import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import PageTitle from "../../layouts/PageTitle";
import { Stepper } from "@material-ui/core";

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import StepButton from "@material-ui/core/StepButton";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useFormik, Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "./generatebidcss.css";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Step-1 Bid Details", "Step-2 Drug Details", "Step-3 Vendor Details"];
}

export default function GenerateBidMUI() {
  const history = useHistory();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const steps = getSteps();
  const [startenddates, setStartEndDates] = useState([{}]);
  const [biddescription, setBidDescription] = useState("");
  const [getDateShow, setGetDateShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState();
  const [isCheck3, setIsCheck3] = useState([]);
  const [isCheckAll3, setIsCheckAll3] = useState();
  const [selectmaterialsingle, setSelectMaterialSingle] = useState();
  const [druguniquelist, setDrugUniqueList] = useState([]);
  const [materialids2, setMaterialIDs2] = useState([]);

  const [biddescriptionerror, setBidDescriptionError] = useState(false);
  const [selectmaterialerror1, setSelectMaterialError1] = useState(false);
  const [isCheckError, setIsCheckError] = useState(false);
  const [dates, setDates] = useState([{}]);
  const [checkboxerror2, setCheckBoxError2] = useState(false);

  const [drugList, setDrugList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [drugmatid, setDrugMatID] = useState([
    {
      drug_id: "",
      material_category_id: "",
    },
  ]);

  const [drugmatidcheck2, setDrugMatIDCheck2] = useState([]);
  const [drugmatidcheck3dummy, setDrugMatIDCheck3Dummy] = [];
  const [stepcompleted, setStepCompleted] = useState(1);

  const [draftmodalshow, setDraftModalShow] = useState(false);

  const [draftbiddata, setDraftBidData] = useState();
  const [draftbidboolean, setDraftBidBoolean] = useState();
  const [draftbidmatcheckstep1, stepDraftBidMatCheckStep1] = useState();
  const [draftbidstendate, setDraftBidstenDate] = useState();
  const [draftbidstep2boolean, setDraftBidStep2Boolean] = useState(false);

  const drafthandlehide = () => {};
  const drafthandleClose = () => {
    swal({
      title: "Are you sure?",
      text: "Do You Want Delete This Draft, \n You Can't Restore This Bid Draft",

      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .delete(
            `/bid/delete_generateBid/${draftbiddata?.step1_data[0].id.toString()}`
          )
          .then((res) => {
            swal("Draft Bid Deleted!", {
              icon: "success",
              timer: 1000,
            });
            setDraftModalShow(false);
            window.location.reload();
          });
      }
    });
  };
  const drafthandleShow = () => {
    setDraftModalShow(true);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <StepOne
            startenddates={startenddates}
            setStartEndDates={setStartEndDates}
            biddescription={biddescription}
            setBidDescription={setBidDescription}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            biddescriptionerror={biddescriptionerror}
            setDates={setDates}
            getDateShow={getDateShow}
            setGetDateShow={setGetDateShow}
            stepcompleted={stepcompleted}
            setStepCompleted={setStepCompleted}
            draftbidboolean={draftbidboolean}
            draftbidstendate={draftbidstendate}
            setDraftBidstenDate={setDraftBidstenDate}
          />
        );
      case 1:
        return (
          <StepTwo
            selectedOption={selectedOption}
            isCheck={isCheck}
            setIsCheck={setIsCheck}
            isCheckAll={isCheckAll}
            setIsCheckAll={setIsCheckAll}
            activestep={activeStep}
            setIsCheckError={setIsCheckError}
            setCheckBoxError2={setCheckBoxError2}
            checkboxerror2={checkboxerror2}
            setMaterialIDs2={setMaterialIDs2}
            materialids2={materialids2}
            setDrugMatID={setDrugMatID}
            drugmatid={drugmatid}
            setDrugMatIDCheck2={setDrugMatIDCheck2}
            drugmatidcheck2={drugmatidcheck2}
            setDrugUniqueList={setDrugUniqueList}
            druguniquelist={druguniquelist}
            setMaterialList={setMaterialList}
            materialList={materialList}
            stepcompleted={stepcompleted}
            setStepCompleted={setStepCompleted}
            draftbidstep2boolean={draftbidstep2boolean}
            setDraftBidStep2Boolean={setDraftBidStep2Boolean}
            draftbiddata={draftbiddata}
            drugList={drugList}
          />
        );
      case 2:
        return (
          <StepThree
            selectedOption={selectedOption}
            isCheck3={isCheck3}
            setIsCheck3={setIsCheck3}
            isCheckAll3={isCheckAll3}
            setIsCheckAll3={setIsCheckAll3}
            activestep={activeStep}
            setVendorList={setVendorList}
            vendorList={vendorList}
            stepcompleted={stepcompleted}
            setStepCompleted={setStepCompleted}
          />
        );
      default:
        return "Unknown step";
    }
  }

  console.log(selectedOption);
  //step2 Axios Calls
  const GetDrugListAxios = () => {
    axios.get("/drug/getAllDrugMaster").then((res) => {
      const selectedLabels = selectedOption?.map((val) => {
        return val?.value?.toString();
      });

      let temp = res.data.data;
      temp = temp.filter((val) => {
        return selectedLabels?.includes(val.material_category);
      });

      setDrugList(temp);

      const drug = temp.map((val) => {
        return {
          id: val.id,
          drug_id: val.generic,
          material_category_id: val.material_category,
        };
      });

      var resArr = [];
      drug.filter(function (item) {
        var i = resArr.findIndex((x) => x.drug_id == item.drug_id);

        /*     &&
            x.material_category_id == item.material_category_id */
        if (i <= -1) {
          resArr.push(item);
        }
        return null;
      });
      setDrugUniqueList(resArr);

      const drugcheckall = resArr.map((li, i) => {
        return {
          drug_id: li.id.toString(),
          material_category_id: li.material_category_id,
        };
      });

      setIsCheck(drugcheckall);
    });
  };

  const GetMaterialListAxios = () => {
    axios.get("/material_category/getAllMaterialCategory").then((res) => {
      setMaterialList(res.data.data);
    });
  };

  useEffect(() => {
    GetDrugListAxios();
    GetMaterialListAxios();
  }, [selectedOption]);

  useEffect(() => {
    setIsCheckAll(true);
  }, []);

  //step3 Axios Calls

  const GetVendorAxios = () => {
    axios.get("/vendor/getAllVendor").then((res) => {
      const selectedLabels = selectedOption?.map((val) => {
        return val.value;
      });

      let tempv = res.data.data;
      tempv = tempv.filter((val) => {
        /*   return selectedLabels?.includes(val.material_category_id.toString()); */
        /*    return val.material_category_id.toString().includes(selectedLabels); */
        const templist = val.material_category_id.some((item) =>
          selectedLabels?.includes(item)
        );

        return templist;
      });
      setVendorList(tempv);

      const vendorcheckall = tempv.map((li, i) => {
        return {
          vendor_user_id: li.vendor_user_id,
        };
      });
    });
  };

  useEffect(() => {
    GetVendorAxios();
  }, [selectedOption]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

    setDrugMatIDCheck2([]);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const step1axios = () => {
    const stepcheck = activeStep + 1;

    const catid = selectedOption?.map((val) => {
      return val.value.toString();
    });

    let startdateyymmdd =
      parseInt(dates[0].startDate?.getFullYear()) +
      "-" +
      parseInt(dates[0].startDate?.getMonth() + 1) +
      "-" +
      parseInt(dates[0].startDate?.getDate());

    let enddateyymmdd =
      parseInt(dates[0].endDate?.getFullYear()) +
      "-" +
      parseInt(dates[0].endDate?.getMonth() + 1) +
      "-" +
      parseInt(dates[0].endDate?.getDate());

    const step1data = {
      bid_description: biddescription,
      material_category_id: catid,
      start_date: startdateyymmdd,
      end_date: enddateyymmdd,
      created_by_user: localStorage.getItem("userId"),
    };

    if (stepcheck === 1) {
      setIsCheckAll(true);

      if (biddescription) {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();

        axios.post(`/bid/create_genearteBid`, step1data).then((res) => {
          localStorage.setItem("genid", res.data.data.insertId);
          setStepCompleted(2);
        });
      } else {
        toast.warning("Please Fill All Fields");
      }
    }

    const step2data = {
      gen_bid_id: localStorage.getItem("genid"),
      data: isCheck,
    };

    if (stepcheck === 2) {
      if (isCheck.length === 0) {
        toast.error("Please Select Drugs");
        setCheckBoxError2(true);
      } else {
        setCheckBoxError2(false);
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
        axios.post(`/bid/create_genearteBid2`, step2data).then((res) => {
          setStepCompleted(3);
        });
      }
    }

    const step3data = {
      gen_bid_id: localStorage.getItem("genid"),
      data: isCheck3,
    };

    if (stepcheck === 3) {
      if (isCheck3.length === 0) {
        toast.error("Pls Select Vendor");
      } else {
        swal({
          title: "Are you sure?",
          text: "Click OK to Generate Bid and \n Send Invite to Selected vendors",

          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            axios.post(`/bid/create_genearteBid3`, step3data).then((res) => {});
            setStepCompleted(1);

            localStorage.removeItem("genid");

            swal("All vendors have been invited!", {
              icon: "success",
              timer: 2000,
            });
            setTimeout(() => {
              history.push("/");
            }, 2000);
          } else {
          }
        });
      }
    }
  };

  ///Step1 Check Validation
  const biddescriptionvalidation = () => {
    if (biddescription.length) {
      setBidDescriptionError(false);
    } else {
      setBidDescriptionError(true);
    }
  };
  useEffect(() => {
    biddescriptionvalidation();
  }, [biddescription]);

  /// step2 check validation
  const checkbox2error = () => {
    if (isCheck.length) {
      setCheckBoxError2(false);
    } else {
      setCheckBoxError2(true);
    }
  };

  useEffect(() => {
    checkbox2error();
  }, [isCheck]);

  const handleComplete = (activeStep) => {
    const stepcheck = activeStep + 1;

    step1axios();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  useEffect(() => {
    const getdraftApicall = async () => {
      await axios
        .get("/bid/getDraftBid")
        .then((res) => {
          setDraftBidData(res.data);

          if (res.data) {
            if (res.data?.step1_data[0].status === "draft") {
              setDraftModalShow(true);
              setDraftBidBoolean(true);
              materialviewbidfunction();
            }

            if (res.data?.step1_data[0].bid_step === "step 1") {
            }
            if (res.data?.step1_data[0].bid_step === "step 2") {
            }
          } else {
          }
        })
        .catch((error) => {});
    };
    getdraftApicall();

    setTimeout(() => {}, 2000);
  }, []);

  const draftrestorehandle = () => {
    setDraftModalShow(false);
    setGetDateShow(true);

    // Step 1 Date Showing Code Start
    const stv = draftbiddata?.step1_data[0].start_date;
    const nstv = new Date(stv);
    const strdate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      day: "2-digit",
      month: "long",
    }).format(nstv);

    const etv = draftbiddata?.step1_data[0].end_date;
    const nsetv = new Date(etv);
    const etrdate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      day: "2-digit",
      month: "long",
    }).format(nsetv);

    setDraftBidstenDate({
      stdate: strdate,
      endate: etrdate,
    });

    // Step 1 Date Showing Code end

    if (draftbiddata?.step1_data[0].bid_step.toString() === "step 1") {
      setActiveStep(1);
      const newCompleted = completed;
      newCompleted[0] = true;
      setCompleted(newCompleted);

      setStepCompleted(2);

      setBidDescription(draftbiddata?.step1_data[0].bid_description.toString());
      setSelectedOption(selectedOption);
    }

    if (draftbiddata?.step1_data[0].bid_step.toString() === "step 2") {
      setActiveStep(2);

      const new0Completed = completed;
      new0Completed[0] = true;
      setCompleted(new0Completed);

      const newCompleted = completed;
      newCompleted[1] = true;
      setCompleted(newCompleted);

      setDraftBidStep2Boolean(true);
      setStepCompleted(3);

      localStorage.setItem("genid", draftbiddata?.step1_data[0].id);

      setBidDescription(draftbiddata?.step1_data[0].bid_description.toString());
      setSelectedOption(selectedOption);
    }
  };

  useEffect(() => {
    if (draftbiddata?.hasError !== true) {
      materialviewbidfunction();
    }
  }, [activeStep]);

  /*  setActiveStep; */

  const materialviewbidfunction = (e) => {
    if (draftbiddata?.hasError !== true) {
      const newvalue = draftbiddata?.step1_data[0]?.material_category_id.map(
        (val) => {
          const material = materialList
            .filter((it) => it.id === val)
            .map((item) => item.material_category);
          return {
            value: val,
            label: material[0],
          };
        }
      );
      stepDraftBidMatCheckStep1(newvalue);
      setSelectedOption(newvalue);
    }
  };

  return (
    <div>
      <PageTitle activeMenu="Generate Bid" motherMenu="Bid" />
      <div className="card px-5 py-5">
        <ToastContainer />

        <Modal show={draftmodalshow} onHide={drafthandlehide} centered>
          <Modal.Header>
            <Modal.Title>
              {" "}
              <i class="fa fa-gavel" aria-hidden="true"></i> Pending Bid
            </Modal.Title>
          </Modal.Header>
          <Modal.Body p-0>
            <div className="text-center text-danger">
              <h3 className="text-danger">You Have One Pending BID Draft !</h3>
              <div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Bid Title</th>
                      <th>Completed Steps</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {draftbidboolean ? (
                        <React.Fragment>
                          <td>{draftbiddata?.step1_data[0].bid_description}</td>
                          <td>
                            {draftbiddata?.step1_data[0].bid_step.toString()}
                          </td>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>{" "}
          </Modal.Body>
          <Modal.Footer>
            <div className="col-md-12">
              <button
                className="btn btn-secondary btn-block text-white"
                onClick={draftrestorehandle}
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>{" "}
                <span className="text-white">RESTORE</span>
              </button>
            </div>

            <div className="col-md-12">
              <button
                className="btn btn-warning btn-block"
                onClick={drafthandleClose}
              >
                <i class="fa fa-trash fa-1x text-white" aria-hidden="true"></i>{" "}
                <span className="text-white">DISCARD</span>
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <div className={classes.root}>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton
                  /*    onClick={handleStep(index)} */
                  completed={completed[index]}
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <div>
            {allStepsCompleted() ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed - you&apos;re finished
                </Typography>
                <Button onClick={handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                <Typography className={classes.instructions}>
                  {getStepContent(activeStep)}
                </Typography>
                {/*         /* col-md-12 d-flex justif-content-between */}

                <div className="csdivider"></div>
                <div className="d-flex justify-content-between">
                  <div>
                    <button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      /*  className={classes.button} */
                      className="btn  text-white px-3 mx-2"
                      style={{ background: "grey" }}
                    >
                      <i class="fa fa-arrow-left fa-1x" aria-hidden="true"></i>{" "}
                    </button>

                    <button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      style={{ background: "grey" }}
                      /*      className="btn btn-info text-white px-3 mx-2" */
                      className="btn text-white px-3 mx-2"
                    >
                      {" "}
                      <i class="fa fa-arrow-right fa-1x" aria-hidden="true"></i>
                    </button>
                  </div>

                  <div>
                    {/*                 {
                  activeStep !== steps.length && (     } */}
                    {activeStep !== steps.length &&
                      (completed[activeStep] ? (
                        <Typography
                          variant="caption"
                          sx={{ display: "inline-block" }}
                        >
                          Step {activeStep + 1} already completed
                        </Typography>
                      ) : (
                        <button
                          onClick={handleComplete}
                          /*       className="btn btn-success px-3 text-white" */
                          className="btn-grad"
                        >
                          {completedSteps() === totalSteps() - 1
                            ? "INVITE"
                            : "COMPLETE STEP"}
                          {"  "}
                          <i
                            class="fa fa-check-circle fa-1x "
                            aria-hidden="true"
                          ></i>
                        </button>
                      ))}

                    {/*            {activeStep === totalSteps() - 1 ? (
                    <button
                      className="btn btn-secondary px-5"
                      onClick={userInviteHandle}
                    >
                      INVITE
                    </button>
                  ) : (
                    <button
                      className="btn btn-success px-5 text-white"
                      onClick={handleComplete}
                    >
                      NEXT
                    </button>
                  )} */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
