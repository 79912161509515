import React, { useState, useRef, useEffect, useMemo } from "react";
import swal from "sweetalert";
// import { Dropdown, DropdownButton, ButtonGroup, } from "react-bootstrap";
import { ReactComponent as ExportIcon } from "../../../../images/svg/export.svg";
import PageTitle from "../../../layouts/PageTitle";
//import tableData from "./tableData.js";
import { Link } from "react-router-dom";
// import Select from "react-select";
import EditVendor from "./EditVendor";
import AddVendor from "./AddVendor";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
// import axios, { post } from "axios";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useSelector } from "react-redux";
import { styled } from "@mui/system";
import TablePaginationUnstyled from "@mui/base/TablePaginationUnstyled";
import {
  deleteVendor,
  loadallVendors,
  updateVendor,
} from "../../../../store/actions/VendorActions";
// import UploadFile from "./UploadFile";
import "./style.css";
import Tooltip from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
// import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
// import "../Css/tbl.css"
//import { colourOptions, groupedOptions } from "./data";

// const options = [
//   { value: "chocolate", label: "Chocolate" },
//   { value: "strawberry", label: "Strawberry" },
//   { value: "vanilla", label: "Vanilla" },
// ];

const VendorMaster = () => {
  const classes = useStyles();
  const vendorsapidata = useSelector((state) => state.vendors.vendors.data);

  const dispatch = useDispatch();

  const [Vendordata, setVendorData] = useState([]);

  const [showeditmodal, setShowEditModal] = useState(false);
  const [editvendorid, setEditVendorID] = useState();

  const [editVendorData, setEditVendorData] = useState({});
  const [showeditselectedmaterials, setShowEditSelectedMaterials] = useState(
    {}
  );
  const [showmaterialvalue, setShowMaterialValue] = useState({});
  const [vendorediteddata, setVendorEditedData] = useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [searchResult, setSearchResult] = useState({});
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);

  const [vendorcheckdata, setVendorCheckedData] = useState(
    vendorsapidata?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  );

  console.log("vendorcheckdata", vendorcheckdata);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - vendorsapidata.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const closeEditModalButtonHandle = () => {
    setShowEditModal(false);
    /*   setVendorData(vendorsapidata); */
    setShowEditSelectedMaterials({});
  };

  const Root = styled("div")`
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td,
    th {
      border: 1px solid #ddd;
      text-align: left;
      padding: 8px;
    }

    th {
      background-color: #ddd;
    }
  `;

  const CustomTablePagination = styled(TablePaginationUnstyled)`
    & .MuiTablePaginationUnstyled-toolbar {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }

    & .MuiTablePaginationUnstyled-selectLabel {
      margin: 0;
    }

    & .MuiTablePaginationUnstyled-displayedRows {
      margin: 0;

      @media (min-width: 768px) {
        margin-left: auto;
      }
    }

    & .MuiTablePaginationUnstyled-spacer {
      display: none;
    }

    & .MuiTablePaginationUnstyled-actions {
      display: flex;
      gap: 0.25rem;
    }
  `;

  const getSingleVendorDetails = (e) => {
    console.log(e);
    axios.get(`/vendor/getSingleVendor/${e}`).then((res) => {
      console.log(res.data.data);
      setEditVendorData(res.data.data[0]);
    });
  };

  const materialviewfunction = (e) => {
    const newvalue = editVendorData.material_category_id?.map((val) => {
      const material = materiallist
        .filter((it) => it.id === val)
        .map((item) => item.material_category);
      return {
        value: val,
        label: material[0],
      };
    });
    setShowEditSelectedMaterials(newvalue);
  };

  useEffect(() => {
    materialviewfunction();
  }, [editVendorData]);

  /*     useEffect(() => {
          setShowMaterialValue(showeditselectedmaterials);
        }, [showeditmodal]); */

  console.log(showeditselectedmaterials);

  // important get selected code start//

  /*   const getseteditmaterialcategory = () => {
    const setmaterialvalue = editVendorData?.material_category_id.map((val) => {
      const material = materiallist
        .filter((it) => it.id.toString() === val.toString())
        .map((item) => item.material_category);
      return {
        value: val.toString(),
        label: material[0],
      };
    });
    setShowEditSelectedMaterials(setmaterialvalue);
  };
  console.log(editVendorData.material_category_id); */
  // important get selected code end//

  useEffect(() => {
    /*  if (editvendorid) {
      getSingleVendorDetails();
    } */
  }, [showeditmodal]);

  console.log(editVendorData);

  /*   const [data, setData] = useState(
    document.querySelectorAll("#patientTable_basic_table tbody tr")
  );
  const [rows, setRows] = useState(5);
  const sort = rows;
  const activePag = useRef(0);
  const [test, settest] = useState(0); */

  // use effect

  /******************Pagination Code******************************************/
  /*   const [currentPage, setCurrentPage] = useState(1);

  const [postsPerPage, setPostsPerPage] = useState(5);
  const [data, setData] = useState(
    document.querySelectorAll("#patientTable_basic_table tbody tr")
  );

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(Vendordata?.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }
 
  const activePag = useRef(1);
  const [test, settest] = useState(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  // Active pagginarion
  activePag.current === 0 && chageData(0, postsPerPage);

  const paginate = (pageNumbers) => {
    activePag.current = pageNumbers;
    chageData(
      activePag.current * postsPerPage,
      (activePag.current + 1) * postsPerPage
    );
    setCurrentPage(pageNumbers);
  };

  useEffect(() => {
    setData(document.querySelectorAll("#patientTable_basic_table tbody tr"));
    //chackboxFun();
  }, []);

  function selectNum() {
    let selectedValue = document.getElementById("rowsSelect").value;
    // setRows(selectedValue);
    setPostsPerPage(selectedValue);
    console.log(selectedValue);
  }

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = Vendordata?.slice(indexOfFirstPost, indexOfLastPost); */

  /*********************************************************/

  useEffect(() => {
    dispatch(loadallVendors());
    setVendorData(vendorsapidata);
  }, [showeditmodal]);

  // console.log(vendorsapidata);

  const VendorDeleteHandle = (vendor) => {
    swal({
      title: "Are you sure. You want to Delete this Vendor ?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteVendor(vendor.id));
        swal("Vendor has been deleted!", {
          icon: "success",
          timer: 1000,
        });
      } else {
      }
    });
  };

  // paggination
  // let paggination = Array(Math.ceil(data.length / sort))
  //   .fill()
  //   .map((_, i) => i + 1);

  // Active paggination & chage data
  // const onClick = (i) => {
  //   activePag.current = i;
  //   chageData(activePag.current * sort, (activePag.current + 1) * sort);
  //   settest(i);
  // };

  //rahul search code start

  /*   const mySearchFunction = () => {
    var input, filter, table, tr, td, i, j, txtValue, alltags;
    let isFound;
    let count = 0;

    input = document.getElementById("searchInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("vendor_table");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      // td = tr[i].getElementsByTagName("td")[0];
      alltags = tr[i].getElementsByTagName("td");
      isFound = false;
      for (j = 0; j < alltags.length; j++) {
        td = alltags[j];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
            j = alltags.length;
            isFound = true;
            count++;
          }
        }
      }
      if (!isFound && tr[i].className !== "header") {
        tr[i].style.display = "none";
      }
    }

    var error = document.getElementById("error");
    if (count === 0) {
      error.innerHTML = "<p style='color: red;'>" + "Data Not Found</p>";
    } else {
      error.innerHTML = "";
    }
  }; */

  //rahul search code end

  //export data
  /*  const filterData = () => {
    axios
      .get("/vendor/getAllVendor")
      .then((response) => {
        // console.log(response.data.data);
        setVendorData(response.data.data);
        // console.log(response.data.data, "vendor list");
      });
  };
  useEffect(() => {
    filterData();
  }, []); */
  //material list
  const [materiallist, setMaterial] = useState([]);

  function getAllMaterialCategory() {
    axios.get("/material_category/getAllMaterialCategory").then((response) => {
      setMaterial(response.data.data);
    });
  }

  useEffect(() => {
    getAllMaterialCategory();
  }, []);

  function fnExport(fileExtn, filename, e) {
    e.preventDefault();

    let temp = [...vendorsapidata];
    temp?.map((data) => {
      let materialnames = [];
      data.material_category_id?.map((val) => {
        const found_material = materiallist?.find((item) => {
          return item.id === val;
        });
        materialnames.push(found_material.material_category);
      });
      data.material_category_id = materialnames;
    });
    // console.log("updating",temp);
    setVendorData(temp);
    dispatch(loadallVendors());

    vendorsapidata?.map((val) => {
      val.contact_person_1_email = val.email_id;
    });

    let exportVendorData = vendorsapidata?.map((item, i, arr) => {
      return Object.keys(arr[i])
        .filter(
          (key) =>
            // key.includes("email_id") ||
            key.includes("company_name") ||
            key.includes("company_address") ||
            key.includes("contact_person_1") ||
            key.includes("contact_person_1_email") ||
            key.includes("contact_person_1_phone") ||
            key.includes("contact_person_2") ||
            key.includes("contact_person_2_email") ||
            key.includes("contact_person_2_phone") ||
            key.includes("material_category_id")
        )
        .reduce((cur, key) => {
          return Object.assign(cur, { [key]: arr[i][key]?.toString() }, {});
        }, {});
    });

    console.log(exportVendorData);

    let header = [
      "COMPANY NAME",
      "CONTACT PERSON 1",
      "CONTACT PERSON 1 EMAIL ID",
      "CONTACT PERSON 1 PHONE",
      "CONTACT PERSON 2 ",
      "CONTACT PERSON 2 EMAIL ID",
      "CONTACT PERSON 2 PHONE",
      "COMPANY ADDRESS",
      "MATERIAL CATEGORY",
    ];
    const ws = XLSX.utils.book_new();

    XLSX.utils.sheet_add_aoa(ws, [header], {
      origin: "A1",
    });
    XLSX.utils.sheet_add_json(ws, exportVendorData, {
      origin: "A2",
      skipHeader: true,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    var wscols = [
      { width: 20 },
      { width: 20 },
      { width: 25 },
      { width: 20 },
      { width: 20 },
      { width: 25 },
      { width: 20 },
      { width: 20 },
      { width: 50 },
      { width: 30 },
    ];
    ws["!cols"] = wscols;
    const excelBuffer = XLSX.write(wb, {
      bookType: fileExtn,
      type: "array",
      cellStyles: true,
    });
    const finalData = new Blob([excelBuffer], { type: "xlsx" });
    FileSaver.saveAs(finalData, filename);
  }

  /*   function getAllVendors() {
    axios
      .get("/vendor/getAllVendor")
      .then((response) => {
       
      });
  } */

  /*  useEffect(() => {
    dispatch(loadallVendors());
  }, []); */

  const onSearchHandler = (e) => {
    console.log(e.target.value, e.target.value.length);
    setSearchResult({
      result: e.target.value?.toLowerCase(),
      length: e.target.value?.length,
    });
  };

  console.log(searchResult);
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = useState(null);

  const changeHandler = (e) => {
    /* console.log(e.target.files); */
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append("import_data_input", e.target.files[0]);
      swal({
        title: "Are you sure? You want to upload this file",
        text: "",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willUpload) => {
        if (willUpload) {
          setOpen(true);
          axios
            .post("/vendor/importVendor", formData)
            .then((response) => {
              console.log(response);
              if (response.data.hasError) {
                console.log(response);
                setOpen(false);
                setFile(e.target.files);
                swal({
                  title: response.data.message,
                  // text: "You clicked the button!",
                  icon: "error",
                  button: "Okay!",
                });
                e.target.value = null;
                console.log(response);
              } else {
                setOpen(false);
                setFile(e.target.files);
                swal({
                  title: response.data.message,
                  // text: "You clicked the button!",
                  icon: "success",
                  button: "Okay!",
                });

                e.target.value = null;
                console.log(response);
                dispatch(loadallVendors());
              }
            })
            .catch((error) => {
              setOpen(false);
              swal({
                title: "Something went wrong!",
                // text: "You clicked the button!",
                icon: "success",
                button: "Okay!",
              });
              console.log({ error });
            });
        }
      });
    }
  };

  console.log(editvendorid);
  const showvendorotp = (e) => {
    /*   const id = edituserid; */
    console.log(e);

    axios.get(`/vendor/viewOtp/${e}`).then((res) => {
      /*   setEditUserData(res.data.data); */
      console.log(res.data.OTP);
      const isotp = res.data.OTP;

      Swal.fire({
        icon: "success",
        html: `<h3> Vendor OTP is ${isotp} </h3>`,
      });
    });
  };

  const lowersearch = searchResult?.result?.toString()?.toLowerCase()?.trim();
  console.log(lowersearch);
  const vendorsearchall = vendorsapidata
    ?.filter(
      (sv) =>
        sv.company_name?.toLowerCase()?.includes(lowersearch) ||
        sv.contact_person_1?.toLowerCase()?.includes(lowersearch) ||
        sv.email_id?.toLowerCase()?.includes(lowersearch) ||
        sv.contact_person_1_phone?.toLowerCase()?.includes(lowersearch) ||
        sv.contact_person_2_phone?.toLowerCase()?.includes(lowersearch) ||
        sv.company_address?.toLowerCase()?.includes(lowersearch)
    )
    .map((sitem) => {
      return sitem;
    });

  /*   useEffect(() => {
    setList(vendorsapidata);
  }, [list]); */

  const rowdataSet = () => {
    setVendorCheckedData(
      vendorsapidata?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
    );

    /* else {
      setVendorCheckedData(vendorsapidata);
    }  */
  };
  console.log(
    "testC",
    vendorsapidata?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  );

  useEffect(() => {
    rowdataSet();
  }, [rowsPerPage]);

  /*   useEffect(() => {
    const vendorpagedata =
      rowsPerPage > 0
        ? vendorsapidata?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
        : vendorsapidata;

    setVendorCheckedData(vendorpagedata);
  }, [rowsPerPage]); */

  console.log(vendorcheckdata);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);

    console.log(
      "isCheckall",
      vendorsapidata?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
    );

    const datav = vendorsapidata?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    setIsCheck(datav?.map((li) => li.id.toString()));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;

    /*    setIsCheck({ ...id, id }); */

    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck?.filter((item) => item !== id.toString()));
    }
  };

  console.log(isCheck?.toString());

  const deleteAllHandle = () => {
    const deletealldata = {
      vendor_id: isCheck.toString(),
    };

    console.log(deletealldata);

    // swal({
    //   title: "Are you sure. You want to Delete Selected Vendors ?",
    //   text: "",
    //   icon: "warning",
    //   buttons: true,
    //   dangerMode: true,
    // }).then((willDelete) => {
    //   if (willDelete) {
    //     swal("Selected Vendors has been deleted!", {
    //       icon: "success",
    //       timer: 1000,
    //     });

    //     axios
    //       .post("/vendor/deleteSelectedVendor", deletealldata)
    //       .then((res) => {
    //         console.log(res);
    //         dispatch(loadallVendors());
    //       });
    //   }
    // });
  };

  console.log(isCheckAll);
  return (
    <>
      <PageTitle activeMenu="Vendor Master" motherMenu="Vendors" />
      {file ? (
        ""
      ) : (
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <div className="row">
        <React.Fragment>
          <EditVendor
            editvendorid={editvendorid}
            showeditmodal={showeditmodal}
            closeEditModalButtonHandle={closeEditModalButtonHandle}
            materiallist={materiallist}
            setMaterial={setMaterial}
            editVendorData={editVendorData}
            setEditVendorData={setEditVendorData}
            /*        EditUserHandle={EditUserHandle} */
            showeditselectedmaterials={showeditselectedmaterials}
            setShowEditSelectedMaterials={setShowEditSelectedMaterials}
            showmaterialvalue={showmaterialvalue}
            setShowMaterialValue={setShowMaterialValue}
            vendorediteddata={vendorediteddata}
            setVendorEditedData={setVendorEditedData}
          />
        </React.Fragment>

        {/*  <PageTitle activeMenu="Users" motherMenu="Dashboard" /> */}

        <div className="col-md-12">
          <div className="card ">
            <div className="card-header ">
              <input
                className="form-control mx-4 text-black"
                type="text"
                name=""
                onPaste={(e) => console.log(e.clipboardData.getData("Text"))}
                placeholder="Search vendor"
                onChange={onSearchHandler}
                id="searchInput"
                /*     onKeyUp={mySearchFunction} */
              />
              <div className="d-flex">
                <AddVendor materiallist={materiallist} />

                <div>
                  <div className="dropZoneContainer">
                    <input
                      type="file"
                      id="drop_zone"
                      className="FileUpload"
                      accept=".csv"
                      onChange={changeHandler}
                    />
                    <div className="dropZoneOverlay">
                      <i
                        htmlFor="drop_zone"
                        id="submit"
                        className="fa fa-cloud-upload"
                        style={{
                          fontSize: "30px",
                          color: "var(--primary)",
                          cursor: "pointer",
                          content: "import",
                        }}
                      ></i>
                    </div>
                  </div>
                </div>

                <i
                  id="btn-export"
                  onClick={(e) => fnExport("xlsx", "VendorSheet.xlsx", e)}
                  class="fa fa-cloud-download  mx-2"
                  style={{
                    fontSize: "30px",
                    color: "var(--primary)",
                    cursor: "pointer",
                    content: "export",
                  }}
                  aria-hidden="true"
                ></i>
              </div>
            </div>
            <div className="card-body py-1">
              <div className="w-100 table-responsive">
                <div
                  id="patientTable_basic_table"
                  className="dataTables_wrapper"
                >
                  <table
                    className="table shadow-hover card-table border-no tbl-btn text-center text-black"
                    id="vendor_table"
                  >
                    <thead>
                      <th>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            name="selectAll"
                            onChange={handleSelectAll}
                            className="custom-control-input"
                            id="selectAll"
                            checked={
                              isCheck?.length ===
                              vendorsapidata?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              ).length
                                ? true
                                : false
                            }
                            /*  checked={props.isCheck?.drug_id} */
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="selectAll"
                          />
                        </div>
                      </th>
                      <th>Company Name</th>
                      <th>Contact Person</th>
                      <th>Email ID</th>
                      <th>Phone No 1</th>
                      <th>Phone No 2</th>
                      <th>Address</th>
                      <th>Action</th>
                    </thead>

                    <tbody>
                      {searchResult.length > 0 ? (
                        <>
                          {vendorsearchall.length === 0 ? (
                            <>
                              <tr>
                                <td colSpan={8}>
                                  <h6 className="text-danger">
                                    No Records Found
                                  </h6>
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>{""}</>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {searchResult.length > 0 ? (
                        <>
                          {vendorsearchall?.map((sitem, i) => (
                            <React.Fragment>
                              {vendorsearchall.length > 0 ? (
                                <>
                                  <tr>
                                    <td></td>
                                    <td>{sitem.company_name}</td>
                                    <td>{sitem.contact_person_1}</td>
                                    <td>{sitem.email_id}</td>
                                    <td>{sitem.contact_person_1_phone}</td>
                                    <td>{sitem.contact_person_2_phone}</td>
                                    <td>{sitem.company_address}</td>
                                    <td className="d-flex ml-4">
                                      <div className="d-flex">
                                        <div>
                                          <button
                                            onClick={(e) => {
                                              setShowEditModal(true);

                                              setEditVendorID(sitem.id);
                                              getSingleVendorDetails(sitem.id);
                                              materialviewfunction(sitem.id);
                                              /* getseteditmaterialcategory(); */
                                            }}
                                            className="btn btn-warning shadow btn-xs sharp mr-1"
                                          >
                                            <i className="fa fa-pencil"></i>
                                          </button>
                                        </div>

                                        <div>
                                          <button
                                            onClick={() =>
                                              VendorDeleteHandle(sitem)
                                            }
                                            className="btn btn-danger shadow btn-xs sharp"
                                          >
                                            <i className="fa fa-trash"></i>
                                          </button>
                                        </div>

                                        {sitem.mandatory_pwd_change === "1" ? (
                                          <div>
                                            <Tooltip
                                              title="Show OTP"
                                              placement="right"
                                            >
                                              <button
                                                onClick={(e) => {
                                                  showvendorotp(sitem.id);
                                                }}
                                                className="btn btn-success shadow btn-xs text-white sharp mx-1 mr-1"
                                              >
                                                <i
                                                  class="fa fa-eye"
                                                  aria-hidden="true"
                                                ></i>
                                              </button>
                                            </Tooltip>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <></>
                              )}
                            </React.Fragment>
                          ))}
                        </>
                      ) : (
                        <>
                          {vendorsapidata?.length == "0" ? (
                            <tr>
                              <td colSpan={8}>
                                <p className="text-red">No Vendors Available</p>
                              </td>
                            </tr>
                          ) : (
                            <React.Fragment>
                              {(rowsPerPage > 0
                                ? vendorsapidata?.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                : vendorsapidata
                              )?.map((vendor) => (
                                <tr key={vendor.id}>
                                  <td>
                                    <div className="custom-control custom-checkbox ">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        name={vendor.companyName}
                                        id={vendor.id.toString()}
                                        onChange={handleClick}
                                        checked={isCheck?.includes(
                                          vendor.id.toString()
                                        )}
                                      />

                                      <label
                                        className="custom-control-label"
                                        htmlFor={vendor.id.toString()}
                                      />
                                    </div>
                                  </td>
                                  <td>{vendor.company_name}</td>
                                  <td>{vendor.contact_person_1}</td>
                                  <td>{vendor.email_id}</td>
                                  <td className="">
                                    {vendor.contact_person_1_phone}
                                  </td>
                                  <td className="">
                                    {vendor.contact_person_2_phone}
                                  </td>
                                  <td>{vendor.company_address}</td>
                                  <td className="d-flex ml-4">
                                    <div className="d-flex">
                                      <div>
                                        <button
                                          onClick={(e) => {
                                            setShowEditModal(true);

                                            setEditVendorID(vendor.id);
                                            getSingleVendorDetails(vendor.id);
                                            materialviewfunction(vendor.id);
                                            /* getseteditmaterialcategory(); */
                                          }}
                                          className="btn btn-warning shadow btn-xs sharp mr-1"
                                        >
                                          <i className="fa fa-pencil"></i>
                                        </button>
                                      </div>

                                      <div>
                                        <button
                                          onClick={() =>
                                            VendorDeleteHandle(vendor)
                                          }
                                          className="btn btn-danger shadow btn-xs sharp"
                                        >
                                          <i className="fa fa-trash"></i>
                                        </button>
                                      </div>

                                      {vendor.mandatory_pwd_change === "1" ? (
                                        <div>
                                          <Tooltip
                                            title="Show OTP"
                                            placement="right"
                                          >
                                            <button
                                              onClick={(e) => {
                                                showvendorotp(vendor.id);
                                              }}
                                              className="btn btn-success shadow btn-xs text-white sharp mx-1 mr-1"
                                            >
                                              <i
                                                class="fa fa-eye"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </Tooltip>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </React.Fragment>
                          )}
                        </>
                      )}

                      {emptyRows > 0 && (
                        <tr style={{ height: 41 * emptyRows }}>
                          <td colSpan={8} />
                        </tr>
                      )}
                    </tbody>

                    <tfoot>
                      <tr>
                        <td
                          /*     colSpan={1} */
                          className=""
                        >
                          {isCheck?.length > 0 ? (
                            <div className="d-flex justify-content-start align-items-baseline">
                              {/*        <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                name="selectAll"
                                onChange={handleSelectAll}
                                className="custom-control-input"
                                id="selectAll"
                                checked={isCheckAll}
                              
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="selectAll"
                              />
                            </div> */}

                              <div className="d-flex justify-content-center">
                                <button
                                  onClick={deleteAllHandle}
                                  /*      onClick={() => VendorDeleteHandle} */
                                  className="btn btn-danger shadow d-flex align-items-baseline"
                                  style={{
                                    borderRadius: "0px",
                                    paddingRight: "20px",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  <i className="fa fa-trash fa-1x"></i>
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div style={{ marginRight: "0px" }}></div>
                          )}
                        </td>

                        <CustomTablePagination
                          rowsPerPageOptions={[
                            5,
                            10,
                            25,
                            { label: "All", value: -1 },
                          ]}
                          colSpan={7}
                          count={vendorsapidata?.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          componentsProps={{
                            select: {
                              "aria-label": "rows per page",
                            },
                            actions: {
                              showFirstButton: true,
                              showLastButton: true,
                            },
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </tr>
                    </tfoot>
                  </table>
                  <div id="error" className="text-center"></div>

                  {/* <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                    <div className="d-flex ">
                      <div className="dataTables_info mx-3 my-4">
                        Showing {activePag.current * postsPerPage + 1} to{" "}
                        {currentPosts?.length >
                        (activePag.current + 1) * postsPerPage
                          ? (activePag.current + 1) * postsPerPage
                          : currentPosts?.length}{" "}
                        of {Vendordata?.length} entries
                      </div>
                      <div class="d-flex justify-content-end  my-4">
                        <label
                          for="exampleFormControlSelect1"
                          className="mx-1 my-auto"
                        >
                          rows
                        </label>
                        <select
                          className=""
                          id="rowsSelect"
                          onChange={(e) => selectNum()}
                        >
                          <option value="5" selected>
                            5
                          </option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                        </select>
                        <label
                          for="exampleFormControlSelect1"
                          className="mx-2 my-auto"
                        >
                          records per page
                        </label>
                      </div>
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example5_paginate"
                    >
                      <Link
                        className="paginate_button previous disabled"
                        to="/vendormaster"
                        onClick={() =>
                          activePag.current > 1 &&
                          paginate(activePag.current - 1)
                        }
                      >
                        Previous
                      </Link>
                      <span>
                        {pageNumbers.map((number) => (
                          <Link
                            key={number}
                            to="/vendormaster"
                            className={`paginate_button  ${
                              activePag.current === number ? "current" : ""
                            } `}
                            onClick={() => paginate(number)}
                          >
                            {number}
                          </Link>
                        ))}
                      </span>
                      <Link
                        className="paginate_button next"
                        to="/vendormaster"
                        onClick={() =>
                          activePag.current + 1 < pageNumbers.length &&
                          paginate(activePag.current + 1)
                        }
                      >
                        Next
                      </Link>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorMaster;
