import React, { Fragment, useContext, useEffect, useState } from "react";
/// React router dom
import { Link, useHistory } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import logo1 from "../../../images/logo-full.png";
import smalllogo from "../../../images/logo-white.png";
const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } =
    useContext(ThemeContext);
  const history = useHistory();
  return (
    <div className="nav-header">
      <div
        onClick={(e)=>{
          const temp = localStorage.getItem("userType");
          temp==='"vendor"'?history.push("/vendor-dashboard"):history.push('/')
        }}
        className="brand-logo"
      >
        {background.value === "light" || navigationHader !== "color_1" ? (
          <Fragment>
            {/*    <img className="brand-title" src={smalllogo} /> */}
          </Fragment>
        ) : (
          <Fragment>
            {toggle ? (
              <img className="logo-abbr" src={smalllogo}  style={{cursor:"pointer"}}/>
            ) : (
              <img className="logo-abbr" src={logo1}  style={{cursor:"pointer"}}/>
            )}
          </Fragment>
        )}
      </div>

      <div></div>
      {/*     <div>
            <img className="logo-abbr" src={smalllogo} />
      
        <img className="brand-title" src={logo1} />
      </div> */}
      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
