export const isAuthenticated = (state) => {

    if(state.auth.auth.mandatory_pwd_change === "1" ){
        return false  
    }else if(
        window.location.href.indexOf("change-password") > -1
    ){
        return false  
    }
    
    if (state.auth.auth !== undefined && state.auth.successMessage === "Login Successfully Completed") {
        return true;
    }else if(
        window.location.href.indexOf("forgot-password") > -1
    ){
        return false
    }
    else if(localStorage.getItem("userEmail")){
        return true;
    }
   
    return false;
};
