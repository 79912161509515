import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { DateRangePicker, DateRange } from "react-date-range";
import { addDays } from "date-fns";
import { useHistory } from "react-router-dom";
function DateRangeModal(props) {
  const history = useHistory();

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const newdata = { state };
  /* console.log(newdata.state[0].startDate); */
  var options = {
    year: "numeric",
    day: "numeric",
    month: "long",
  };

  const startDatestr = newdata.state[0].startDate;
  const endDatestr = newdata.state[0].endDate;
  /* console.log(startDatestr.toLocaleDateString("en-us", options)); */

  const datenowstart = startDatestr.toLocaleDateString("en-us", options);
  const datenowend = endDatestr.toLocaleDateString("en-us", options);

  const datenow = [
    {
      datenowstart,
      datenowend,
    },
  ];

  const dateHandleClick = () => {
    props.getDate(datenow);
    props.dates(state);
  };

  

  return (
    <div className="row">
      <div className="col d-flex ">
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="d-flex align-items-center"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Select Date Range
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={state}
              months={2}
              direction="horizontal"
              minDate={new Date()}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="col-md-12 d-flex justify-content-between align-items-center text-center">
              <div className="col-md-6">
                <span>
                  start date:{" "}
                  {startDatestr.toLocaleDateString("en-us", options)}
                </span>
                <p>
                  End Date : {endDatestr.toLocaleDateString("en-us", options)}{" "}
                </p>
              </div>
              <div className="col-md-6">
                <Button onClick={props.onHide} className="btn btn-warning mx-2">
                  Close
                </Button>
                <Button onClick={dateHandleClick}>Submit</Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default DateRangeModal;
