import React, { useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import axios from "axios";
import swal from "sweetalert";
import { Modal, Button } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { styled } from "@mui/system";
import TablePaginationUnstyled from "@mui/base/TablePaginationUnstyled";
import { useHistory } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function DrugMaster() {
  const classes = useStyles();
  /*************declaration part***************/

  const history = useHistory();
  const [recordRow, setRecords] = useState([]);
  const [UpdateList, setUpadateList] = useState([]);
  const [rowData, setRowData] = useState([]);
  // console.log(rowData.description)
  const [modalCentered, setModalCentered] = useState(false);
  const [materiallist, setMaterial] = useState([]);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchResult, setSearchResult] = useState({});
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [vendorcheckdata, setVendorCheckedData] = useState([]);

  //search
  const mySearchFunction = () => {
    var input, filter, table, tr, td, i, j, txtValue, alltags;
    let isFound;
    let count = 0;
    input = document.getElementById("mysearchInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("drug_table");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      // td = tr[i].getElementsByTagName("td")[0];
      alltags = tr[i].getElementsByTagName("td");
      isFound = false;
      for (j = 0; j < alltags.length; j++) {
        td = alltags[j];
        if (td) {
          if (
            td.textContent.toUpperCase().indexOf(filter) > -1 ||
            td.innerText.toUpperCase().indexOf(filter) > -1
          ) {
            tr[i].style.display = "";
            j = alltags.length;
            isFound = true;
            count++;
          }
        }
      }
      if (!isFound && tr[i].className !== "header") {
        tr[i].style.display = "none";
      }
    }
    var error = document.getElementById("error");
    if (count === 0) {
      error.innerHTML = "<p style='color: red;'>" + "Data Not Found</p>";
    } else {
      error.innerHTML = "";
    }
  };

  /******************Pagination Code******************************************/
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - UpdateList.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const CustomTablePagination = styled(TablePaginationUnstyled)`
    & .MuiTablePaginationUnstyled-toolbar {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }

    & .MuiTablePaginationUnstyled-selectLabel {
      margin: 0;
    }

    & .MuiTablePaginationUnstyled-displayedRows {
      margin: 0;

      @media (min-width: 768px) {
        margin-left: auto;
      }
    }

    & .MuiTablePaginationUnstyled-spacer {
      display: none;
    }

    & .MuiTablePaginationUnstyled-actions {
      display: flex;
      gap: 0.25rem;
    }
  `;

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  // material category data fecthing data from database
  const [error_notfound, setError_notfound] = useState(false);
  function getAllMaterialCategory() {
    axios.get("/material_category/getAllMaterialCategory").then((response) => {
      // console.log(response?.data?.data, "material list");
      setMaterial(response?.data?.data);

      if (response?.data?.data?.length === 0) {
        setError_notfound(true);
      } else {
        setError_notfound(false);
      }

      // console.log("updated",temp);
    });
  }

  useEffect(() => {
    getAllMaterialCategory();
  }, []);

  //fecth data API cal or axios

  const [error, setErrors] = useState(false);
  const getAllDrugs = async () => {
    await axios.get("/drug/getAllDrugMaster").then((response) => {
      console.log(response?.data?.data);
      if (response?.data?.data?.length === 0) {
        setErrors(true);
        // console.log(response.data.data.length, "length")
      } else {
        setErrors(false);
        setRecords(response?.data?.data);
        setUpadateList(response?.data?.data);
        // console.log(response.data.data.length, "length")
      }
    });
  };

  useEffect(() => {
    getAllDrugs();
  }, []);

  //export
  function fnExport(fileExtn, filename) {
    const temp = recordRow?.map((data, i) => {
      const temp1 = materiallist?.find((val) => {
        return val.id === Number(data?.material_category);
      });
      data.material_category = temp1?.material_category;
      return data;
    });
    console.log("temp", temp);
    getAllDrugs();

    let result = recordRow?.map((val)=>{
      let exportDATA = {
        "code":val.code,
        "description":val.description,
        "generic":val.generic,
        "material_category":val.material_category,
        "lastFyYear":val.nine_month_purchase,
        "currentFyYear":val.twelve_month_purchase,
        "gst":val.gst,
        "mrp":val.mrp,
      }
      return exportDATA
    })

    console.log("filterData", result);
    let header = [
      "CODE",
      "DESCRIPTION",
      "GENERIC",
      "MATERIAL CATEGORY",
      "Last FY Year",
      "Current FY Year",
      "GST%",
      "MRP",
    ];

    const ws = XLSX.utils.book_new();

    XLSX.utils.sheet_add_aoa(ws, [header], {
      origin: "A1",
    });
    XLSX.utils.sheet_add_json(ws, result, {
      origin: "A2",
      skipHeader: true,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"], range: 5 };
    /* TEST: column props */
    // var wscols = [ {wch:20,color:"red"}, {wch:30}, {wch:40}, {wch:30},{wch:10}, {wch:10}];
    // ws['!cols'] = wscols;

    ws["A1"].style = {
      font: {
        name: "arial",
        sz: 24,
        bold: true,
        color: "#F2F2F2",
      },
    };
    var wscols = [
      { width: 15 },
      { width: 28 },
      { width: 28 },
      { width: 20 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
    ];
    ws["!cols"] = wscols;
    const excelBuffer = XLSX.write(wb, {
      bookType: fileExtn,
      type: "array",
      cellStyles: true,
    });
    const finalData = new Blob([excelBuffer], { type: "xlsx" });
    FileSaver.saveAs(finalData, filename);
  }

  // delete by id
  const DrugDeleteHandle = (id) => {
    // console.log("delete bid id " + id);
    swal({
      title: "Are you sure? You want to delete this drug",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .delete(`/drug/delete_drug_master/${id}`)
          .then(function (response) {
            if (!response.data.hasError) {
              getAllDrugs();
              swal({
                title: `${response.data.message}`,
                // text: "You clicked the button!",
                icon: "success",
                button: "Okay!",
              });
            } else {
              swal({
                title: `${response.data.message}`,
                // text: "You clicked the button!",
                icon: "error",
                button: "Okay!",
              });
            }
          })

          .catch(function (response) {
            //handle error
            console.log(response);
          });
        console.log(id + " deleted this drug");
      }
    });
  };

  //edit by id
  const DrugEditById = (id) => {
    // console.log(id, "Edit id");
    axios.get(`/drug/getDrugMaster/${id}`).then((response) => {
      //  console.log(response.data.data[0]);
      if (!response.data.hasError) {
        setRowData(response?.data?.data[0]);
      } else {
        console.log(response?.data?.data[0])
        swal({
          title: `${response.data.message}`,
          // text: "You clicked the button!",
          icon: "error",
          button: "Okay!",
        });
      }
    });
  };

  //handleChangeUpdate
  const handleChangeUpdateData = (e) => {
    setRowData({ ...rowData, [e.target.name]: e.target.value });
  };

 
 

  const UpdatedData = {
    id: rowData?.id,
    code: rowData?.code,
    description: rowData?.description,
    generic: rowData?.generic,
    material_category: rowData?.material_category,
    nine_month_purchase: rowData?.nine_month_purchase,
    twelve_month_purchase: rowData?.twelve_month_purchase,
    gst: rowData?.gst,
    mrp: rowData?.mrp,
  };

  //update existing data
  const handleSubmitUpdate = (e) => {
    e.preventDefault();
    if (rowData?.code === "") {
      document.getElementById("error_code").innerHTML = "please enter code";
    } else if (rowData?.description === "") {
      document.getElementById("error_description").innerHTML =
        "please enter description";
    } else if (rowData?.generic === "") {
      document.getElementById("error_generic").innerHTML =
        "please enter generic";
    } else if (rowData?.material_category === "") {
      document.getElementById("error_material").innerHTML =
        "please select material catergory";
    } else if (rowData?.nine_month_purchase === "") {
      document.getElementById("error_nine").innerHTML =
        "please enter nine month purchase";
    } else if (rowData?.twelve_month_purchase === "") {
      document.getElementById("error_twelve").innerHTML =
        "please enter twelve month purchase";
    } else if (rowData?.gst === "") {
      document.getElementById("error_gst").innerHTML = "please enter gst";
    } else if (rowData?.mrp === "") {
      document.getElementById("error_mrp").innerHTML = "please enter mrp";
    } else {
      console.log("Update Data ", UpdatedData);
      axios
        .put("/drug/update_drug_master", UpdatedData)
        .then((response) => {
          // console.log(response.data);
          if (!response.data.hasError) {
            setModalCentered(false);
            getAllDrugs();
            swal({
              title: `${response.data.message}`,
              // text: "You clicked the button!",
              icon: "success",
              button: "Okay!",
              allowOutsideClick: false,
            });
          } else {
            swal({
              title: `${response.data.message}`,
              // text: "You clicked the button!",
              icon: "error",
              button: "Okay!",
            });
          }
          // setRowData(response.data.data[0]);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  let userId = localStorage.getItem("userId");
  //add new data
  var myurl = "/drug/create_drug";
  const handleSubmitNewData = (values, { setSubmitting, resetForm }) => {
    var bodyFormData = {
      code: values.code,
      description: values.description,
      generic: values.generic,
      material_category: values.material_category,
      nine_month_purchase: values.nine_month_purchase,
      twelve_month_purchase: values.twelve_month_purchase,
      gst: values.gst,
      mrp: values.mrp,
      created_by_user: userId,
    };
    console.log(bodyFormData);

    axios
      .post(myurl, bodyFormData)
      .then(function (response) {
        if (!response.data.hasError) {
          setShow(false);
          resetForm({});
          getAllDrugs();
          swal({
            title: `${response.data.message}`,
            // text: "You clicked the button!",
            icon: "success",
            button: "Okay!",
          });
        } else {
          swal({
            title: `${response.data.message}`,
            // text: "You clicked the button!",
            icon: "error",
            button: "Okay!",
          });
        }
      })
      .catch(function (response) {
        setSubmitting(false);
        console.log(response);
      });
  };
  //initial empty values
  const defaultValue = {
    code: "",
    description: "",
    generic: "",
    material_category: "",
    nine_month_purchase: "",
    twelve_month_purchase: "",
    gst: "",
    mrp: "",
  };
  //validation code
  const validationSchema = yup.object().shape({
    code: yup.string().required("Please enter code"),
    description: yup.string().required("Please enter description"),
    generic: yup.string().required("Please enter generic"),
    material_category: yup
      .string()
      .required("Please select material catergory"),
    nine_month_purchase: yup
      .number()
      .required("Please enter nine month purchase"),
    twelve_month_purchase: yup
      .number()
      .required("Please enter twelve month purchase"),
    gst: yup.number().required("Please enter GST"),
    mrp: yup.number().required("Please enter MRP"),
  });

  const [open, setOpen] = React.useState(false);
  const [file, setFile] = useState(null);

  //uploading files importing
  const changeHandler = (e) => {
    console.log(e.target.files);

    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append("import_data_input", e.target.files[0]);
      formData.append("created_by_user", userId);

      swal({
        title: "Are you sure? You want to upload this file",
        text: "",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willUpload) => {
        if (willUpload) {
          setOpen(true);
          axios
            .post("/drug/importDrug", formData)
            .then((response) => {
              console.log(response);
              if (response?.data?.hasError) {
                console.log(response);
                setOpen(false);
                setFile(e.target.files);
                swal({
                  title: response?.data?.message,
                  // text: "You clicked the button!",
                  icon: "error",
                  button: "Okay!",
                });
                e.target.value = null;
                console.log(response);
              } else {
                setOpen(false);
                setFile(e.target.files);
                swal({
                  title: response?.data?.message,
                  // text: "You clicked the button!",
                  icon: "success",
                  button: "Okay!",
                });

                e.target.value = null;
                console.log(response);
                getAllDrugs();
              }
            })
            .catch(({ error }) => {
              setOpen(false);
              swal({
                title:
                  "Something went wrong! network error unable to upload all data",
                // text: "You clicked the button!",
                icon: "error",
                button: "Okay!",
              });
              console.log(error);
            });
        }
      });
    }
  };

  const onSearchHandler = (e) => {
    console.log(e.target.value, e.target.value.length);
    setSearchResult({ result: e.target.value, length: e.target.value.length });
  };

  const lowersearch = searchResult?.result?.toString().toLowerCase().trim();
  // console.log(lowersearch);

  const drugsearchall = UpdateList?.filter(
    (sv) =>
      sv.code.toLowerCase().includes(lowersearch) ||
      sv.description.toLowerCase().includes(lowersearch) ||
      sv.generic.toLowerCase().includes(lowersearch) ||
      sv.material_category.toLowerCase().includes(lowersearch) ||
      sv.nine_month_purchase.toString().includes(lowersearch) ||
      sv.twelve_month_purchase.toString().includes(lowersearch) ||
      sv.gst.toString().includes(lowersearch) ||
      sv.mrp.toString().includes(lowersearch)
  ).map((sitem) => {
    return sitem;
  });

  useEffect(() => {
    const vendorpagedata =
      rowsPerPage > 0
        ? UpdateList?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
        : UpdateList;

    setVendorCheckedData(vendorpagedata);
  }, [UpdateList]);

  useEffect(() => {
    const vendorpagedata =
      rowsPerPage > 0
        ? UpdateList?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
        : UpdateList;

    setVendorCheckedData(vendorpagedata);
  }, [rowsPerPage]);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);

    console.log(
      "isCheckall",
      UpdateList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    );

    const drugall = UpdateList?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    setIsCheck(drugall?.map((li) => li.id.toString()));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;

    /*    setIsCheck({ ...id, id }); */

    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id.toString()));
    }
  };

  console.log(isCheck.toString());

  const deleteAllHandle = () => {
    const deletealldata = {
      drug_id: isCheck.toString(),
    };

    console.log(deletealldata);

    swal({
      title: "Are you sure. You want to Delete Selected Drugs ?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Selected Drugs has been deleted!", {
          icon: "success",
          timer: 1000,
        });

        axios.post("/drug/deleteSelectedDrug", deletealldata).then((res) => {
          console.log(res);
          getAllDrugs();
        });
      }
    });
  };

  console.log(isCheck);

  let GST = [0, 5, 12, 15, 18, 28];
  return (
    <>
      <PageTitle activeMenu="Drug Masters" motherMenu="Drugs" />
      <div className="row">
        {/*  <PageTitle activeMenu="Users" motherMenu="Dashboard" /> */}
        {file ? (
          ""
        ) : (
          <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <div className="col-md-12">
          {/* <h4 className="px-3">Users</h4> */}
          <div className="card ">
            <div className="card-header ">
              <input
                className="form-control mx-4 text-black"
                type="text"
                onChange={onSearchHandler}
                placeholder="Search Drug"
                id="mysearchInput"
                /*        onKeyUp={mySearchFunction} */
              />
              <div className="d-flex">
                <i
                  className="fa fa-plus-circle mx-2"
                  style={{
                    fontSize: "30px",
                    color: "var(--primary)",
                    cursor: "pointer",
                  }}
                  onClick={handleShow}
                ></i>
                <div>
                  <div className="dropZoneContainer">
                    <input
                      type="file"
                      id="drop_zone"
                      className="FileUpload"
                      accept=".csv"
                      onChange={changeHandler}
                    />
                    <div className="dropZoneOverlay">
                      <i
                        htmlFor="drop_zone"
                        id="submit"
                        className="fa fa-cloud-upload "
                        style={{
                          fontSize: "30px",
                          color: "var(--primary)",
                          cursor: "pointer",
                          content: "import",
                        }}
                      ></i>
                    </div>
                  </div>
                </div>

                <i
                  id="btn-export"
                  onClick={(e) => fnExport("xlsx", "DrugSheet.xlsx", e)}
                  class="fa fa-cloud-download  mx-2"
                  style={{
                    fontSize: "30px",
                    color: "var(--primary)",
                    cursor: "pointer",
                  }}
                  aria-hidden="true"
                ></i>
              </div>
            </div>
            <div className="card-body py-1">
              <div className="w-100 table-responsive">
                <div
                  id="patientTable_basic_table"
                  className="dataTables_wrapper"
                >
                  <table
                    className="table shadow-hover card-table border-no tbl-btn text-center text-black"
                    id="drug_table"
                  >
                    <thead>
                      <th>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            name="selectAll"
                            onChange={handleSelectAll}
                            className="custom-control-input"
                            id="selectAll"
                            checked={
                              isCheck?.length ===
                              UpdateList?.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )?.length
                                ? true
                                : false
                            }
                            /*  checked={props.isCheck?.drug_id} */
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="selectAll"
                          />
                        </div>
                      </th>
                      <th scope="">Code</th>
                      <th scope="">Description</th>
                      <th scope="">Generic</th>
                      <th scope="">Material Category</th>
                      <th scope="">Last FY Year</th>
                      <th scope="">Current FY Year</th>
                      <th scope="">GST</th>
                      <th scope="">MRP</th>
                      <th scope="">Action</th>
                    </thead>
                    <tbody>
                      {UpdateList?.length === 0 ? (
                        <tr>
                          <td className="text-danger" colSpan={7}>
                            Drug master has been not created yet
                          </td>
                        </tr>
                      ) : (
                        <>
                          {searchResult.length > 0 ? (
                            <>
                              {drugsearchall.length === 0 ? (
                                <>
                                  <tr>
                                    <td colSpan={7}>
                                      <h6 className="text-danger">
                                        No Records Found
                                      </h6>
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <>{""}</>
                              )}
                            </>
                          ) : (
                            <></>
                          )}

                          {searchResult.length > 0 ? (
                            <>
                              {drugsearchall?.map((dtem, i) => (
                                <React.Fragment>
                                  {drugsearchall.length > 0 ? (
                                    <>
                                      <tr>
                                        <td>{" "}</td>
                                        <td>{dtem.code}</td>
                                        <td>{dtem.description}</td>
                                        <td>{dtem.generic}</td>
                                        <td>
                                          {" "}
                                          {materiallist
                                            .filter(
                                              (val) =>
                                                val?.id ==
                                                dtem?.material_category
                                            )
                                            .map((mat) => (
                                              <span>
                                                {" "}
                                                {mat?.material_category}
                                              </span>
                                            ))}
                                        </td>
                                        <td>{dtem.nine_month_purchase}</td>
                                        <td>{dtem.twelve_month_purchase}</td>
                                        <td>{dtem.gst}</td>
                                        <td>{dtem.mrp}</td>
                                        <td>
                                          <div className="d-flex">
                                            <button
                                              onClick={() => {
                                                setModalCentered(true);
                                                DrugEditById(dtem?.id);
                                              }}
                                              className="btn btn-warning shadow btn-xs sharp mr-1"
                                            >
                                              <i className="fa fa-pencil"></i>
                                            </button>
                                            <button
                                              onClick={(e) =>
                                                DrugDeleteHandle(dtem?.id)
                                              }
                                              className="btn btn-danger shadow btn-xs sharp"
                                            >
                                              <i className="fa fa-trash"></i>
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </React.Fragment>
                              ))}
                            </>
                          ) : (
                            <>
                              {(rowsPerPage > 0
                                ? UpdateList?.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                : UpdateList
                              ).map((data, i) => (
                                <tr key={i}>
                                  <td>
                                    <div className="custom-control custom-checkbox ">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        name={data.id}
                                        id={data.id.toString()}
                                        onChange={handleClick}
                                        checked={isCheck?.includes(
                                          data.id.toString()
                                        )}
                                      />

                                      <label
                                        className="custom-control-label"
                                        htmlFor={data.id.toString()}
                                      />
                                    </div>
                                  </td>
                                  <td>{data?.code}</td>
                                  <td>
                                    <span>{data?.description}</span>
                                  </td>
                                  <td>
                                    <span>{data?.generic} </span>
                                  </td>
                                  <td>
                                    {materiallist
                                      .filter(
                                        (val) =>
                                          val?.id == data?.material_category
                                      )
                                      .map((mat) => (
                                        <span> {mat?.material_category}</span>
                                      ))}
                                  </td>
                                  <td>
                                    <span> {data?.nine_month_purchase}</span>
                                  </td>
                                  <td>
                                    <span> {data?.twelve_month_purchase}</span>
                                  </td>
                                  <td>
                                    <span> {data?.gst}%</span>
                                  </td>
                                  <td>
                                    <span> {data.mrp}</span>
                                  </td>

                                  <td>
                                    <div className="d-flex">
                                      <button
                                        onClick={() => {
                                          setModalCentered(true);
                                          DrugEditById(data?.id);
                                        }}
                                        className="btn btn-warning shadow btn-xs sharp mr-1"
                                      >
                                        <i className="fa fa-pencil"></i>
                                      </button>
                                      <button
                                        onClick={(e) =>
                                          DrugDeleteHandle(data?.id)
                                        }
                                        className="btn btn-danger shadow btn-xs sharp"
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}

                          {emptyRows > 0 && (
                            <tr style={{ height: 41 * emptyRows }}>
                              <td colSpan={9} />
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>

                    <tfoot>
                      <tr>
                        <td
                          /*     colSpan={1} */
                          className=""
                        >
                          {isCheck.length > 0 ? (
                            <div className="d-flex justify-content-start align-items-baseline">
                              {/*        <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                name="selectAll"
                                onChange={handleSelectAll}
                                className="custom-control-input"
                                id="selectAll"
                                checked={isCheckAll}
                              
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="selectAll"
                              />
                            </div> */}

                              <div className="d-flex justify-content-center">
                                <button
                                  onClick={deleteAllHandle}
                                  /*      onClick={() => VendorDeleteHandle} */
                                  className="btn btn-danger shadow d-flex align-items-baseline"
                                  style={{
                                    borderRadius: "0px",
                                    paddingRight: "20px",
                                    paddingLeft: "20px",
                                  }}
                                >
                                  <i className="fa fa-trash fa-1x"></i>
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div style={{ marginRight: "0px" }}></div>
                          )}
                        </td>

                        <CustomTablePagination
                          rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
                          colSpan={9}
                          count={UpdateList?.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          componentsProps={{
                            select: {
                              "aria-label": "rows per page",
                            },
                            actions: {
                              showFirstButton: true,
                              showLastButton: true,
                            },
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </tr>
                    </tfoot>
                  </table>
                  <div id="error" className="text-center"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* add new data modal */}
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>Create a drug</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* Create group module */}
          <div className="col-sm-12 col-md-12">
            <div className=" flex-wrap justify-content-around">
              <Formik
                initialValues={defaultValue}
                validationSchema={validationSchema}
                onSubmit={handleSubmitNewData}
              >
                {({ isSubmitting, errors, isValid, touched }) => (
                  <Form>
                    <div className="my-auto">
                      <label className="form-label">
                        Code<span className="text-danger mx-1">*</span>
                      </label>
                      <Field
                        type="text"
                        name="code"
                        // value={recordData.code}
                        // onChange={(e) => handleChange(e)}
                        // onKeyPress={(event) => {
                        //     if (!/^[0-9\b]+$/.test(event.key)) {
                        //       event.preventDefault();
                        //     }
                        //   }}
                        className="form-control"
                        placeholder="for example: '#24122'"
                      />
                      {errors.code && touched.code ? (
                        <div className="text-danger">{errors.code}</div>
                      ) : null}
                    </div>

                    <div className="my-auto">
                      <label className="form-label">
                        Description<span className="text-danger mx-1">*</span>
                      </label>
                      <Field
                        type="text"
                        name="description"
                        // value={recordData.description}
                        // onChange={(e) => handleChange(e)}
                        className="form-control"
                      />
                      {errors.description && touched.description ? (
                        <div className="text-danger">{errors.description}</div>
                      ) : null}
                    </div>
                    <div className="my-auto">
                      <label className="form-label">
                        Generic<span className="text-danger mx-1">*</span>
                      </label>
                      <Field
                        type="text"
                        name="generic"
                        // value={recordData.generic}
                        // onChange={(e) => handleChange(e)}
                        className="form-control"
                      />
                      {errors.generic && touched.generic ? (
                        <div className="text-danger">{errors.generic}</div>
                      ) : null}
                    </div>
                    <div className="my-auto">
                      <label for="exampleFormControlSelect1">
                        Material Category
                        <span className="text-danger mx-1">*</span>
                      </label>

                      <Field
                        component="select"
                        class="form-control my-2"
                        id="material_category"
                        name="material_category"

                        // onChange={(e) => handleChangeUpdateData(e)}
                      >
                        {error_notfound ? (
                          <option value="" disabled>
                            Material category is not exist
                          </option>
                        ) : (
                          <>
                            <option value="" disabled>
                              Select Material Category
                            </option>

                            {materiallist.map((val) => (
                              <option value={val.id}>
                                {val.material_category}
                              </option>
                            ))}
                          </>
                        )}
                      </Field>
                      {errors.material_category && touched.material_category ? (
                        <div className="text-danger">
                          {errors.material_category}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-auto">
                      <label className="form-label">
                        Last FY Year
                        <span className="text-danger mx-1">*</span>
                      </label>
                      <Field
                        type="text"
                        name="nine_month_purchase"
                        // value={recordData.nine_month_purchase}
                        // onChange={(e) => handleChange(e)}
                        className="form-control"
                        onKeyPress={(event) => {
                          if (!/^[0-9\b]+$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {errors.nine_month_purchase &&
                      touched.nine_month_purchase ? (
                        <div className="text-danger">
                          {errors.nine_month_purchase}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-auto">
                      <label className="form-label">
                        Current FY Year
                        <span className="text-danger mx-1">*</span>
                      </label>
                      <Field
                        type="text"
                        name="twelve_month_purchase"
                        // value={recordData.twelve_month_purchase}
                        // onChange={(e) => handleChange(e)}
                        className="form-control"
                        onKeyPress={(event) => {
                          if (!/^[0-9\b]+$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {errors.twelve_month_purchase &&
                      touched.twelve_month_purchase ? (
                        <div className="text-danger">
                          {errors.twelve_month_purchase}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-auto">
                      <label for="exampleFormControlSelect1">
                        GST
                        <span className="text-danger mx-1 ">*</span>
                      </label>

                      <Field
                        component="select"
                        class="form-control"
                        id="gst"
                        name="gst"

                        // onChange={(e) => handleChangeUpdateData(e)}
                      >
                        {error_notfound ? (
                          <option value="" disabled>
                            Gst is not exist
                          </option>
                        ) : (
                          <>
                            <option value="" disabled>
                              Select Gst %
                            </option>

                            {GST?.map((val) => (
                              <option value={val}>{val}%</option>
                            ))}
                          </>
                        )}
                      </Field>
                      {errors.material_category && touched.material_category ? (
                        <div className="text-danger">
                          {errors.material_category}
                        </div>
                      ) : null}
                    </div>
                    <div className="my-auto">
                      <label className="form-label">
                        MRP<span className="text-danger mx-1">*</span>
                      </label>
                      <Field
                        type="text"
                        name="mrp"
                        // value={recordData.mrp}
                        // onChange={(e) => handleChange(e)}
                        className="form-control"
                      />
                      {errors.mrp && touched.mrp ? (
                        <div className="text-danger">{errors.mrp}</div>
                      ) : null}
                    </div>

                    <div className="d-flex justify-content-end my-4">
                      <button
                        onClick={handleClose}
                        className="btn btn-danger mx-2"
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!isValid}
                      >
                        SUBMIT
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* edit modal */}
      <Modal
        className="fade"
        show={modalCentered}
        onHide={setModalCentered}
        backdrop="static"
      >
        <Modal.Header>
          <h4 className="text-black">Edit Drug</h4>
          <Button
            onClick={() => {
              setModalCentered(false);
              getAllDrugs();
            }}
            variant=""
            className="close"
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="col-sm-12 col-md-12">
            <div className=" flex-wrap justify-content-center">
              <form onSubmit={handleSubmitUpdate}>
                <div className="my-3">
                  <label className="form-label">
                    Code<span className="text-danger mx-1">*</span>
                  </label>
                  <input
                    id="code"
                    name="code"
                    type="text"
                    value={rowData?.code}
                    onChange={(e) => handleChangeUpdateData(e)}
                    className="form-control my-2"
                    required
                  />
                  <div id="error_code" className="text_danger"></div>
                  <label className="form-label">
                    Description<span className="text-danger mx-1">*</span>
                  </label>
                  <input
                    id="description"
                    name="description"
                    type="text"
                    value={rowData?.description}
                    onChange={(e) => handleChangeUpdateData(e)}
                    className="form-control my-2"
                    required
                  />
                  <div id="error_description" className="text_danger"></div>
                  <label className="form-label">
                    Generic<span className="text-danger mx-1">*</span>
                  </label>
                  <input
                    id="generic"
                    name="generic"
                    type="text"
                    value={rowData?.generic}
                    onChange={(e) => handleChangeUpdateData(e)}
                    className="form-control my-2"
                    required
                  />
                  <div id="error_generic" className="text_danger"></div>
                  <label for="exampleFormControlSelect1">
                    Material Category<span className="text-danger mx-1">*</span>
                  </label>

                  <select
                    class="form-control my-2"
                    id="material_category"
                    name="material_category"
                    value={rowData?.material_category}
                    onChange={(e) => handleChangeUpdateData(e)}
                    required
                  >
                    <option
                      value=""
                      disabled
                      style={{ backgroundColor: "skyblue" }}
                    >
                      {/* {rowData?.material_category} */}Select Material
                      Category
                    </option>
                    {materiallist?.map((val) => (
                      <option value={val?.id}>{val?.material_category}</option>
                    ))}
                  </select>
                  <div id="error_material" className="text_danger"></div>
                  <label className="form-label">
                    Last FY Year<span className="text-danger mx-1">*</span>
                  </label>
                  <input
                    id="nine_month_purchase"
                    name="nine_month_purchase"
                    type="text"
                    value={rowData?.nine_month_purchase}
                    onChange={(e) => handleChangeUpdateData(e)}
                    className="form-control my-2"
                    onKeyPress={(event) => {
                      if (!/^[0-9\b]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    required
                  />
                  <div id="error_nine" className="text_danger"></div>
                  <label className="form-label">
                    Current FY Year<span className="text-danger mx-1">*</span>
                  </label>
                  <input
                    id="twelve_month_purchase"
                    name="twelve_month_purchase"
                    type="text"
                    value={rowData?.twelve_month_purchase}
                    onChange={(e) => handleChangeUpdateData(e)}
                    className="form-control my-2"
                    onKeyPress={(event) => {
                      if (!/^[0-9\b]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    required
                  />
                  <div id="error_twelve" className="text_danger"></div>
                  <label className="form-label">
                    GST<span className="text-danger mx-1">*</span>
                  </label>
                  <select
                    class="form-control"
                    id="gst"
                    name="gst"
                    value={rowData?.gst}
                    onChange={(e) => handleChangeUpdateData(e)}
                    required
                  >
                    <option
                      value=""
                      disabled
                      style={{ backgroundColor: "skyblue" }}
                    >
                      {/* {rowData?.gst} */} Select Gst %
                    </option>
                    {GST?.map((val) => (
                      <option value={val}>{val}%</option>
                    ))}
                  </select>
                  <div id="error_gst" className="text_danger"></div>
                  <label className="form-label">
                    MRP<span className="text-danger mx-1">*</span>
                  </label>
                  <input
                    id="mrp"
                    name="mrp"
                    type="text"
                    value={rowData?.mrp}
                    onChange={(e) => handleChangeUpdateData(e)}
                    className="form-control my-2"
                    onKeyPress={(event) => {
                      if (!/^[0-9\b]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    required
                  />
                  <div id="error_mrp" className="text_danger"></div>
                </div>

                <div className="d-flex justify-content-end my-4">
                  <Button
                    onClick={() => {
                      setModalCentered(false);
                      getAllDrugs();
                    }}
                    variant=""
                    className="btn btn-danger mx-2"
                  >
                    Close
                  </Button>
                  <button type="submit" className="btn btn-secondary">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DrugMaster;
