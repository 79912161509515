import axios from "axios";
import swal from "sweetalert";
// import {
//     logout,
//   } from "./AuthActions";


export const CHANGE_PASSWORD_FAILED_ACTION = '[changePasswordFailedAction] faildpasswordChange';
export const CHANGE_PASSWORD_SUCESS_ACTION = '[changePasswordSucessAction] passwordChange';


export function changePasswordFailedAction(data) {
    
    return {
        type: CHANGE_PASSWORD_FAILED_ACTION,
        payload: data,
    };
}

export function changePasswordSucessAction(data) {
    return {
        type: CHANGE_PASSWORD_SUCESS_ACTION,
        payload: data,
    };
}

export function changePasswordAction(passwords) {
    return (dispatch) => {

        const data = {
            user_id:passwords.userId,
            old_password:passwords.oldPassword,
            password:passwords.newPassword,
        }
        
        axios.post(`/user/changeOldPassword`, data)
            .then((response) => {  
                if (response.status === 200 && response.data.hasError === false) {
                     dispatch(changePasswordSucessAction(response.data));
                     swal({ title:  response?.data?.message, icon: "success", timer: 3000 });
                }else if( response.data.hasError === true){
                    dispatch(changePasswordFailedAction(response.data));
                    swal({ title: response?.data?.message, icon: "error", timer: 3000 });
                }
                   
            
            }).catch((error) => {
                // dispatch(PasswordFailedAction)(error);
                  console.log(error);
              });
      };
  }

