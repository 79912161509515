import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import swal from "sweetalert";
import axios from "axios";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useFormik, Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./vendorcss.css";
import {
  addVendor,
  loadallVendors,
} from "../../../../store/actions/VendorActions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
function AddVendor(props) {
  const dispatch = useDispatch();

  const [modalCentered, setModalCentered] = useState(false);

  const [getMaterial, setGetMaterial] = useState([]);
  const [selectedmaterial, setSelectedMaterial] = useState([]);
  const [mapMaterial, setMapMaterial] = useState([]);
  const [addVendorData, setAddVendorData] = useState({
    company_name: "",
    contact_person_1: "",
    contact_person_1_email: "",
    contact_person_1_phone: "",
    company_address: "",
    created_by_user: "1",
    select_material_category: "",
  });
  const [formikData, setFormikData] = useState({});
  const [materialcategoryerror, setMaterialCategoryError] = useState(false);

  /// get All Material Category
  /*   const getmaterialaxios = () => {
    axios
      .get(
        "/material_category/getAllMaterialCategory"
      )
      .then((res) => {
        setGetMaterial(res.data.data);
   
      });
  };

  useEffect(() => {
    getmaterialaxios();
  }, []);
 */
  /*   useEffect(() => {
    setAddVendorData({ ...addVendorData, select_material_category: "65" });
  }, []); */

  const addVendorChange = (e) => {
    setAddVendorData({ ...addVendorData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (selectedmaterial.length !== 0) {
      setMaterialCategoryError(false);
    }
  }, [selectedmaterial]);
  const localuserid = localStorage.getItem("userId");
  const addVendorSubmit = (e) => {
    if (props.materiallist.length === 0) return;

    if (selectedmaterial.length === 0) {
      console.log("has No Values");
      setMaterialCategoryError(true);
    } else {
      setMaterialCategoryError(false);
      const vendordata = {
        company_name: e.company_name,
        contact_person_1: e.contact_person_1,
        contact_person_2: e.contact_person_2,
        email_id: e.email_id,
        contact_person_2_email: e.contact_person_2_email,
        contact_person_1_phone: e.contact_person_1_phone,
        contact_person_2_phone: e.contact_person_2_phone,
        company_address: e.company_address,
        created_by_user: localuserid,
        material_category_id: selectedmaterial,
      };

      console.log(vendordata);
      /*   setAddVendorData({ ...e, select_material_category: selectedmaterial }); */
      setAddVendorData({ ...addVendorData, e });
      /* 
      swal({
        title: "Vender Added Successfully",
        icon: "success",
        timer: 1000,
      });
      console.log(addVendorData); */
      /*     dispatch(addVendor(addVendorData)); */

      axios.post("/vendor/create_vendor", vendordata).then((response) => {
        console.log(response);
        if (response.data.hasError) {
          swal({
            title: "Vendor was not added",
            text: `Please try a different email and phone number`,
            icon: "error",
          });
        }

        if (response.data.hasError !== true) {
          swal({
            title: "Vendor Added Successfully",
            text: `Vendor One Time Password is ${response.data.data.mandatory_pwd_change} `,
            icon: "success",
          });
        }

        dispatch(loadallVendors());
        setModalCentered(false);
      });
    }
  };

  /*   const AddVendorHandle = () => {
    var digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < 4; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    swal(
      "Vendor Added Successfully ",
      `Your One Time Password is ${OTP}`,
      "success"
    );
    setModalCentered(false);
  }; */

  /*   const materialcategoryadd = () => {
    axios.get('/material_category/getAllMaterialCategory').then(response=> )
  }
 */

  //formik form integrate

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  //Validation Start For Formik
  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required("Please Enter Company Name"),
    contact_person_1: Yup.string().required("Please Enter Person Name"),

    email_id: Yup.string()
      .required("Please Enter Email")
      .email("Email is invalid"),
    contact_person_1_phone: Yup.string()
      .required("Please Enter Contact Number")
      .matches(phoneRegExp, "Phone Number is not Valid")
      .min(10, "to Short")
      .max(10, "to Long"),
    /*   contact_person_2: Yup.string().required("Please Enter Person Name"),

    contact_person_2_email: Yup.string()
      .required("Please Enter Email")
      .email("Email is invalid"),
    contact_person_2_phone: Yup.string()
      .required("Please Enter Contact Number")
      .matches(phoneRegExp, "Phone Number is not Valid")
      .min(10, "to Short")
      .max(10, "to Long"), */
    company_address: Yup.string().required("Please Enter Address"),
  });

  const formik = useFormik({
    initialValues: {
      company_name: "",
      contact_person_1: "",
      contact_person_2: "",
      email_id: "",
      contact_person_2_email: "",
      contact_person_1_phone: "",
      contact_person_2_phone: "",
      company_address: "",
      created_by_user: "1",
    },
    validationSchema,
    /*     enableReintialize= true, */
    validateOnChange: false,
    validateOnBlur: false,

    onSubmit: (data) => {
      addVendorSubmit(data);

      /*   alert(JSON.stringify(data, null, 2)); */
      formik.handleReset();
    },
    onReset: () => {
      setModalCentered(false);
    },
  });

  /*  ; */

  /*   const materiallisst = () => {

    const mate = getMaterial.map((mat) => {
      return (
        key: mat.id,
        label: mat.material_category,
      )

    } )
  }
   */

  /*   const materialdd = materiallist.map((data) => ({
    label: data.Name,
    value: data.Id,
  }));
  useEffect(() => {
    materialdd();
  }, []);

  console.log(materialdd); */

  /* const setOptions = () => {
    ;
    const matdata = getMaterial.map((mat, index) => {
      return {
        key: `${mat.id}`,
        label: `${mat.material_category}`,
      };
    });
    setMapMaterial(matdata);
  };
  console.log(mapMaterial);
  ;
  useEffect(() => {
    setTimeout(() => {
      setOptions();
    }, 2000);
  }, []);
 */

  console.log(selectedmaterial);
  return (
    <div>
      {/* <div className="col-sm-6 col-md-12 d-flex justify-content-end"> */}
      <i
        className="fa fa-plus-circle primary mx-2"
        onClick={() => setModalCentered(true)}
        style={{ fontSize: "30px", color: "var(--primary)", cursor: "pointer" }}
      ></i>
      {/* </div> */}
      <div>
        {/* <!-- Modal --> */}
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <Modal
            className="fade"
            show={modalCentered}
            onHide={setModalCentered}
          >
            <Modal.Header>
              <h4 className="text-black">Add Vendor</h4>
              <Button
                onClick={() => setModalCentered(false)}
                variant=""
                className="close"
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>
              <div className="col-sm-6 col-md-12">
                <div className=" flex-wrap justify-content-around">
                  <div className="form-group  ">
                    <label htmlFor="">
                      Company Name <span className="text-danger">*</span>
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      name="company_name"
                      required
                      onChange={formik.handleChange}
                      value={formik.values.company_name}
                    />

                    <div className="text-danger">
                      {formik.errors.company_name
                        ? formik.errors.company_name
                        : null}
                    </div>

                    <input
                      type="hidden"
                      className="form-control"
                      name="created_by_user"
                      required
                      onChange={formik.handleChange}
                      value={formik.values.created_by_user}
                    />
                    {/*         <input
                      type="hidden"
                      className="form-control"
                      name="select_material_category"
                      required
                      onChange={formik.handleChange}
                      value={selectedmaterial}
                    /> */}
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Contact Person 1 <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="contact_person_1"
                      required
                      onChange={formik.handleChange}
                      value={formik.values.contact_person_1}
                    />
                    <div className="text-danger">
                      {formik.errors.contact_person_1
                        ? formik.errors.contact_person_1
                        : null}
                    </div>
                  </div>
                </div>
                <div className="flex-wrap justify-content-around">
                  <div className="form-group  ">
                    <label htmlFor="">
                      Email ID 1 <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      required
                      name="email_id"
                      className="form-control"
                      onChange={formik.handleChange}
                      value={formik.values.email_id}
                    />
                    <div className="text-danger">
                      {formik.errors.email_id ? formik.errors.email_id : null}
                    </div>
                  </div>
                  <div className="form-group  ">
                    <label htmlFor="">
                      Phone No 1 <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      name="contact_person_1_phone"
                      className="form-control"
                      required
                      onChange={formik.handleChange}
                      value={formik.values.contact_person_1_phone}
                    />
                    <div className="text-danger">
                      {formik.errors.contact_person_1_phone
                        ? formik.errors.contact_person_1_phone
                        : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Contact Person 2</label>
                    <input
                      type="text"
                      className="form-control"
                      name="contact_person_2"
                      required
                      onChange={formik.handleChange}
                      value={formik.values.contact_person_2}
                    />
                    <div className="text-danger">
                      {formik.errors.contact_person_2
                        ? formik.errors.contact_person_2
                        : null}
                    </div>
                  </div>
                </div>
                <div className="flex-wrap justify-content-around">
                  <div className="form-group  ">
                    <label htmlFor="">Email ID 2</label>
                    <input
                      type="text"
                      required
                      name="contact_person_2_email"
                      className="form-control"
                      onChange={formik.handleChange}
                      value={formik.values.contact_person_2_email}
                    />
                    <div className="text-danger">
                      {formik.errors.contact_person_2_email
                        ? formik.errors.contact_person_2_email
                        : null}
                    </div>
                  </div>
                  <div className="form-group  ">
                    <label htmlFor="">Phone No 2 </label>
                    <input
                      type="text"
                      name="contact_person_2_phone"
                      className="form-control"
                      required
                      onChange={formik.handleChange}
                      value={formik.values.contact_person_2_phone}
                    />
                    <div className="text-danger">
                      {formik.errors.contact_person_2_phone
                        ? formik.errors.contact_person_2_phone
                        : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Address <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      required
                      name="company_address"
                      className="form-control"
                      onChange={formik.handleChange}
                      value={formik.values.company_address}
                    />
                    <div className="text-danger">
                      {formik.errors.company_address
                        ? formik.errors.company_address
                        : null}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-md-12  h50">
                    <div className="form-group "></div>

                    <label htmlFor="">
                      Select Material Category{" "}
                      <span className="text-danger">*</span>
                    </label>

                    {props.materiallist.length === 0 ? (
                      <p style={{ color: "red" }}>
                        No material category available, please create one first.
                      </p>
                    ) : (
                      <DropdownMultiselect
                        handleOnChange={(selected) => {
                          setSelectedMaterial(selected);
                        }}
                        buttonClass="btn-light "
                        type="select"
                        onChange={formik.handleChange}
                        options={props.materiallist.map((mat) => {
                          return {
                            key: mat.id,
                            label: mat.material_category,
                          };
                        })}
                        name="select_smaterial_categodry"
                      />
                    )}
                    {materialcategoryerror ? (
                      <span className="text-danger">
                        Please Select Material Category
                      </span>
                    ) : (
                      ""
                    )}

                    {/*         <DropdownMultiselect
                      handleOnChange={(selected) => {
                        setSelected(selected);
                      }}
                      placeholder="-- Select --"
                      options={materiallisst}
                      name="material"
                    /> */}
                  </div>

                  {/*         <label htmlFor="">Select Bid Group</label>
                <select
                  defaultValue={"option"}
                  className="form-control"
                  id="sel1"
                >
                  <option>VACCINES</option>
                  <option>PHARMA</option>
                  <option>SURGICALS</option>
                  <option>IMPLANTS</option>
                </select> */}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={formik.handleReset} variant="danger light">
                CLOSE
              </Button>
              <button
                type="submit"
                onClick={formik.handleSubmit}
                className="btn btn-primary text-white"
              >
                SUBMIT
              </button>
            </Modal.Footer>
          </Modal>
        </form>
      </div>
    </div>
  );
}
export default AddVendor;
