import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
// import { Link } from "react-router-dom";
import swal from "sweetalert";
import {
  loadallVendors,
  updateVendor,
} from "../../../../store/actions/VendorActions";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import axios from "axios";
import Select from "react-select";
const EditVendor = (props) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState([]);
  const [editdata, setEditData] = useState();
  const [modalCentered, setModalCentered] = useState(false);
  const [getMaterial, setGetMaterial] = useState([]);
  const [selectedmaterial, setSelectedMaterial] = useState([]);
  const [matid, setMatID] = useState([]);
  const [vendorupdating, setVendorUpdating] = useState();

  const [vendoreditMatdata, setVendorEditMatData] = useState({});

  console.log(vendoreditMatdata);

  /*  useEffect(() => {
    const selectedLabelss = selectedOption?.map((val) => {
      return val.value;
    });
    setMatID(selectedLabelss);

    setEditVendorData({ ...props.editVendorData[0], material_category_id: matid });
  }, [selectedOption]); */

  /*   useEffect(() => {
    props.setEditVendorData(props.vendor);
  }, []); */

  const selectedLabels = selectedOption?.map((val) => {
    return val.value.toString();
  });

  console.log(selectedLabels);

  const editUserChange = (e) => {
    const selectedLabels = selectedOption?.map((val) => {
      return val.value.toString();
    });
    console.log(selectedLabels);
    props.setEditVendorData({
      ...props.editVendorData,
      [e.target.name]: e.target.value,
    });

    const vendorediteddata = {
      id: props.editVendorData?.id,
      company_name: props.editVendorData?.company_name,
      contact_person_1: props.editVendorData?.contact_person_1,
      contact_person_2: props.editVendorData?.contact_person_2,
      email_id: props.editVendorData?.email_id,
      contact_person_2_email: props.editVendorData?.contact_person_2_email,
      contact_person_1_phone: props.editVendorData?.contact_person_1_phone,

      contact_person_2_phone: props.editVendorData?.contact_person_2_phone,
      company_address: props.editVendorData?.company_address,
      material_category_id: props.editVendorData?.material_category_id,
    };
    console.log(vendorediteddata);
    setVendorEditMatData(vendorediteddata);

    setVendorUpdating(true);

    /* props.setEditVendorData({
      ...props.editVendorData,
      material_category_id: selectedLabels,
    }); */
  };

  useEffect(() => {
    props.setEditVendorData({
      ...props.editVendorData,

      material_category_id: selectedOption?.map((val) => val.value),
    });
  }, [selectedOption]);

  console.log(props.editVendorData);

  const EditUserHandle = () => {
    dispatch(updateVendor(props.editVendorData));

    swal({
      title: "Vendor Updated Successfully",
      icon: "success",
      timer: 1000,
    });

    loadallVendors();
    /* props.setVendorData(vendorsapidata); */
    props.closeEditModalButtonHandle();
  };

  /*       useEffect(() => {
      editUserChange();
    }, [selectedOption]); */

  console.log(props.editVendorData);

  console.log(selectedOption);
  const vendorvalues = { value: "1", label: "SURGICALS" };

  /*  const newvendor = props.materiallist
    .filter((val) => val.id === props.editVendorData.material_category_id)
    .map((item) => {
      return {
        item,
      };
    }); */
  /*  */

  /*   const valuesselectmaterialcategory =
    if (props.editVendorData.length > 0 ){
      props.editVendorData.material_category_id.map((val) => {
        const material = props.materiallist
          .filter((it) => it.id.toString() === val.toString())
          .map((item) => item.material_category);
        return {
          value: val.toString(),
          label: material[0],
        };
      });
      
    } */

  // props.editVendorData.material_category_id output: [1,2]
  // props.materiallist{id: 1, material_category: "SURGICALS"}, {id: 2, material_category: "VACCINE"}, {id: 3, material_category: "SURGICALS"}
  //require output {value : '2', label: 'Surgical'}
  console.log(props.showeditselectedmaterials);

  return (
    <div>
      <div>
        <form onSubmit={EditUserHandle}>
          <Modal
            className="fade"
            show={props.showeditmodal}
            onHide={props.closeEditModalButtonHandle}
          >
            <Modal.Header>
              <h4 className="text-black">Edit Vendor</h4>
              <Button
                onClick={props.closeEditModalButtonHandle}
                variant=""
                className="close"
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>

            <Modal.Body>
              <div className="col-sm-6 col-md-12">
                <div className=" flex-wrap justify-content-around">
                  <div className="form-group  ">
                    <label htmlFor="">
                      Company Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={props.editVendorData?.company_name}
                      onChange={editUserChange}
                      name="company_name"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Contact Person 1 <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={props.editVendorData?.contact_person_1}
                      onChange={editUserChange}
                      name="contact_person_1"
                      required
                    />
                  </div>
                </div>
                <div className="flex-wrap justify-content-around">
                  <div className="form-group  ">
                    <label htmlFor="">
                      Email ID 1 <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={editUserChange}
                      required
                      name="email_id"
                      value={props.editVendorData?.email_id}
                    />
                  </div>
                  <div className="form-group  ">
                    <label htmlFor="">
                      Phone No 1 <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={editUserChange}
                      required
                      name="contact_person_1_phone"
                      value={props.editVendorData?.contact_person_1_phone}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="">Contact Person 2 </label>
                    <input
                      type="text"
                      className="form-control"
                      value={props.editVendorData?.contact_person_2}
                      onChange={editUserChange}
                      name="contact_person_2"
                      required
                    />
                  </div>

                  <div className="form-group  ">
                    <label htmlFor="">Email ID 2</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={editUserChange}
                      required
                      name="contact_person_2_email"
                      value={props.editVendorData?.contact_person_2_email}
                    />
                  </div>

                  <div className="form-group  ">
                    <label htmlFor="">Phone No 2</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={editUserChange}
                      required
                      name="contact_person_2_phone"
                      value={props.editVendorData?.contact_person_2_phone}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="">
                      Address <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={editUserChange}
                      required
                      name="company_address"
                      value={props.editVendorData?.company_address}
                    />
                  </div>

                  <div className="form-group">
                    <div className="col-md-12 pl-2 h50">
                      <div className="form-group "></div>

                      <label htmlFor="">
                        Select Material Category{" "}
                        <span className="text-danger">*</span>
                      </label>

                      <Select
                        key={props.showeditselectedmaterials}
                        name=""
                        placeholder="Select Material Category"
                        options={props.materiallist.map((mat) => {
                          return {
                            value: mat.id,
                            label: mat.material_category,
                          };
                        })}
                        defaultValue={props.showeditselectedmaterials}
                        onChange={setSelectedOption}
                        isMulti
                      />

                      {/*         <DropdownMultiselect
                      handleOnChange={(selected) => {
                        setSelected(selected);
                      }}
                      placeholder="-- Select --"
                      options={materiallisst}
                      name="material"
                    /> */}
                    </div>

                    {/*         <label htmlFor="">Select Bid Group</label>
                <select
                  defaultValue={"option"}
                  className="form-control"
                  id="sel1"
                >
                  <option>VACCINES</option>
                  <option>PHARMA</option>
                  <option>SURGICALS</option>
                  <option>IMPLANTS</option>
                </select> */}
                  </div>

                  {/*        <div className="form-group ">
                    <label htmlFor="">Select Material Category</label>

                    <DropdownMultiselect
                      handleOnChange={(selected) => {
                        setSelectMaterial(selected);
                      }}
                      buttonClass="btn-light "
                      type="select"
                      onChange={editUserChange}
              
                      options={props.editVendorData[0].material_category_id}
                      name="select_material_categodry"
                    />
                  </div> */}
                </div>
                <div></div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={props.closeEditModalButtonHandle}
                variant="danger light"
              >
                CLOSE
              </Button>
              <button
                type="submit"
                onClick={EditUserHandle}
                className="btn btn-warning text-white"
              >
                UPDATE
              </button>
            </Modal.Footer>
          </Modal>
        </form>
      </div>
    </div>
  );
};

export default EditVendor;
