import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";

const EditRole = () => {
  const location = useLocation();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState(["Dashobard", "Users"]);
  const [list, setList] = useState([]);
  const [permissionAll, setPermissionAll] = useState([]);
  let errorsObj = { role_name: ""};
  const [errors, setErrors] = useState(errorsObj);
  /*   const [checked, setChecked] = useState([]); */
  const [rowData, setRowData] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const history = useHistory();

  // console.log("edit id", location.state.id)

  useEffect(() => {
    axios
      .get(
        `/setting/getSingleRole/${location?.state?.id}`
      )
      .then((response) => {
        console.log("singlerole", response.data);
        // console.log("singlerole_permission",response.data.permission);
        setRowData(response?.data?.data[0]);
        setIsCheck(response?.data?.data[0]?.permission);
      })
      .catch(function (response) {
        console.log(response.data);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`/setting/getAllPermission/`)
      .then((response) => {
        // console.log("permission",response.data.data);
        setPermissionAll(response?.data?.data);
        setMaterialList(response?.data?.data);
      })
      .catch(function (response) {
        console.log(response.data);
      });
  }, []);

  /* const handleChange = (event) => {
    const target = event.target;
    var value = target.value;
    if(target.checked){
    this.state.hobbies[value] = value;   
    }else{
    this.state.hobbies.splice(value, 1);
    }
  }; */

 

  const handleClick = (e) => {
    const { id, checked } = e.target;

    setIsCheck([...isCheck, id]);

    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  function handleRole(evt) {
    const value = evt.target.value;
    setRowData({
      ...rowData,
      [evt.target.name]: value,
    });
  }
  console.log(isCheck);

  const handleUpdate = (e) => {
    e.preventDefault();

    const roles = {
      role_name: rowData?.role_name,
      role_id: rowData?.id,
      role_permission: isCheck,
    };
    var errorPermission = document.getElementById("errorPermission")
    let error = false;
    const errorObj = { ...errorsObj };

    if (rowData.role_name === "") {
      errorObj.role_name = "Please enter role name";
      error = true;
    }
  
    setErrors(errorObj);
    if (error) {
      return;
    }

  else  if (isCheck?.length === 0) {

      errorPermission.innerHTML = "<p style='color: red;'>" +
        "Please select at least one permission</p>"

    }
   else{
    errorPermission.innerHTML = ""
    console.log(roles);

    axios
      .put("/setting/update_role", roles)
      .then((response) => {
          if (!response?.data?.hasErrpr) {
            document.getElementById('errorExist').innerHTML ="<p style='color: red;'>" +
            `${response?.data?.message}</p>`
        }
        else{
          // swal(response?.data?.message)
          history.push("/settings/roles")
          console.log(response?.data?.message)
        
        }
     
      })
      .catch(function (response) {
        console.log(response.data);
      });
        }
  }
  return (
    <>

      <div className="row">
        <PageTitle activeMenu="Edit Role" motherMenu="Settings" />
        <div className="col-md-12">
          <div className="card ">
            <div className="card-header">
              <h2>Edit Role </h2>
            </div>
            <div className="card-body mx-3 py-3">
              <div className="w-100 table-responsive text-black">
                <form onSubmit={handleUpdate}>
                  <div className="form-group">
                    <label htmlFor="">Role Name</label>
                    <input
                      type="text"
                      name="role_name"
                      value={rowData.role_name}
                      onChange={handleRole}
                      className="form-control"

                    />
                    {errors.role_name && (
                      <div className="text-danger fs-12">
                        {errors.role_name}
                      </div>
                    )}
                    <div id="errorExist" className="text-danger">

                    </div>
                  </div>
                  <div>
                    <label htmlFor="">Permission</label>
                    {permissionAll.map((val) => {
                      return (
                        <div className="custom-control custom-checkbox my-2 mx-auto">
                          <input
                            type="checkbox"
                            onChange={handleClick}
                            className="custom-control-input"
                            name={val.permission}
                            id={val.permission}
                            checked={isCheck.includes(val.permission)}
                          />
                          <label
                            className="custom-control-label mx-2"
                            htmlFor={val.permission}
                          />

                          <span>{val.permission}</span>
                        </div>
                      );
                    })}
                  </div>
                  <div id="errorPermission" className="text-center">

                  </div>
                  <div className="col-md-6 float-right">
                    <div className="d-flex justify-content-end">
                      <button type="submit" className="btn btn-primary mr-4">
                        SUBMIT
                      </button>
                      <button
                        className="btn btn-danger light mr-4"
                        onClick={(e) => history.push("/settings/roles")}
                      >
                        CANCEL
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditRole;
