import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import "./vendorquotecss.css";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { makeStyles } from "@material-ui/styles";
const filter = createFilterOptions();

export default function SelectSearch({
  brandList,
  selectedValue,
  setSelectedValue,
  brandSelected,
  id,
  handleFocusOut,
  setQuoteData,
  quoteData,
  index,
  status,
  highlight,
}) {
  // const useStyles = makeStyles(() => ({
  //   noBorder: {
  //     border: "none",
  //   },
  //   fontCol: {
  //     color: "#FFF",
  //   },
  // }));
  // const classes = useStyles();
  return (
    <Autocomplete
      style={{
        backgroundColor: status ? "#D6EFC7" : highlight ? "var(--warning)" : "",
      }}
      defaultValue={brandSelected}
      underlineShow={false}
      onBlur={(e) => handleFocusOut(id, index)}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          // setSelectedValue({
          //   brand_name: newValue,
          // });
          setSelectedValue((prev) => {
            let temp = [...prev];
            temp[index] = { brandName: newValue };
            return [...temp];
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          // setSelectedValue({
          //   brand_name: newValue.inputValue,
          // });
          setSelectedValue((prev) => {
            let temp = [...prev];
            temp[index] = { brandName: newValue };
            return [...temp];
          });
        } else {
          // setSelectedValue(newValue);
          setSelectedValue((prev) => {
            let temp = [...prev];
            temp[index] = { brandName: newValue };
            return [...temp];
          });
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.brand_name
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            brand_name: inputValue,
          });
        }
        return filtered;
      }}
      selectOnFocus
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={
        brandList === undefined
          ? [{ brand_name: "Not selected" }]
          : [{ brand_name: "Not selected" }, ...brandList]
      }
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.brand_name;
      }}
      renderOption={(props, option) => <li {...props}>{option.brand_name}</li>}
      sx={{ width: 300, color: highlight ? "#FFF" : "" }}
      freeSolo
      renderInput={(params) => (
        <TextField
          style={{
            backgroundColor: status
              ? "#D6EFC7"
              : highlight
              ? "var(--warning)"
              : "",
          }}
          disableUnderline={false}
          {...params}
          defaultValue={brandSelected}
          className="form-control"
          variant="standard"
          onChange={(e) => {
            // setSelectedValue({
            //   brand_name: e.target.value,
            // });
            setSelectedValue((prev) => {
              let temp = [...prev];
              temp[index] = { brandName: { brand_name: e.target.value } };
              return [...temp];
            });
          }}
        />
      )}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
// const top100Films = [
//   { brandeName: "Novartis India Ltd" },
//   { brandeName: "Alkem Laboratories Ltd" },
//   { brandeName: "Ciple Limited" },
// ];
