import {
  formatError,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";
import axios from "axios";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const USER_STORED_DATA = "[user store data] user store data";

export function signupAction(email, password, history) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        dispatch(confirmedSignupAction(response.data));
        history.push("/dashboard");
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function userStoredData(payload) {
  return {
    type: USER_STORED_DATA,
    payload,
  };
}

export function logout(history) {
  localStorage.clear();
  setTimeout(() => {
    history.push("/login");
  }, 100);

  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, history) {
  return (dispatch) => {
    const data = {
      email_id: email,
      password: password,
    };
    axios
      .post(`/user/login`, data)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        if (response.data.hasError === false) {
          dispatch(loginConfirmedAction(response?.data?.data[0]));
          console.log("response?.data.permission", response?.data.permission);
          localStorage.setItem(
            "vendorContact",
            JSON.stringify(response?.data?.data[0]?.contact_person_1_phone)
          );

          localStorage.setItem(
            "companyAddress",
            JSON.stringify(response?.data?.data[0]?.company_address)
          );
          localStorage.setItem(
            "companyName",
            JSON.stringify(response?.data?.data[0]?.company_name)
          );
          debugger;
          response?.data.permission.map((x) => {
            if (x.permission === "Dashboard") {
              localStorage.setItem("DashboardPermissons", true);
            }
            if (x.permission === "Material Category") {
              localStorage.setItem("MaterialPermissons", true);
             
            }
            if (x.permission === "Setting") {
              localStorage.setItem("SettingPermissons", true);
           
            }
            if (x.permission === "Settings") {
              localStorage.setItem("SettingPermissons", true);
             
            }

            if (x.permission === "Users") {
              localStorage.setItem("UsersPermissons", true);
             
            }
            if (x.permission === "Vendor Master") {
              localStorage.setItem("VendorMasterPermissons", true);
              
            }
            if (x.permission === "Drugs Master") {
              localStorage.setItem("DrugsMasterPermissons", true);
             
            }
            if (x.permission === "Generate Bid") {
              localStorage.setItem("GenerateBidPermissons", true);
              
            }
            return x.permission;
          });
          dispatch(userStoredData(response?.data));
          setTimeout(() => {
            localStorage.setItem(
              "userId",
              JSON.stringify(response?.data?.data[0].id)
            );
            localStorage.setItem(
              "mandatory_pwd_change",
              JSON.stringify(response?.data?.data[0].mandatory_pwd_change)
            );
            localStorage.setItem(
              "userEmail",
              JSON.stringify(response?.data?.data[0].email_id)
            );
            localStorage.setItem(
              "userName",
              JSON.stringify(response?.data?.data[0].first_name) +
                " " +
                JSON.stringify(response?.data?.data[0].last_name)
            );
            localStorage.setItem(
              "userRole",
              JSON.stringify(response?.data?.data[0].role_name)
            );
            localStorage.setItem(
              "userStatus",
              JSON.stringify(response?.data?.data[0].status)
            );
            localStorage.setItem(
              "userType",
              JSON.stringify(response?.data?.data[0].user_type)
            );
          });

          response?.data?.data[0].mandatory_pwd_change === "1"
            ? setTimeout(() => {
                history.push("/change-password");
                localStorage.setItem(
                  "userId",
                  JSON.stringify(response?.data?.data[0].id)
                );
                localStorage.setItem(
                  "mandatory_pwd_change",
                  JSON.stringify(response?.data?.data[0].mandatory_pwd_change)
                );
                localStorage.setItem(
                  "userEmail",
                  JSON.stringify(response?.data?.data[0].email_id)
                );
                localStorage.setItem(
                  "userName",
                  JSON.stringify(response?.data?.data[0].first_name) +
                    " " +
                    JSON.stringify(response?.data?.data[0].last_name)
                );
                localStorage.setItem(
                  "userRole",
                  JSON.stringify(response?.data?.data[0].role_name)
                );
                localStorage.setItem(
                  "userStatus",
                  JSON.stringify(response?.data?.data[0].status)
                );
                localStorage.setItem(
                  "vendorName",
                  JSON.stringify(response?.data?.data[0]?.contact_person_1)
                );
                window.location.reload();
              }, 20)
            : response?.data?.data[0].user_type === "vendor"
            ? setTimeout(() => {
                localStorage.setItem(
                  "userId",
                  JSON.stringify(response?.data?.data[0].id)
                );
                localStorage.setItem(
                  "mandatory_pwd_change",
                  JSON.stringify(response?.data?.data[0].mandatory_pwd_change)
                );
                localStorage.setItem(
                  "userEmail",
                  JSON.stringify(response?.data?.data[0].email_id)
                );
                localStorage.setItem(
                  "userType",
                  JSON.stringify(response?.data?.data[0].user_type)
                );
                localStorage.setItem(
                  "userName",
                  JSON.stringify(response?.data?.data[0].first_name) +
                    " " +
                    JSON.stringify(response?.data?.data[0].last_name)
                );
                localStorage.setItem(
                  "userRole",
                  JSON.stringify(response?.data?.data[0].role_name)
                );
                localStorage.setItem(
                  "userStatus",
                  JSON.stringify(response?.data?.data[0].status)
                );
                localStorage.setItem(
                  "vendorName",
                  JSON.stringify(response?.data?.data[0].contact_person_1)
                );
                localStorage.setItem(
                  "vendorContact",
                  JSON.stringify(response?.data?.data[0].contact_person_1_phone)
                );

                localStorage.setItem(
                  "companyAddress",
                  JSON.stringify(response?.data?.data[0].company_address)
                );
                localStorage.setItem(
                  "companyName",
                  JSON.stringify(response?.data?.data[0].company_name)
                );
                setTimeout(() => {
                  history.push("/vendor-dashboard");
                });
                setTimeout(() => {
                  window.location.reload();
                }, 5);
              })
            : setTimeout(() => {
                localStorage.setItem(
                  "userId",
                  JSON.stringify(response?.data?.data[0].id)
                );
                localStorage.setItem(
                  "mandatory_pwd_change",
                  JSON.stringify(response?.data?.data[0].mandatory_pwd_change)
                );
                localStorage.setItem(
                  "userEmail",
                  JSON.stringify(response?.data?.data[0].email_id)
                );
                localStorage.setItem(
                  "userType",
                  JSON.stringify(response?.data?.data[0].user_type)
                );
                localStorage.setItem(
                  "userName",
                  JSON.stringify(response?.data?.data[0].first_name) +
                    " " +
                    JSON.stringify(response?.data?.data[0].last_name)
                );
                localStorage.setItem(
                  "userRole",
                  JSON.stringify(response?.data?.data[0].role_name)
                );
                localStorage.setItem(
                  "userStatus",
                  JSON.stringify(response?.data?.data[0].status)
                );
                dispatch(loadingToggleAction(true));
                dispatch(userStoredData(response?.data));
                console.log("permissionsDtaksodso", response?.data.permission);
                debugger;
                var test = response?.data.permission?.find(
                  (x) => x.permission === "Dashboard"
                );
                var userTest = response?.data.permission?.find(
                  (x) => x.permission === "Users"
                );
                var materialTest = response?.data.permission?.find(
                  (x) => x.permission === "Material Category"
                );
                var vendorTest = response?.data.permission?.find(
                  (x) => x.permission === "Vendor Master"
                );
                var drugTest = response?.data.permission?.find(
                  (x) => x.permission === "Material Category"
                );
                var settingsTest = response?.data.permission?.find(
                  (x) => x.permission === "Settings"
                );
                var roleTest = response?.data.permission?.find(
                  (x) => x.permission === "Generate Bid"
                );
                if (test?.permission === "Dashboard" && test !== undefined) {
                  history.push("/dashboard");
                } else if (
                  userTest?.permission === "Users" &&
                  userTest !== undefined
                ) {
                  history.push("/users");
                } else if (
                  materialTest?.permission === "Material Category" &&
                  materialTest !== undefined
                ) {
                  history.push("/material-category");
                } else if (
                  vendorTest?.permission === "Vendor Master" &&
                  vendorTest !== undefined
                ) {
                  history.push("/vendormaster");
                } else if (
                  drugTest?.permission === "Drugs Master" &&
                  drugTest !== undefined
                ) {
                  history.push("/drug-master");
                } else if (
                  settingsTest?.permission === "Settings" &&
                  settingsTest !== undefined
                ) {
                  history.push("/rolemanagment");
                } else if (
                  roleTest?.permission === "Generate Bid" &&
                  roleTest !== undefined
                ) {
                  history.push("/generate-bid");
                }
                window.location.reload();
              });
        } else {
          dispatch(loginFailedAction(response.data.message));
        }
      })
      .catch((error) => {
        console.log(error);
        // const errorMessage = formatError(error.response.data);
        // dispatch(loginFailedAction(error));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
