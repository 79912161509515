import React, { useEffect } from "react";
import "./viewquote.css";
import MultiSelectSearch from "./components/MultiSelectSearch";
import { Button, Dropdown } from "react-bootstrap";
import { useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import TextField from "@material-ui/core/TextField";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function ApprovedQuotes() {
  const orderdataBlog = [];
  const history = useHistory();
  const [colors, setColor] = useState([
    "bg-light",
    "bg-dark",
    "bg-secondary",
    "bg-success",
  ]);

  /*   function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  } */
  // const randomColor = Math.floor(Math.random() * 16777215).toString(16);

  const [newViewData, setNewViewData] = useState(orderdataBlog);

  useEffect(() => {
    getVendorData().then((res) => {
      let temp = res?.data;
      temp?.map((val) => {
        val.value = val.company_name;
        val.label = val.company_name;
      });
      setOptions(temp);
      handleVendorChangeInitial(temp);
    });
  }, []);

  async function getVendorData() {
    const res = await axios.get(
      `/vendor_quote/getAllVendorByBid/${location?.state}`
    );
    return res?.data;
  }

  const location = useLocation();
  const [options, setOptions] = useState([]);

  //temporary purpose i used setNewViewData useState function in below for removing warning
  // setNewViewData("");
  const mySearchFunction = () => {
    var input, filter, table, tr, td, i, j, txtValue, alltags;
    let isFound;
    let count = 0;
    input = document.getElementById("searchInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("viewQuote_table");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      // td = tr[i].getElementsByTagName("td")[0];
      alltags = tr[i].getElementsByTagName("td");
      isFound = false;
      for (j = 0; j < alltags.length; j++) {
        td = alltags[j];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
            j = alltags.length;
            isFound = true;
            count++;
          }
        }
      }
      if (!isFound && tr[i].className !== "header") {
        tr[i].style.display = "none";
      }
    }

    // var error = document.getElementById("error");
    // if (count === 0) {
    //   error.innerHTML = "<p style='color: red;'>" + "Data Not Found</p>";
    // } else {
    //   error.innerHTML = "";
    // }
  };

  const [materiallist, setMaterial] = useState([]);
  function getAllMaterialCategory() {
    axios.get("/material_category/getAllMaterialCategory").then((response) => {
      // console.log(response.data.data, "material list");
      setMaterial(response.data.data);
    });
  }

  useEffect(() => {
    getAllMaterialCategory();
  }, []);
  //export
  function getBrands(ViewDATA) {
    const temp = ViewDATA.map((element, i) => {
      let res = state.filter((val) => {
        if (val.description === element.brand_name) {
          console.log(val)
          return val

        }

      })

      if (res[0] === undefined) {
        return element
      }
      else {
        console.log(res[0]);
        console.log(element)
        element.current_yr_fy = res?.[0]?.twelve_month_purchase;
        element.drug_code = res?.[0]?.code;
        element.drug_mrp = res?.[0]?.mrp;
      }
      return element;
    });
    return temp
  }
  const [state, setState] = useState([])
  const getAllDrugs = async () => {
    await axios.get("/drug/getAllDrugMaster")
      .then((response) => {
        console.log(response?.data?.data);
        setState(response?.data?.data)
      });
  };

  useEffect(() => {
    getAllDrugs();
  }, []);

  async function fnExport(fileExtn, filename) {

    let colorCode = null;
    let flag = true;
    let colorClass = true;
    let categoryLabel = 1;
    let Priority = "P";
    let marginPrice = 0;

    newViewData?.map((newdata, index, arr) => {
      newdata.cost_price = newdata?.discounted_price === 0 ? newdata?.cost_price : newdata?.discounted_price;
      let cpGst =
        Number(newdata?.cost_price) +
        (Number(newdata?.cost_price) * Number(newdata?.gst)) / 100;
      let margin = Number(newdata?.mrp) - Number(cpGst);
      let marginPercent = (Number(cpGst) / Number(newdata?.mrp)) * 100;
      marginPercent = 100 - marginPercent;
      marginPercent = Math.round(marginPercent * 100) / 100;
      margin = Math.round(margin * 100) / 100;
      cpGst = Math.round(cpGst * 100) / 100;
      if (arr[index - 1]?.generic !== newdata?.generic) flag = false;
      else flag = true;
      colorClass = flag ? colorClass : !colorClass;
      flag ? (categoryLabel = categoryLabel + 1) : (categoryLabel = 1);

    });

    newViewData.map((newdata, index, arr) => {
      newdata.cost_price = newdata?.discounted_price === 0 ? newdata?.cost_price : newdata?.discounted_price;
    });

    let ViewDATA = newViewData?.filter((val) => {
      if (val.status === "1" && val.approval_status === 1) 
      {
        return val
      }
    })

    console.log(ViewDATA);
    ViewDATA = await getBrands(ViewDATA)

    console.log("New....", ViewDATA);

    let Category = "L" + categoryLabel;

    ViewDATA?.map((val, i) => {
      val.quote_category = Category;

    });

    ViewDATA?.map((newdata) => {
      let cpGst =
        Number(newdata?.cost_price) +
        (Number(newdata?.cost_price) * Number(newdata?.gst)) / 100;
      let margin = Number(newdata?.mrp) - Number(cpGst);
      margin = Math.round(margin * 100) / 100;
      let marginPercent = (Number(cpGst) / Number(newdata?.mrp)) * 100;
      marginPercent = 100 - marginPercent;
      marginPercent = Math.round(marginPercent * 100) / 100;
      newdata.marginValue = margin;
      newdata.margin_parcentage = marginPercent;
    })

    // let temp = ViewDATA.map((val) => {
    //   let sort =state.map((data) => {
    //     if(data.description===val.brand_name)
    //     {
    //       return data
    //     }
    //   })
    //   return sort
    // })

    // console.log("brand_name",temp)

    let result = ViewDATA?.map((val, i) => {
      let exportDATA =
      {
        "Material_Code": val.drug_code,
        "Description": val.brand_name,
        "Qty": val.current_yr_fy,
        "Last year Price": "",
        "Last year MRP": val.drug_mrp,
        "Cost Price": val.cost_price,
        "MRP": val.mrp,
        "Margin Value": val.marginValue,
        "GST": val.gst,
        "Preference": val.quote_category,
        "Bidding number": val.bid_id,
        "Company": val.company_name,
        "Last year Margin %": "",
        "Current year Margin %": val.margin_parcentage
      }

      return exportDATA;
    })
    console.log(result);

    let header = [
      "MATERIAL CODE",
      "DESCREPTION",
      "QTY",
      "LAST YEAR PRICE",
      "LAST YEAR MRP",
      "BIDDING COST PRICE",
      "BIDDING MRP",
      "BIDDING MARGIN VALUE",
      "GST",
      "PREFERENCE",
      "BIDDING NUMBER",
      "COMPANY",
      "LAST YEAR MARGIN %",
      "CURRENT YEAR MARGIN %",

    ];
    const ws = XLSX.utils.book_new();

    XLSX.utils.sheet_add_aoa(ws, [header], {
      origin: "A1",
    });
    XLSX.utils.sheet_add_json(ws, result, {
      origin: "A2",
      skipHeader: true,
    });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"], range: 5 };

    ws["A1"].style = {
      font: {
        name: "arial",
        sz: 24,
        bold: true,
        color: "#F2F2F2",
      },
    };

    var wscols = [
      { width: 20 },
      { width: 30 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 18 },
      { width: 20 },
      { width: 25 },
      { width: 20 },
      { width: 20 },
    ];
    ws["!cols"] = wscols;

    const excelBuffer = XLSX.write(wb, {
      bookType: fileExtn,
      type: "array",
      cellStyles: true,
    });
    const finalData = new Blob([excelBuffer], { type: "xlsx" });
    FileSaver.saveAs(finalData, filename);
  }

  async function handleVendorChange(e) {
    setVendorSelected(e.target.value);
    if (e.target.value === "all") {
      return handleVendorChangeInitial(options);
    }
    const find = options.find(
      (val) => Number(val.vendor_user_id) === Number(e.target.value)
    );
    console.log("view", find);
    const res = await axios.get(
      `/bid/getQuote/${find?.gen_bid_id}/${find?.vendor_user_id}`
    );
    console.log("detail", res);
    const temp = [...res?.data?.data];
    // const result = temp.filter((test) => {
    //   const found = personName?.find((item) => item === test.generic);
    //   if (found) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });
    setPersonName([]);
    setNewViewData([...temp]);
    getStatusCount(temp);
    return res?.data;
  }

  async function handleVendorChangeInitial(temp) {
    const find = temp?.[0];
    const res = await axios.get(`/bid/getQuote/${find?.gen_bid_id}`);
    res?.data?.data.sort(compare);
    colorCode = res?.data?.data?.[0]?.generic;
    setNewViewData([...res?.data?.data]);
    getStatusCount(res?.data?.data);
    const unique = [...new Set(res?.data?.data?.map((item) => item.generic))];
    setNames(unique);
    return res?.data;
  }

  function compare(a, b) {
    if (a?.generic === b?.generic) {
      let cpGstA =
        Number(a?.cost_price) + (Number(a?.cost_price) * Number(a?.gst)) / 100;
      let cpGstB =
        Number(b?.cost_price) + (Number(b?.cost_price) * Number(b?.gst)) / 100;
      let marginPercentA = (Number(cpGstA) / Number(a?.mrp)) * 100;
      let marginPercentB = (Number(cpGstB) / Number(b?.mrp)) * 100;
      if (a?.status === "0") {
        return 0;
      }
      if (b?.status === "0") {
        return -1;
      }
      return marginPercentA - marginPercentB;
    }
    if (a?.generic < b?.generic) {
      return -1;
    }
    if (a?.last_nom > b?.last_nom) {
      return 1;
    }
    return 0;
  }

  let colorCode = null;
  let flag = true;
  let colorClass = true;
  let categoryLabel = 1;

  const [names, setNames] = useState([]);

  const classes = useStyles();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  useEffect(() => {
    if (personName.length === 0) {
      if (vendorSelected === "all") {
        handleVendorChangeInitial(options);
        return;
      } else {
        vendorChange(vendorSelected);
        return;
      }
    }
    if (vendorSelected === "all") {
      handleVendorChangeInitial(options).then((result) => {
        const temp = [...result?.data];
        const res = temp.filter((test) => {
          const found = personName?.find((item) => item === test.generic);
          if (found) {
            return true;
          } else {
            return false;
          }
        });
        setNewViewData(res);
        getStatusCount(res);
      });
    } else {
      vendorChange(vendorSelected).then((result) => {
        const temp = [...result?.data];
        const res = temp.filter((test) => {
          const found = personName?.find((item) => item === test.generic);
          if (found) {
            return true;
          } else {
            return false;
          }
        });
        setNewViewData(res);
        getStatusCount(res);
      });
    }
  }, [personName]);

  async function vendorChange(e) {
    setVendorSelected(e);
    if (e === "all") {
      return handleVendorChangeInitial(options);
    }
    const find = options.find(
      (val) => Number(val.vendor_user_id) === Number(e)
    );
    const res = await axios.get(
      `/bid/getQuote/${find?.gen_bid_id}/${find?.vendor_user_id}`
    );
    setNewViewData([...res?.data?.data]);
    getStatusCount(res?.data?.data);
    return res?.data;
  }

  const [vendorSelected, setVendorSelected] = useState("all");
  const [statusCount, setStatusCount] = useState(null);

  function getStatusCount(res) {
    let count = 0;
    res.forEach((element) => {
      if (element?.status === "1") count = count + 1;
    });
    setStatusCount(count);
    return count;
  }

  const [modalShow, setModalShow] = React.useState(false);
  const [remarkModalShow, setRemarkModalShow] = React.useState(false);
  // const [genericCounts, setGenericCounts] = useState([]);
  // function calculateRangeGeneric() {
  //   const counts = {};
  //   newViewData.forEach(function (x) {
  //     console.log(counts[x]);
  //     counts[x.generic] = (counts[x.generic] || 0) + 1;
  //   });
  //   console.log(counts);
  //   return counts;
  // }
  // useEffect(() => {
  //   setGenericCounts(calculateRangeGeneric());
  // }, [newViewData]);
  const [remarkObjectIndex, setRemarkObjectIndex] = useState(null);

  const [highlight, setHighlight] = useState(null);
  return (
    <>
      <PageTitle activeMenu="Approved Quotes" motherMenu="View" />

      <div className="col-md-12">
        {/*         <div className="col d-flex flex-row  justify-content-end align-items-baseline mb-2 ">
          <button className="btn btn-white shadow  mx-2">
            <i
              class="fa fa-cloud-upload"
              style={{ fontSize: "30px", color: "var(--primary)" }}
            ></i>
          </button>

          <button className="btn shadow btn-white mx-2">
            <i
              id="btn-export"
              onClick={(e) => fnExport("xlsx", "ViewQuoteSheet.xlsx", e)}
              class="fa fa-cloud-download   "
              style={{
                fontSize: "30px",
                color: "var(--primary)",
                cursor: "pointer",
              }}
              aria-hidden="true"
            ></i>
          </button>
        </div> */}
        <div className="card cardheight px-0">
          <div className="">
            <div className="col-md-12">
              <div className="card-header">
                <div className="col d-flex justify-content-around align-items-center">
                  <div className="col-md-4 ">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control text-black"
                        name=""
                        placeholder="Search"
                        id="searchInput"
                        onKeyUp={mySearchFunction}
                      />
                    </div>
                  </div>
                  <div
                  /*className="col-md-4 justify-content-between my-auto"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }} */
                  >
                    {/* <Select
                      name="filters"
                      placeholder="Select Material Category"
                      options={uniqueGenerics.map((mat) => {
                        return {
                          value: mat,
                          label: mat,
                        };
                      })}
                      defaultValue={selectedGeneric}
                      onChange={setSelectedGeneric}
                      isMulti
                    /> */}
                    <div className="col-md-3 mr-3">
                      <FormControl
                        className={classes.formControl}
                        style={{
                          minWidth: "280px",
                        }}
                      >
                        <InputLabel id="demo-mutiple-chip-label">
                          Generic
                        </InputLabel>
                        <Select
                          labelId="demo-mutiple-chip-label"
                          id="demo-mutiple-chip"
                          multiple
                          value={personName}
                          onChange={handleChange}
                          input={<Input id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={value}
                                  className={classes.chip}
                                />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {names.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, personName, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div
                    className="d-flex col-md-2 mx-3"
                  /*       style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }} */
                  >
                    <div className="">
                      <div
                        className=""
                      /*         style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }} */
                      >
                        {/* <p style={{ width: "auto", marginBottom: "0" }}>
                          Select Vendor
                        </p>
                        <select
                          value={vendorSelected}
                          onChange={(e) => {
                            handleVendorChange(e);
                          }}
                          style={{
                            borderLeft: "0",
                            borderTop: "0",
                            borderRight: "0",
                            outline: "0",
                            backgroundColor: "#fff",
                            minWidth: "120px",
                            maxWidth: "300px",
                            height: "30px",
                          }}
                        >
                          <option selected value={"all"}>
                            All
                          </option>
                          {options.map((val) => (
                            <option value={val.vendor_user_id}>
                              {val.company_name}
                            </option>
                          ))}
                        </select> */}
                        <FormControl
                          className={classes.formControl}
                          style={{
                            minWidth: "150px",
                          }}
                        >
                          <InputLabel id="demo-simple-select-label">
                            Vendor
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={vendorSelected}
                            onChange={handleVendorChange}
                            defaultValue={vendorSelected}
                          >
                            <MenuItem value={"all"}>All</MenuItem>
                            {options.map((val) => (
                              <MenuItem value={val.vendor_user_id}>
                                {val.company_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1 mx-4">
                    <i
                      id="btn-export"
                      onClick={(e) =>
                        fnExport("xlsx", "ApprovedQuoteSheet.xlsx", e)
                      }
                      class="fa fa-cloud-download   "
                      style={{
                        fontSize: "30px",
                        color: "var(--primary)",
                        cursor: "pointer",
                      }}
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body p-1">
            {statusCount === 0 ? (
              <p style={{ padding: "20px" }}>
                Vendors have not submitted the quote yet.
              </p>
            ) : (
              <div className="w-100 table-responsive">
                <div id="example_wrapper" className="dataTables_wrapper">
                  <table
                    className="table shadow-hover card-table border-no tbl-btn text-left text-black"
                    id="viewQuote_table"
                  >
                    <div style={{ height: "60vh", overflow: "auto" }}>
                      <thead className="text-wrap">
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          GENERIC
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          DESCRIPTION
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          VENDOR COMPANY
                          {/* <Select options={options} />  */}
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          MATERIAL CATEGORY{" "}
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          CP+GST
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          MRP
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          MARGIN
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          %
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          CATEGORY
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          PRIORITY
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          REMARK
                        </th>
                      </thead>
                      <tbody className="text-wrap">
                        {newViewData?.map((newdata, index, arr) => {
                          if (newdata?.approval_status !== 1) {
                            return <></>;
                          }
                          let price = newdata?.discounted_price === 0 ? newdata?.cost_price : newdata?.discounted_price;
                          let cpGst =
                            Number(price) +
                            (Number(price) *
                              Number(newdata?.gst)) /
                            100;
                          let margin = Number(newdata?.mrp) - Number(cpGst);
                          let marginPercent =
                            (Number(cpGst) / Number(newdata?.mrp)) * 100;
                          marginPercent = 100 - marginPercent;
                          marginPercent = Math.round(marginPercent * 100) / 100;
                          margin = Math.round(margin * 100) / 100;
                          cpGst = Math.round(cpGst * 100) / 100;
                          if (arr[index - 1]?.generic !== newdata?.generic)
                            flag = false;
                          else flag = true;
                          colorClass = flag ? colorClass : !colorClass;
                          flag
                            ? (categoryLabel = categoryLabel + 1)
                            : (categoryLabel = 1);
                          return (
                            <tr
                              onClick={(e) => setHighlight(index)}
                              className={
                                colorClass
                                  ? index === highlight
                                    ? "bg-primary text-white"
                                    : "text-light text-muted"
                                  : index === highlight
                                    ? "bg-primary text-white"
                                    : "bg-light text-dark"
                              }
                            >
                              <td style={{ maxWidth: "200px" }}>
                                {newdata?.generic}
                              </td>
                              <td className="">{newdata?.brand_name}</td>
                              <td>{newdata?.company_name}</td>
                              <td className="text-center">
                                {newdata?.material_category}
                              </td>
                              <td>{cpGst}</td>
                              <td>{newdata?.mrp}</td>
                              <td>{margin}</td>
                              <td>{marginPercent}</td>
                              <td className="text-center">
                                {"L" + categoryLabel}
                              </td>
                              {/* <td className="text-center">
                                {newdata?.quote_category}
                              </td> */}
                              <td>
                                {newdata?.quote_priority === null ? "" : "P"}
                                {newdata?.quote_priority ?? "No priority set."}
                              </td>
                              <td>
                                {newdata?.quote_priority === -1
                                  ? "No remarks added."
                                  : newdata?.quote_remark ??
                                  "No remarks added."}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </div>
                  </table>
                  <div>
                    <button
                      onClick={() =>
                        history.push({
                          pathname: "/viewQuotes",
                          state: location?.state,
                        })
                      }
                      style={{ marginLeft: "20px", marginBottom: "20px" }}
                      className="btn btn-warning mx-2"
                    >
                      BACK
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ApprovedQuotes;
