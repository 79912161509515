import axios from "axios";
import {
  CREATE_VENDOR_ACTION,
  DELETE_VENDOR_ACTION,
  GET_ALLVENDORS_ACTION,
  UPDATE_VENDOR_ACTION,
} from "./VendorTypes";

const getAVRS = (vendors) => ({
  type: GET_ALLVENDORS_ACTION,
  payload: vendors,
});
/* sa */
export const loadallVendors = () => {
  return function (dispatch) {
    axios
      .get("/vendor/getAllVendor")
      .then((res) => {
        dispatch(getAVRS(res.data));
      })
      .catch((error) => console.log(error));
  };
};

const vendorDeleted = () => ({
  type: DELETE_VENDOR_ACTION,
});
export const deleteVendor = (id) => {
  return function (dispatch) {
    axios.delete(`/vendor/delete_vendor/${id}`).then((response) => {
      dispatch(vendorDeleted());

      dispatch(loadallVendors());
    });
  };
};

const vendorAdded = () => ({
  type: CREATE_VENDOR_ACTION,
});

export const addVendor = (vendor) => {
  return function (dispatch) {
    axios.post(`/vendor/create_vendor`, vendor).then((response) => {
      dispatch(vendorAdded());
      dispatch(loadallVendors());
    });
  };
};

const vendorUpdated = () => ({
  type: UPDATE_VENDOR_ACTION,
});

export const updateVendor = (venup) => {
  return function (dispatch) {
    axios.put(`/vendor/update_vendor`, venup).then((response) => {
      console.log(response);
      dispatch(vendorUpdated());
      dispatch(loadallVendors());
    });
  };
};
