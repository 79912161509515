import { useState, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
// import { useLocation } from "react-router-dom";
// import { Link } from "react-router-dom";
import swal from "sweetalert";

export default function Revaluation() {
  return (
    <div>
      <PageTitle activeMenu="Revaluation" motherMenu="Revaluation" />
      <Body />
    </div>
  );
}

function Body() {
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [highlight, setHighlight] = useState(null);

  useEffect(() => {
    axios
      .get(`/revaluation/getRevolution/${localStorage?.getItem("userId")}`)
      .then((res) => {
        console.log("VendorBidData", res?.data?.data);
        //ensuring its an array
        Array.isArray(res?.data?.data) ? setData(res?.data?.data) : setData([]);
      });
  }, []);
  let colorCode = null;
  let flag = true;
  let colorClass = true;
  let categoryLabel = 1;

  const handlePreview = () => {
    console.log("send preview", data);
    data?.map((val, i) => {
      if (val?.discounted_price === "") {
        swal({
          title: "Please enter your Discounted price ",
          // text: "You clicked the button!",
          icon: "error",
          button: "Okay!",
        });
      } else if (val?.discounted_price === 0) {
        swal({
          title: "Discounted price should not be a Empty Or Zero ",
          // text: "You clicked the button!",
          icon: "warning",
          button: "Okay!",
        });
      } else {
        history.push({
          pathname: "/preview-revalution",
          state: data,
        });
      }
    });
  };

  return (
    <div className="card" style={{ padding: "40px", fontSize: "0.82rem" }}>
      <div className="w-100 table-responsive">
        <div className="form-group">
          <table className="table shadow-hover text-nowrap text-center card-table border-no tbl-btn text-left text-black">
            <div style={{ height: "50vh", overflow: "auto" }}>
              <thead className="text-wrap">
                <tr>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    GENERIC
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    DESCRIPTION
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    VENDOR COMPANY
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    MATERIAL CATEGORY
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    CP+GST
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    MRP
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    MARGIN
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    %
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    CATEGORY
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    APPROVAL STATUS
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    EXPECTED PRICE
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    Discounted Price
                  </th>
                </tr>
              </thead>
              <tbody className="text-wrap">
                {data?.length === 0 ? (
                  <tr>
                    <td className="text-danger" colSpan={10}>
                      No records are there to revaluate
                    </td>
                  </tr>
                ) : (
                  data?.map((newdata, index, arr) => {
                    let cpGst =
                      Number(newdata?.cost_price) +
                      (Number(newdata?.cost_price) * Number(newdata?.gst)) /
                        100;
                    let margin = Number(newdata?.mrp) - Number(cpGst);
                    let marginPercent =
                      (Number(cpGst) / Number(newdata?.mrp)) * 100;
                    marginPercent = 100 - marginPercent;
                    marginPercent = Math.round(marginPercent * 100) / 100;
                    margin = Math.round(margin * 100) / 100;
                    cpGst = Math.round(cpGst * 100) / 100;
                    if (arr[index - 1]?.generic !== newdata?.generic)
                      flag = false;
                    else flag = true;
                    colorClass = flag ? colorClass : !colorClass;
                    flag
                      ? (categoryLabel = categoryLabel + 1)
                      : (categoryLabel = 1);
                    return (
                      <tr
                        onClick={(e) => setHighlight(index)}
                        className={
                          colorClass
                            ? index === highlight
                              ? "bg-primary text-white"
                              : "text-light text-muted"
                            : index === highlight
                            ? "bg-primary text-white"
                            : "bg-light text-dark"
                        }
                      >
                        <>
                          <td style={{ maxWidth: "200px" }}>
                            {newdata?.generic}
                          </td>
                          <td className="">{newdata?.brand_name}</td>
                          <td className="text-center">
                            {newdata?.company_name}
                          </td>
                          <td className="text-center">
                            {newdata?.material_category}
                          </td>
                          <td className="text-center">{cpGst}</td>
                          <td className="text-center">{newdata?.mrp}</td>
                          <td className="text-center">{margin}</td>
                          <td className="text-center">{marginPercent}</td>
                          <td className="text-center">{"L" + categoryLabel}</td>
                          <td>
                            {(newdata?.approval_status === 1 && "Approved") ||
                              (newdata?.approval_status === -1 && "Rejected") ||
                              (newdata?.approval_status === 0 &&
                                "Not selected")}
                          </td>
                          <td className="text-center">
                            {newdata?.expected_price}
                          </td>
                        </>
                        <td>
                          <input
                            id={newdata?.id}
                            name="discounted_price"
                            type="number"
                            value={newdata?.discounted_price}
                            onChange={(e) => {
                              setData((prev) => {
                                let temp = [...prev];
                                temp[index].discounted_price = e.target.value;
                                return temp;
                              });
                            }}
                            onBlur={async (e) => {
                              if (cpGst < e.target.value) {
                                swal(
                                  "Value was not updated. Please ensure that discounted price cannot be more than cost price + GST.",
                                  { icon: "error", button: "Okay" }
                                ).then((okay) => {
                                  history.go(0);
                                });
                                return;
                              }
                              const res = await axios.put(
                                "/revaluation/updateRevaluationVendor",
                                {
                                  vendor_id: newdata?.id,
                                  discounted_price: e.target.value,
                                }
                              );
                            }}
                            className="form-control"
                            // onKeyPress={(event) => {
                            //   if (!/^[0-9\b]+$/.test(event.key)) {
                            //     event.preventDefault();
                            //   }
                            // }}
                          />
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </div>
          </table>
        </div>
      </div>
      <div className="col-md-12 d-flex justify-content-end mt-4">
        {data.length !== 0 ? (
          <button
            className="btn btn-primary text-white mx-2"
            onClick={(e) => handlePreview(e)}
          >
            PREVIEW
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
