import axios from "axios";
import {
    logout,
  } from "./AuthActions";


  export const FORGOT_FAILED_ACTION = '[PasswordFailedAction] faild passwordChange';
 export const FORGOT_SUCESS_ACTION = '[PasswordSucessAction] passwordChange';


export function PasswordFailedAction(data) {
  return {
      type: FORGOT_FAILED_ACTION,
      payload: data,
  };
}

export function PasswordSucessAction(data) {
  
  return {
      type: FORGOT_SUCESS_ACTION,
      payload: data,
  };
}

export function forgotPasswordAction(email,history) {
    return (dispatch) => {
        const data = {
            email_id: email,
          }
        axios.post(`/user/passwordResetEmail`, data)
        .then((response) => {
            console.log("response--Data",response );
            if(response.status === 200 && response.data.hasError === false){
              dispatch(PasswordSucessAction(response.data));
            }else if(response.status === 200 && response.data.hasError === true){
              
              dispatch(PasswordFailedAction(response.data));
            }
            
        })
       
            .catch((error) => {
              // dispatch(PasswordFailedAction)(error);
				console.log(error);
            });
    };
}

