import React from "react";
import { components } from "react-select";

import { default as ReactSelect } from "react-select";
function MultiSelectSearch(props) {
  // const [isSelected, setIsSelected] = useState();

  const colourOptions = [
    { value: "Novartis India Ltd", label: "Novartis India Ltd" },
    { value: "Alkem Laboratories Ltd.", label: "Alkem Laboratories Ltd." },
    { value: "CIPLA LIMITED", label: "CIPLA LIMITED" },
    { value: "Veritaz Healthcare Ltd", label: "Veritaz Healthcare Ltd" },
    { value: "ZYDUS CADILA", label: "ZYDUS CADILA" },
    { value: "INTAS PHARMACEUTICALS LTD", label: "INTAS PHARMACEUTICALS LTD" },
    {
      value: "Glenmark Pharmaceuticals Ltd",
      label: "Glenmark Pharmaceuticals Ltd",
    },
    {
      value: "Mylan Pharmaceuticals Pvt Ltd",
      label: "Mylan Pharmaceuticals Pvt Ltd",
    },
    {
      value: "Glenmark Pharmaceuticals Ltd",
      label: "Glenmark Pharmaceuticals Ltd",
    },
  ];

  const options = () => {
    <components.options {...props}>
      <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
      <label>{props.label}</label>
    </components.options>;
  };

  return (
    <div className="card mx-2 my-1">
      <div className="form-group">
        <label htmlFor="">Search Company</label>
        <ReactSelect
          /*      menuIsOpen={true} */
          onMenuClose={false}
          options={colourOptions}
          isMulti
          closeMenuOnSelect={true}
          hideSelectedOptions={false}
          components={{
            options,
          }}
          allowSelectAll={true}
        />

        <div className="col d-flex justify-content-end my-3">
          <button className="btn btn-sm btn-primary">Close</button>
        </div>
      </div>
    </div>
  );
}

export default MultiSelectSearch;
