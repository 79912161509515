import { Checkbox } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
// import { Dropdown, InputGroup, FormControl } from "react-bootstrap";

// import { Link } from "react-router-dom";
// import data from "./tableData.js";
// import "./Css/tbl.css"

import DrugList from "./jsondata/Data";
import { set } from "date-fns/esm";
const StepTwo = (props) => {
  const [list, setList] = useState([]);

  const [checkitem, setCheckItem] = useState([]);
  const [ischecktrueall, setIsCheckTrueAll] = useState([]);
  /*   const chackbox = document.querySelectorAll(".sorting_1 input");
  const motherChackBox = document.querySelector(".sorting_asc input");
  // console.log(document.querySelectorAll(".sorting_1 input")[0].checked);
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  }; */

  const mySearchFunction = () => {
    var input, filter, table, tr, td, i, j, txtValue, alltags;
    let isFound;
    let count = 0;
    input = document.getElementById("searchInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("drug_table");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      // td = tr[i].getElementsByTagName("td")[0];
      alltags = tr[i].getElementsByTagName("td");
      isFound = false;
      for (j = 0; j < alltags.length; j++) {
        td = alltags[j];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
            j = alltags.length;
            isFound = true;
            count++;
          }
        }
      }
      if (!isFound && tr[i].className !== "header") {
        tr[i].style.display = "none";
      }
    }
    var error = document.getElementById("error");
    if (count === 0) {
      error.innerHTML = "<p style='color: red;'>" + "Data Not Found</p>";
    } else {
      error.innerHTML = "";
    }
  };

  const handleSelectAll = (e) => {
    props.setIsCheckAll(!props.isCheckAll);
    const drugcheckall = props.druguniquelist.map((li, i) => {
      return {
        drug_id: li.id.toString(),
        material_category_id: li.material_category_id,
      };
    });
    setIsCheckTrueAll(drugcheckall);
    props.setIsCheck(drugcheckall);
    if (props.isCheckAll) {
      props.setIsCheck([]);

      /*   props.setMaterialIDs2([]); */
    }
  };

  /* const loadtocheck = () => {
    const drugcheckall = resArr.map((li, i) => {
      return {
        drug_id: li.id.toString(),
        material_category_id: li.material_category_id,
      };
    });
    setIsCheckTrueAll(drugcheckall);
  };

  useEffect(() => {
    checklength();
    loadtocheck();

    if (props.isCheckAll) {
      props.setIsCheck(ischecktrueall);
    }

    if (checkdatatrue23) {
      props.setIsCheckAll(true);
    } else {
      props.setIsCheckAll(false);
    }
  }, [props.isCheck]); */

  /*   console.log(parseInt(props.isCheck.length).toString());

  const datacheckalllength = resArr;
  const datacheckischecklength = parseInt(props.isCheck.length).toString();

  const [checkdatatrue23, setCheckDataTrue23] = useState();

  const checklength = () => {
    var fn = datacheckalllength;
    var sn = datacheckischecklength;
    console.log(Number(fn) === Number(sn));
    setCheckDataTrue23(Number(fn) === Number(sn));
  }; */

  /* useEffect(() => {
    const drugcheckall = resArr.map((li, i) => {
      return {
        drug_id: li.id.toString(),
        material_category_id: li.material_category_id,
      };
    });

    props.setIsCheck(drugcheckall);
  }, []); */

  const handleClick = (e, drug) => {
    /*     materialsendHandle(e); */

    setCheckItem({ ...checkitem, name: "3" });

    drugidsendhandle(e, drug);
  };

  const drugidsendhandle = (e, drug) => {
    const { id, checked } = e.target;

    props.setIsCheck([
      ...props.isCheck,
      { drug_id: id, material_category_id: drug },
    ]);
    props.setIsCheckError(true);

    if (!checked) {
      props.setIsCheck(
        props.isCheck?.filter((item) => item.drug_id !== id.toString())
      );
    }
  };

  /*     onChange={(e) => {
                              setCheckMatID({
                                drugid: e.target.id,
                                matid: `${drug.material_category}`,
                              });
                            }} */

  const materialsendHandle = (drug) => {
    props.setMaterialIDs2([...props.materialids2, drug.material_category]);
    const newid = [
      ...props.drugmatidcheck2,
      { drug_id: drug.id, material_category_id: drug.material_category },
    ];
    props.setDrugMatIDCheck2(newid);

    /*imp  props.setMaterialIDs2([...props.materialids2, drug.material_category]); */

    /*     localStorage.setItem("step2", JSON.stringify(check25)); */

    /* const { id, checked, material_category } = e.target;
    const matid = material_category.toString();

    setCheckMatID([...checkmatId, material_category]);

    if (!checked) {
      setCheckMatID(checkmatId.filter((item) => item !== id.toString()));
    } */
  };

  /*   console.log(materialList);  */
  const currentStep = props.activestep + 1;
  /*   console.log(currentStep); */
  /*   console.log(checkmatId?.drugid); */

  /*   const dataload = [
    {
      drug_id: checkmatId?.drugid,
      material: checkmatId?.matid,
    },
  ]; */

  const propsid = props.isCheck.map((val) => val.drug_id);
  console.log(propsid);
  console.log(props.stepcompleted);

  return (
    <>
      <div style={{ pointerEvents: props.stepcompleted === 2 ? "" : "none" }}>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon3">
              Search Drugs
            </span>
          </div>
          <input
            type="text"
            class="form-control text-black"
            placeholder="For example - 'Pharama'"
            id="searchInput"
            aria-describedby="basic-addon3"
            onKeyUp={mySearchFunction}
          />
        </div>
        <div className="card">
          <div className="card-body">
            <div className="w-80 table-responsive">
              <div id="patientTable_basic_table" className="dataTables_wrapper">
                <table
                  id="drug_table"
                  className="table shadow-hover card-table border-no tbl-btn text-left text-black"
                  style={{ minWidth: 400 }}
                  role="grid"
                  aria-describedby="example5_info"
                >
                  <thead>
                    <th
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-sort="ascending"
                      aria-label=": activate to sort column descending"
                      style={{ width: 10 }}
                    >
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          name="selectAll"
                          onChange={handleSelectAll}
                          className="custom-control-input"
                          id="selectAll"
                          checked={props.isCheckAll}
                          /*  checked={props.isCheck?.drug_id} */
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="selectAll"
                        />
                      </div>
                    </th>

                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Date Check in: activate to sort column ascending"
                      style={{ width: 100 }}
                    >
                      Generic
                    </th>

                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="example5"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Date Check in: activate to sort column ascending"
                      style={{ width: 100 }}
                    >
                      Material Category
                    </th>
                  </thead>
                  <tbody className="step2scroll">
                    {props.draftbidstep2boolean ? (
                      <React.Fragment>
                        {props.draftbiddata?.step2_data.map((drug) => (
                          <tr role="row" className="odd">
                            <td>
                              <div className="custom-control custom-checkbox ">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  name={drug.id}
                                  id={drug.id}
                                  checked="checked"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={drug.id}
                                />
                              </div>
                            </td>
                            <td>
                              {props.drugList
                                .filter((dr) => dr.id == drug.drug_id)
                                .map((drv) => (
                                  <div>{drv.generic}</div>
                                ))}
                            </td>
                            <td>
                              {props.materialList
                                .filter(
                                  (mat) => mat.id == drug.material_category_id
                                )
                                .map((matlabel) => (
                                  <div>{matlabel.material_category}</div>
                                ))}

                              {/* {drug.material_category} */}
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {props.druguniquelist?.map((drug) => (
                          <tr role="row" className="odd">
                            <td>
                              <div className="custom-control custom-checkbox ">
                                <input
                                  type="checkbox"
                                  /*     onChange={(e) => {
                              setCheckMatID({
                                drugid: e.target.id,
                                matid: `${drug.material_category}`,
                              });
                            }} */
                                  onChange={(e) => {
                                    handleClick(e, drug.material_category_id);
                                    materialsendHandle(drug);
                                  }}
                                  className="custom-control-input"
                                  name={drug.generic}
                                  id={drug.id.toString()}
                                  checked={propsid.includes(drug.id.toString())}

                                  /*    checked={
                              props.isCheckAll
                                ? "checked"
                                : props.isCheck.drug_id?.includes(
                                    drug.id.toString()
                                  )
                            } */
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={drug.id.toString()}
                                />
                              </div>
                            </td>
                            <td>{drug.drug_id}</td>
                            <td>
                              {props.materialList
                                .filter(
                                  (mat) => mat.id == drug.material_category_id
                                )
                                .map((matlabel) => (
                                  <div>{matlabel.material_category}</div>
                                ))}

                              {/* {drug.material_category} */}
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    )}
                  </tbody>
                </table>
                <div id="error" className="text-center"></div>
              </div>
            </div>
          </div>

          <div className="card-footer">
            {props.checkboxerror2 ? (
              <span className="text-red">Select atleast One Checkbox</span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StepTwo;
