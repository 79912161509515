import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { PrevirwData as data } from "./PreviewData";
import PageTitle from "../../layouts/PageTitle";
import swal from "sweetalert";
import axios from "axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export default function Preview() {
  const [quoteData, setQuoteData] = useState([]);
  const history = useHistory();
  const location = useLocation();
  console.log(quoteData);
  console.log(location?.state);
  const submitHandle = () => {
    submitQuote().then((res) => {
      swal("Quotation Submitted Successfully", "", "success");
      history.push("/vendor-dashboard");
    });
  };

  useEffect(() => {
    getQuoteData().then((res) => {
      let temp = res?.data;
      temp = temp.filter((row) => {
        return row?.cost_price === "" || row?.cost_price === null
          ? false
          : true;
      });
      setQuoteData(temp);
    });
  }, []);

  async function submitQuote() {
    let filteredData = [...quoteData]
    filteredData = filteredData.filter((row) => row?.status === "0")
    let quoteIds = [];
    quoteIds = filteredData.map((row) => row?.id);
    const userId = localStorage.getItem("userId");
    const temp = {
      vendor_id: userId,
      bid_id: quoteData?.[0]?.bid_id,
      quote_id: quoteIds,
    };
    const res = await axios.put("/vendor_quote/updateQuoteStatus", temp);
    return res;
  }

  async function getQuoteData() {
    const userId = localStorage.getItem("userId");

    const res = await axios.get(`/bid/getQuote/${location?.state}/${userId}`);
    return res?.data;
  }

 
  //export
  function fnExport(fileExtn, filename) {

    quoteData.map((data, i) => {
      if (data.company_name === null) {
        data.company_name = companyName;
      }
      return data;
    });

    console.log("filterData", quoteData);
    let result = quoteData?.map((val) => {

      let exportDATA = {
        "generic": val.generic,
        "annual_volume": val.annual_volume,
        "brand_name": val.brand_name,
        "company_name": val.company_name,
        "dosage_form": val.material_category,
        "cost_price": val.cost_price,
        "gst": val.gst,
        "mrp": val.mrp,
        "hsn": val.hsn,

      }
      return exportDATA
    })

    console.log(result);

    let header = [
      "GENERIC",
      "ANNUAL VOLUME",
      "BRAND NAME",
      "COMPANY NAME",
      "DOSAGE FORM",
      "COST PRICE",
      "GST",
      "MRP",
    
      "HSN"

    ];


    const ws = XLSX.utils.book_new();

    XLSX.utils.sheet_add_aoa(ws, [header], {
      origin: "A1",
    });
    XLSX.utils.sheet_add_json(ws, result, {
      origin: "A2",
      skipHeader: true,
    });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"], range: 5 };

    /* TEST: column props */
    // var wscols = [ {wch:20,color:"red"}, {wch:30}, {wch:40}, {wch:30},{wch:10}, {wch:10}];
    // ws['!cols'] = wscols;

    ws["A1"].style = {
      font: {
        name: "arial",
        sz: 24,
        bold: true,
        color: "#F2F2F2",
      },
    };

    var wscols = [
      { width: 30 },
      { width: 18 },
      { width: 25 },
      { width: 25 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
    ];
    ws["!cols"] = wscols;

    const excelBuffer = XLSX.write(wb, {
      bookType: fileExtn,
      type: "array",
      cellStyles: true,
    });
    const finalData = new Blob([excelBuffer], { type: "xlsx" });
    FileSaver.saveAs(finalData, filename);
  }

  useEffect(() => {
    getHeaderData().then((res) =>
      setCompanyName(res?.data?.data?.[0]?.company_name)
    );
  }, []);

  const [companyName, setCompanyName] = useState("");
  async function getHeaderData() {
    const userId = localStorage.getItem("userId");
    const res = await axios.get(`/vendor/getSingleVendor/${userId}`);
    return res;
  }
  return (
    <div>
      <PageTitle activeMenu="Preview" motherMenu="Vendor Quote" />
      <div className="card" style={{ fontSize: "0.82rem" }}>
        <div className="card-header   justify-content-end">
          <i
            id="btn-export"
            onClick={(e) => fnExport("xlsx", "QuotePreviewData.xlsx", e)}
            className="fa fa-cloud-download  mx-2 "
            style={{
              fontSize: "30px",
              color: "var(--primary)",
              cursor: "pointer",
            }}
            aria-hidden="true"
          ></i>
        </div>
        <div className="w-100 table-responsive my-3">
          <table className="table shadow-hover text-black text-nowrap text-center card-table border-no tbl-btn text-left">
            <thead>
              <tr>
                <th className="text-left">Generic</th>
                <th>Annual Volume</th>
                <th>Dosage Form</th>
                <th>Brand Name</th>
                <th>CP + GST</th>
                <th>MRP</th>
                <th>HSN</th>
                <th>Company Name</th>
              </tr>
            </thead>

            <tbody>
              {quoteData.map((val, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: val?.status === "1" ? "#D6EFC7" : "",
                  }}
                >
                  <td className="text-left">
                    {val?.generic === "" ? "value not entered" : val?.generic}
                  </td>
                  <td>
                    {val?.annual_volume === ""
                      ? "value not entered"
                      : val?.annual_volume}
                  </td>
                  <td>
                    {val?.material_category === ""
                      ? "value not entered"
                      : val?.material_category}
                  </td>
                  <td>
                    {val?.brand_name === ""
                      ? "value not entered"
                      : val?.brand_name}{" "}
                  </td>
                  <td>
                    {" "}
                    {val?.cpGst === ""
                      ? "value not entered"
                      : val?.cpGst}
                  </td>
                  <td> {val?.mrp === "" ? "value not entered" : val?.mrp} </td>
                  <td> {val?.hsn === "" ? "value not entered" : val?.hsn} </td>
                  <td>
                    {" "}
                    {val?.company_name === null
                      ? companyName
                      : val?.company_name}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="card-footer">
          <div className="col-md-12 d-flex justify-content-end">
            <button
              onClick={() =>
                history.push({
                  pathname: "/vendor-quote",
                  state: location?.state,
                })
              }
              className="btn btn-warning mx-2"
            >
              BACK
            </button>

            <button onClick={submitHandle} className="btn btn-primary mx-2">
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
