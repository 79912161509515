import axios from "axios";
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { useFormik, Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  addUser,
  CreateUserAction,
  loadUserRoles,
  loadUsers,
} from "../../../../store/actions/UserActions";
import {
  CREATE_USER_ACTION,
  GET_USER_ACTION,
} from "../../../../store/actions/UserTypes";
import { useSelector } from "react-redux";

function AddUserModal(props) {
  const otpshow = useSelector((state) => state.users.usershowOTP);
  const usersemail = useSelector((state) => state.users.users);

  const history = useHistory();
  const dispatch = useDispatch();

  const localuserid = localStorage.getItem("userId");

  const [addUserData, setAddUserData] = useState([
    {
      first_name: "",
      last_name: "",
      email_id: "",
      phone_number: "",
      user_role: "",
      created_by_user: localuserid,
    },
  ]);

  const [modalCentered, setModalCentered] = useState(false);

  const [selectvalues, setSelectValues] = useState([]);
  /* const [addResponse, setAddResponse] = useState([]); */

  const AddUserHandle = () => {
    swal("User Added", "", "success");
    setModalCentered(false);
  };

  const addUserChange = (e) => {
    setAddUserData({ ...addUserData, [e.target.name]: e.target.value });
  };

  const addUserSubmit = (e) => {
    /*   e.preventDefault(); */
    console.log(e);

    /*  dispatch(addUser(addUserData)); */

    axios.post("/user/create_user", e).then((response) => {
      console.log(response);
      if (response.data.hasError) {
        swal({
          title: "User email or phone number already in use.",
          text: `Please try using another email/phone number `,
          icon: "error",
        });
      } else {
        swal({
          title: "User Added Successfully",
          text: `User One Time Password is ${response.data.data.mandatory_pwd_change} `,
          icon: "success",
        });
      }
      dispatch(loadUsers());
      dispatch(loadUserRoles());
      setModalCentered(false);
    });
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  //Validation Start For Formik
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("Please Enter First Name"),
    last_name: Yup.string().required("Please Enter Last Name"),

    email_id: Yup.string()
      .required("Email is required")
      .email("Email is invalid"),
    phone_number: Yup.string()
      .required("Please Enter Contact Number")
      .matches(phoneRegExp, "Phone Number is not Valid")
      .min(10, "to Short")
      .max(10, "to Long"),
    user_role: Yup.string().required("Please Select Role"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email_id: "",
      phone_number: "",
      user_role: "",
      created_by_user: localuserid,
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    /*  touched: true, */
    onSubmit: (data) => {
      /*  alert(JSON.stringify(data, null, 2)); */

      /*   alert("email already exist"); */

      addUserSubmit(data);
      formik.handleReset();
    },

    onReset: () => {
      setModalCentered(false);
    },
  });

  return (
    <div>
      <div className="col-sm-6 col-md-12 d-flex justify-content-end mb-0">
        <button
          className="btn text-primary"
          onClick={() => setModalCentered(true)}
        >
          <i class="fa fa-user-plus fa-2x" aria-hidden="true"></i>
        </button>
      </div>

      <div>
        <Modal
          className="fade"
          show={modalCentered}
          onHide={(e) => setModalCentered(true)}
        >
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <Modal.Header>
              <h4 className="text-black">Add User</h4>
              <Button onClick={formik.handleReset} variant="" className="close">
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>
              <div className="col-sm-6 col-md-12">
                <div className=" flex-wrap justify-content-around">
                  <div className="form-group  ">
                    <label htmlFor="">
                      First Name <span className="text-danger">*</span>
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      onChange={formik.handleChange}
                      name="first_name"
                      value={formik.values.first_name}
                    />
                    <div className="text-danger">
                      {/*                       {formik.errors.first_name && formik.touched.first_name
                        ? formik.errors.first_name
                        : null} */}

                      {formik.errors.first_name &&
                        formik.touched.first_name && (
                          <div className="input-feedback">
                            {formik.errors.first_name}
                          </div>
                        )}
                    </div>

                    <input
                      type="hidden"
                      name="created_by_user"
                      value={formik.values.created_by_user}
                      onChange={formik.handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={formik.handleChange}
                      name="last_name"
                      value={formik.values.last_name}
                    />
                    <div className="text-danger">
                      {/*  {formik.errors.last_name ? formik.errors.last_name : null} */}

                      {formik.errors.last_name && formik.touched.last_name && (
                        <div className="input-feedback">
                          {formik.errors.last_name}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex-wrap justify-content-around">
                  <div className="form-group  ">
                    <label htmlFor="">
                      Email <span className="text-danger">*</span>
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      onChange={formik.handleChange}
                      name="email_id"
                      value={formik.values.email_id}
                    />

                    <div className="text-danger">
                      {/*  {formik.errors.email_id ? formik.errors.email_id : null} */}

                      {formik.errors.email_id && formik.touched.email_id && (
                        <div className="input-feedback">
                          {formik.errors.email_id}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Phone Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={formik.handleChange}
                      name="phone_number"
                      value={formik.values.phone_number}
                    />

                    <div className="text-danger">
                      {formik.errors.phone_number &&
                        formik.touched.phone_number && (
                          <div className="input-feedback">
                            {formik.errors.phone_number}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1">
                        Select Role <span className="text-danger">*</span>
                      </label>

                      <select
                        class="form-control"
                        id="exampleFormControlSelect1"
                        name="user_role"
                        onChange={formik.handleChange}
                        value={formik.values.user_role}
                      >
                        <option value="">Select Role</option>
                        {props.userroles?.map((role) => (
                          <option value={role.id}>{role.role_name}</option>
                        ))}
                      </select>
                      <div className="text-danger">
                        {formik.errors.user_role &&
                          formik.touched.user_role && (
                            <div className="input-feedback">
                              {formik.errors.user_role}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={formik.handleReset} variant="danger light">
                CLOSE
              </Button>

              <button type="submit" className="btn btn-primary text-white">
                SUBMIT
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </div>
  );
}

export default AddUserModal;
