import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
// import { logout } from '../../store/actions/AuthActions';
import login from "../../images/bg-login2.png";
import loginbg from "../../images/bg-login.jpg";
import logo1 from "../../images/logo-full.png";
import swal from "sweetalert";
import { forgotPasswordAction } from "../../store/actions/forgotPassAction";
// image
import logo from "../../images/logo-full.png";
import { propTypes } from "react-bootstrap/esm/Image";
import { useHistory } from "react-router-dom";

const ForgotPassword = (props) => {
  const history = useHistory();
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();
  const [emptyEmail, setEmptyEmail] = useState(false);
  useEffect(() => {
    if (window.location.href.indexOf("login") > -1) {
      window.location.assign(`/login`);
      localStorage.removeItem("userEmail");
      localStorage.removeItem("userType");
    }
  });

  useEffect(() => {
    if (props.userDetailsError === false) {
      swal({
        title: "OTP sent successfully, please check your Email for the OTP",
        icon: "success",
        timer: 3000,
      });
      setTimeout(() => {
        window.location.assign(`/login`);
      }, 2000);
    } else if (props.userDetailsError === true) {
      swal({ title: props.userMessage, icon: "error", timer: 3000 });
    }
  }, [props?.userDetailsError]);

  const clickBack = () => {
    window.location.assign(`/login`);
  };

  const validateEmail = (e) => {
    var emailChecker = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var emailtest = e.target.value;
    setEmail(emailtest);
    var validateEmail = emailChecker.test(emailtest);
    // alert(validateEmail)

    if (!validateEmail) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };
  const dispatch = useDispatch();
  const onSubmit = (e) => {
    e.preventDefault();
    if (email === "" || email === undefined) {
      setEmptyEmail(true);
    }

    if (!emailError && email !== "" && email != undefined) {
      dispatch(forgotPasswordAction(email, props.history));
      swal({
        title: `OTP is Sent to ${email}`,
        text: "Please Check Your Email",
        icon: "success",
      }).then((willDelete) => {
        if (willDelete) {
          window.location.assign(`/login`);
        }
      });
    }
  };
  return (
    <div
      className="login-main-page"
      style={{ backgroundImage: "url(" + loginbg + ")" }}
    >
      <div className="login-wrapper">
        <div
          className="login-aside-left"
          style={{ backgroundImage: "url(" + login + ")" }}
        >
          <Link className="login-logo">
            <img src={logo1} alt="logo" />
          </Link>
          <div className="login-description">
            <h2 className="text-black  mb-2">Lorem Ipsum</h2>
            <p className="fs-12 text-dark">
              {/* Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also th */}
            </p>
          </div>
        </div>

        <div className="login-aside-right" id="loginpage">
          <i
            className="fa fa-arrow-left"
            aria-hidden="true"
            onClick={clickBack}
            style={{
              marginTop: "5%",
              marginLeft: "10%",
              fontSize: "20px",
              cursor: "pointer",
            }}
          ></i>
          <div className="row m-0 justify-content-center h-100 align-items-center">
            <div className="col-xl-7 col-xxl-7">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form-1">
                      <div style={{ marginBottom: "60px" }}>
                        <h3 className="text-center text-white mb-4">
                          Forgot Password
                        </h3>
                        {/* <p className="">
                        Sign in by entering information below
                      </p> */}
                      </div>
                      {/* <h3 className="text-center text-white mb-8 ">Forgot Password</h3> */}
                      <form>
                        <div className="form-group ">
                          <label className="text-center justify-content-center align-items-center">
                            <strong>Email</strong>
                          </label>
                          <input
                            onChange={validateEmail}
                            type="email"
                            className="form-control "
                            placeholder="Please Enter Your Registered Email"
                            value={email}
                            // defaultValue="hello@example.com"
                          />
                          {emailError || emptyEmail === true ? (
                            <div
                              className="bg-red-300 text-red-900 border border-red-900 p-1 my-2"
                              style={{
                                backgroundColor: "red",
                                color: "aliceblue",
                              }}
                            >
                              {"Please Enter a Valid Email-Id"}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="text-center">
                          <button
                            onClick={(e) => onSubmit(e)}
                            type="submit"
                            className="btn btn-warning text-white btn-block"
                          >
                            SUBMIT
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log("23232", state.forget.forgotErrorMessage.hasError);
  console.log("message", state.forget.forgotErrorMessage.message);

  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
    mandatory_pwd_change: state.auth.auth.mandatory_pwd_change,
    userId: state.auth.auth.id,
    userEmail: state.auth.auth.email_id,
    userDetailsError: state.forget.forgotErrorMessage.hasError,
    userMessage: state.forget.forgotErrorMessage.message,
  };
};

export default connect(mapStateToProps)(ForgotPassword);
