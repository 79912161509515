import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import { PrevirwRevalutionData as data } from "../PreviewRevalution/PreviewRevalutionData";
import PageTitle from "../../layouts/PageTitle";
import axios from "axios";
import swal from "sweetalert";
import { useLocation } from "react-router-dom";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export default function AdminPreviewRevalution() {
  const location = useLocation();
  const history = useHistory();
  const [highlight, setHighlight] = useState(null);
  // const [isSubmit,setIsSubmit] = useState(false);
  console.log(location?.state?.SelectedItem);

  let colorCode = null;
  let flag = true;
  let colorClass = true;
  let categoryLabel = 1;

  const submitHandle = () => {
    swal("Quotation Submitted Successfully", "", "success");
    const ids = location?.state?.SelectedItem?.map((x) => {
      return x.id;
    });
    console.log("Idssss", ids);

    if (ids.length !== 0) {
      axios.put("/revaluation/updateRevolutionStatus", {
        revelStatus: "1",
        quoteId: ids,
      });
    }
    setTimeout(() => {
      history.push({ pathname: "/dashboard" });
    }, 1000);
  };

  const [materiallist, setMaterial] = useState([]);
  function getAllMaterialCategory() {
    axios.get("/material_category/getAllMaterialCategory").then((response) => {
      // console.log(response.data.data, "material list");
      setMaterial(response.data.data);
    });
  }
  const [error, setError] = useState(false);

  useEffect(() => {
    getAllMaterialCategory();
    location?.state?.SelectedItem?.map((val) => {
      if (val.expected_price === 0 || val.expected_price === "") {
        setError(true);
      }
    });
  }, []);

  //export
  function fnExport(fileExtn, filename) {

    location?.state?.SelectedItem?.map((newdata) => {
      let cpGst =
        Number(newdata?.cost_price) +
        (Number(newdata?.cost_price) * Number(newdata?.gst)) / 100;
      let margin = Number(newdata?.mrp) - Number(cpGst);
      margin = Math.round(margin * 100) / 100;
      let marginPercent = (Number(cpGst) / Number(newdata?.mrp)) * 100;
      marginPercent = 100 - marginPercent;
      marginPercent = Math.round(marginPercent * 100) / 100;
      newdata.marginValue = margin;
      newdata.margin_parcentage = marginPercent;
    })
   
    location?.state?.SelectedItem?.map((newdata) => {
        let res =
        newdata.quote_priority === null
          ? "priority is not added"
          : newdata.quote_priority;
      let res1 =
        newdata.quote_remark === null ? "remark is not added" : newdata.quote_remark;
    
      newdata.quote_priority = res;
      newdata.quote_remark = res1;
    });

    location?.state?.SelectedItem?.map((val) => {
      if (val.approval_status === 1) {
        val.approval_status = "Approved";
      } else if (val.approval_status === -1) {
        val.approval_status = "Rejected";
      } else {
        val.approval_status = "Not Selected";
      }
    });


    console.log("updated_category", location?.state?.SelectedItem)
    let result = location?.state?.SelectedItem?.map((val)=>{
      let exportDATA= {
        "generic":val.generic,
        "description":val.brand_name,
        "vendor_company":val.company_name,
        "material_category":val.material_category,
        "cost_price":val.cost_price,
        "gst":val.gst,
        "mrp":val.mrp,
        "margin":val.marginValue,
        "margin_percentage":val.margin_parcentage,
        "quote_category":val.quote_category,
        "quote_priority":val.quote_priority,
        "quote_remark":val.quote_remark,
        "status_approval":val.approval_status,
        "expected_price":val.expected_price,
      }
      return exportDATA
    })

    console.log(result);
    let header = [
      "GENERIC",
      "DESCIPTION",
      "VENDOR COMPANY",
      "MATERIAL CATEGORY",
      "COST PRICE",
      "GST",
      "MRP",
      "MARGIN",
      "MARGIN PERCENTAGE %",
      "CATEGORY",
      "QUOTE PRIORITY",
      "QUOTE REMARK",
      "STATUS APPROVAL",
      "EXPECTED PRICE"

    ];
    const ws = XLSX.utils.book_new();

    XLSX.utils.sheet_add_aoa(ws, [header], {
      origin: "A1",
    });
    XLSX.utils.sheet_add_json(ws, result, {
      origin: "A2",
      skipHeader: true,
    });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"], range: 5 };

    ws["A1"].style = {
      font: {
        name: "arial",
        sz: 24,
        bold: true,
        color: "#F2F2F2",
      },
    };

    var wscols = [
      { width: 40 },
      { width: 20 },
      { width: 38 },
      { width: 20 },
      { width: 15 },
      { width: 19 },
      { width: 15 },
      { width: 19 },
      { width: 15 },
      { width: 24 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];
    ws["!cols"] = wscols;

    const excelBuffer = XLSX.write(wb, {
      bookType: fileExtn,
      type: "array",
      cellStyles: true,
    });
    const finalData = new Blob([excelBuffer], { type: "xlsx" });
    FileSaver.saveAs(finalData, filename);

  }

  const backrevolutionhandle = () => {
    history.push({
      pathname: "/admin-revalution",
      state: {
        bidId: location?.state?.bidId,
        vendorId: location?.state?.vendorId,
        AdminSelectedItems: AdminSelectedItems,
      },
    });
  };

  let AdminSelectedItems = location?.state?.SelectedItem.map((val) => {
    return val;
  });

  return (
    <>
      {/*     <PageTitle
        activeMenu="Revaluation Preview"
        motherMenu="AdminRevaluation"
      /> */}
      <div className="mb-5">
        <span onClick={backrevolutionhandle} style={{ cursor: "pointer" }}>
          AdminRevaluation
        </span>{" "}
        /{" "}
        <span className="text-primary" style={{ fontWeight: "bold" }}>
          Revaluation Preview
        </span>
      </div>

      <div className="card" style={{ padding: "40px", fontSize: "0.82rem" }}>
        <div className="card-header   justify-content-end">
          <i
            id="btn-export"
            onClick={(e) => fnExport("xlsx", "AdminQuotePreviewData.xlsx", e)}
            className="fa fa-cloud-download  mx-2 "
            style={{
              fontSize: "30px",
              color: "var(--primary)",
              cursor: "pointer",
            }}
            aria-hidden="true"
          ></i>
        </div>
        <div className="w-100 table-responsive">
          <div className="form-group">
            <table className="table shadow-hover card-table border-no tbl-btn text-nowrap text-black">
              <div style={{ height: "40vh", overflow: "auto" }}>
                <thead className="text-wrap">
                  <tr>
                    <th
                      style={{
                        // width: "200px",
                        position: "sticky",
                        top: "0",
                        zIndex: "1",
                        backgroundColor: "white",
                      }}
                      className=""
                    >
                      Generic
                    </th>
                    <th
                      style={{
                        // width: "200px",
                        position: "sticky",
                        top: "0",
                        zIndex: "1",
                        backgroundColor: "white",
                      }}
                      className=""
                    >
                      Description
                    </th>
                    <th
                      style={{
                        // width: "200px",
                        position: "sticky",
                        top: "0",
                        zIndex: "1",
                        backgroundColor: "white",
                      }}
                      className=""
                    >
                      Vendor Company
                      {/* <Select options={options} />  */}
                    </th>
                    <th
                      style={{
                        // width: "200px",
                        position: "sticky",
                        top: "0",
                        zIndex: "1",
                        backgroundColor: "white",
                      }}
                      className=""
                    >
                      Material Category{" "}
                    </th>
                    <th
                      style={{
                        // width: "200px",
                        position: "sticky",
                        top: "0",
                        zIndex: "1",
                        backgroundColor: "white",
                      }}
                      className=""
                    >
                      CP+GST
                    </th>
                    <th
                      style={{
                        // width: "200px",
                        position: "sticky",
                        top: "0",
                        zIndex: "1",
                        backgroundColor: "white",
                      }}
                      className=""
                    >
                      MRP
                    </th>
                    <th
                      style={{
                        // width: "200px",
                        position: "sticky",
                        top: "0",
                        zIndex: "1",
                        backgroundColor: "white",
                      }}
                      className=""
                    >
                      MARGIN
                    </th>
                    <th
                      style={{
                        // width: "200px",
                        position: "sticky",
                        top: "0",
                        zIndex: "1",
                        backgroundColor: "white",
                      }}
                      className=""
                    >
                      %
                    </th>
                    <th
                      style={{
                        // width: "200px",
                        position: "sticky",
                        top: "0",
                        zIndex: "1",
                        backgroundColor: "white",
                      }}
                      className=""
                    >
                      Category
                    </th>
                    <th
                      style={{
                        // width: "200px",
                        position: "sticky",
                        top: "0",
                        zIndex: "1",
                        backgroundColor: "white",
                      }}
                      className=""
                    >
                      Priority
                    </th>
                    <th
                      style={{
                        // width: "200px",
                        position: "sticky",
                        top: "0",
                        zIndex: "1",
                        backgroundColor: "white",
                      }}
                      className=""
                    >
                      Remark
                    </th>
                    <th
                      style={{
                        // width: "200px",
                        position: "sticky",
                        top: "0",
                        zIndex: "1",
                        backgroundColor: "white",
                      }}
                      className=""
                    >
                      Status Approval
                    </th>
                    <th
                      style={{
                        // width: "200px",
                        position: "sticky",
                        top: "0",
                        zIndex: "1",
                        backgroundColor: "white",
                      }}
                      className=""
                    >
                      Expected Price
                    </th>
                  </tr>
                </thead>
                <tbody className="text-wrap">
                  {location?.state?.SelectedItem?.map((newdata, index, arr) => {
                    let price = newdata?.discounted_price === 0 ? newdata?.cost_price : newdata?.discounted_price;
                    let cpGst =
                      Number(price) +
                      (Number(price) *
                        Number(newdata?.gst)) /
                      100;
                    let margin = Number(newdata?.mrp) - Number(cpGst);
                    let marginPercent =
                      (Number(cpGst) / Number(newdata?.mrp)) * 100;
                    marginPercent = 100 - marginPercent;
                    marginPercent = Math.round(marginPercent * 100) / 100;
                    margin = Math.round(margin * 100) / 100;
                    cpGst = Math.round(cpGst * 100) / 100;
                    if (arr[index - 1]?.generic !== newdata?.generic)
                      flag = false;
                    else flag = true;
                    colorClass = flag ? colorClass : !colorClass;
                    flag
                      ? (categoryLabel = categoryLabel + 1)
                      : (categoryLabel = 1);
                      newdata.quote_category = 'L' + categoryLabel;
                    return (
                      <tr
                        onClick={(e) => setHighlight(index)}
                        className={
                          colorClass
                            ? index === highlight ? "bg-primary text-white" : "text-light text-muted"
                            : index === highlight ? "bg-primary text-white" : "bg-light text-dark"
                        }
                      >
                        <>
                          <td style={{ maxWidth: "200px" }}>
                            {newdata?.generic}
                          </td>
                          <td className="">{newdata?.brand_name}</td>
                          <td>{newdata?.company_name}</td>
                          <td className="text-center">
                            {newdata?.material_category}
                          </td>
                          <td>{cpGst}</td>
                          <td>{newdata?.mrp}</td>
                          <td>{margin}</td>
                          <td>{marginPercent}</td>
                          {/* <td className="text-center">
                                                            {"L" + categoryLabel}
                                                        </td> */}
                          <td className="text-center">
                            {"L" + categoryLabel}
                          </td>
                          {/* <td className="text-center">
                                {newdata?.quote_category}
                              </td> */}
                          <td>
                            {/* {newdata?.quote_priority === null ? "" : ""} */}
                            {newdata?.quote_priority ?? "No priority set."}
                          </td>
                          <td>
                            {newdata?.quote_priority === -1
                              ? "No remarks added."
                              : newdata?.quote_remark ??
                              "No remarks added."}
                          </td>
                          <td>
                            {newdata?.approval_status === 1 && "Approved" || newdata?.approval_status === -1 && "Rejected" || newdata?.approval_status === 0 && "Not selected"}
                          </td>
                          <td>
                            {newdata?.expected_price}
                          </td>
                        </>
                      </tr>
                    );
                  })}
                </tbody>
              </div>
            </table>
          </div>
        </div>
        <div className="card-footer">
          <div className="col d-flex justify-content-end">
            <button
              onClick={(e) => {
                history.push({
                  pathname: "/admin-revalution",
                  state: {
                    bidId: location?.state?.bidId,
                    vendorId: location?.state?.vendorId,
                    AdminSelectedItems: AdminSelectedItems,
                  },
                });
              }}
              className="btn btn-warning mx-2"
            >
              BACK
            </button>

            <button
              onClick={submitHandle}
              className="btn btn-primary mx-2"
              disabled={error}
            >
              SUBMIT
            </button>
          </div>
          {error && (
            <span className="text-danger" style={{ fontSize: "20px" }}>
              *Unable to submit, please enter expected price.{" "}
            </span>
          )}
        </div>
      </div>
    </>
  );
}
