import React, { Fragment, useState, useEffect } from "react";
// import { Button, Dropdown, Modal } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { SRLWrapper } from "simple-react-lightbox";
//** Import Image */
// import profile01 from "../../../../images/profile/1.jpg";
// import profile02 from "../../../../images/profile/2.jpg";
// import profile03 from "../../../../images/profile/3.jpg";
// import profile04 from "../../../../images/profile/4.jpg";
// import profile05 from "../../../../images/profile/5.jpg";
// import profile06 from "../../../../images/profile/6.jpg";
// import profile07 from "../../../../images/profile/7.jpg";
// import profile08 from "../../../../images/profile/8.jpg";
// import profile09 from "../../../../images/profile/9.jpg";
import profile from "../../../../images/profile/profile.png";
import PageTitle from "../../../layouts/PageTitle";

const AppProfile = () => {
  // const [activeToggle, setActiveToggle] = useState("posts");
  // const [postModal, setPostModal] = useState(false);
  // const [cameraModal, setCameraModal] = useState(false);
  // const [linkModal, setLinkModal] = useState(false);

  let [userData, setUserData] = useState({
    userName:
      localStorage.getItem("userType")?.replace(/["]+/g, "") === "vendor"
        ? localStorage.getItem("vendorName")?.replace(/["]+/g, "")
        : localStorage.getItem("userName")?.replace(/["]+/g, ""),
    email: localStorage.getItem("userEmail")?.replace(/["]+/g, ""),
    role:
      localStorage.getItem("userType")?.replace(/["]+/g, "") === "vendor"
        ? "Vendor"
        : localStorage.getItem("userRole")?.replace(/["]+/g, ""),
    status: localStorage.getItem("userStatus")?.replace(/["]+/g, ""),
    type:
      localStorage.getItem("userType")?.replace(/["]+/g, "") === "vendor"
        ? "Vendor"
        : "User",
    companyName:
      localStorage.getItem("userType")?.replace(/["]+/g, "") === "vendor"
        ? localStorage.getItem("companyName")?.replace(/["]+/g, "")
        : "",
    vendorPhone:
      localStorage.getItem("userType")?.replace(/["]+/g, "") === "vendor"
        ? localStorage.getItem("vendorContact")?.replace(/["]+/g, "")
        : "",
    vendorAddress:
      localStorage.getItem("userType")?.replace(/["]+/g, "") === "vendor"
        ? localStorage.getItem("companyAddress")?.replace(/["]+/g, "")
        : "",
  });

  useEffect(() => {
    // console.log("userDatauserData",userData)
    localStorage.setItem("data", JSON.stringify(userData));
  });

  // const [replayModal, setReplayModal] = useState(false);

  // const options = {
  //   settings: {
  //     overlayColor: "#000000",
  //   },
  // };
  return (
    <Fragment>
      <PageTitle activeMenu="Profile" motherMenu={userData.type} />
      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content">
                <div className="cover-photo"></div>
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <img
                    src={profile}
                    className="img-fluid rounded-circle"
                    alt="profile"
                  />
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">{userData.userName}</h4>
                    {/* <p>UX / UI Designer</p> */}
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">{userData.email}</h4>
                    <p>Email</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="profile-personal-info">
          <h4 className="text-primary mb-4">Personal Information</h4>
          <div className="row mb-2">
            <div className="col-3">
              <h5 className="f-w-500">
                {" "}
                Name<span className="pull-right">:</span>
              </h5>
            </div>
            <div className="col-9">
              <span>{userData.userName}</span>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-3">
              <h5 className="f-w-500">
                Email<span className="pull-right">:</span>
              </h5>
            </div>
            <div className="col-9">
              <span>{userData.email}</span>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-3">
              <h5 className="f-w-500">
                {" "}
                Role<span className="pull-right">:</span>
              </h5>
            </div>
            <div className="col-9">
              <span>{userData.role}</span>
            </div>
          </div>
          {localStorage.getItem("userType")?.replace(/["]+/g, "") ===
          "vendor" ? (
            <>
              <div className="row mb-2">
                <div className="col-3">
                  <h5 className="f-w-500">
                    {" "}
                    Address<span className="pull-right">:</span>
                  </h5>
                </div>
                <div className="col-9">
                  <span>{userData.vendorAddress}</span>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-3">
                  <h5 className="f-w-500">
                    {" "}
                    Vendor Contact<span className="pull-right">:</span>
                  </h5>
                </div>
                <div className="col-9">
                  <span>{userData.vendorPhone}</span>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-3">
                  <h5 className="f-w-500">
                    {" "}
                    Company Name<span className="pull-right">:</span>
                  </h5>
                </div>
                <div className="col-9">
                  <span>{userData.companyName}</span>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          <div className="row mb-2">
            <div className="col-3">
              <h5 className="f-w-500">
                Status<span className="pull-right">:</span>
              </h5>
            </div>
            <div className="col-9">
              <span>{userData.status}</span>
            </div>
          </div>

          {/* <div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500">  Location<span className="pull-right">:</span></h5>
									</div>
									<div className="col-9">
										<span>Rosemont Avenue Melbourne, Florida</span>
									</div>
								</div>
								<div className="row mb-2">
									<div className="col-3">
										<h5 className="f-w-500">Year Experience<span className="pull-right">:</span></h5>
									</div>
									<div className="col-9">
										<span>07 Year Experiences</span>
									</div>
								</div> */}
        </div>
      </div>
    </Fragment>
  );
};

export default AppProfile;
