import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
// import { useLocation } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import swal from "sweetalert";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export default function PreviewRevalution() {
  const history = useHistory();
  // const location = useLocation();
  const [isSubmit, setIsSubmit] = useState(false)
  const [data, setData] = useState([]);
  const [highlight, setHighlight] = useState(null);

  const location = useLocation()

  const GetStateValue = () => {
    location?.state?.map((val) => {
      console.log("getValue Successfully")
    })
  }

  // console.log("previewdata", location?.state)

  let colorCode = null;
  let flag = true;
  let colorClass = true;
  let categoryLabel = 1;

  const submitHandle = () => {
    swal("Quotation Submitted Successfully", "", "success");
    setTimeout(() => {
      history.push("/vendor-dashboard");
      const ids = location?.state?.map((x) => {
        return x.id;
      });
      if (ids.length !== 0) {
        axios.put(
          "/revaluation/updateRevolutionStatus",
          {
            revelStatus: "0",
            quoteId: ids,
          }
        );
      }

      // revelStatus:"0"
      // quoteId:ids
    }, 1000);
  };

  const [materiallist, setMaterial] = useState([]);
  function getAllMaterialCategory() {
    axios.get("/material_category/getAllMaterialCategory").then((response) => {
      // console.log(response.data.data, "material list");
      setMaterial(response.data.data);
    });
  }

  const [error, setError] = useState(false);
  useEffect(() => {
    getAllMaterialCategory();
    location?.state?.map((val) => {
      if (val.discounted_price === 0 || val.discounted_price === "") {
        setIsSubmit(true);
        setError(true);
      }

    })
  }, []);

  //export
  function fnExport(fileExtn, filename) {

    let colorCode = null;
    let flag = true;
    let colorClass = true;
    let categoryLabel = 1;
    let Priority = "P";

    location?.state?.map((newdata, index, arr) => {
      newdata.cost_price = newdata?.discounted_price === 0 ? newdata?.cost_price : newdata?.discounted_price;
      let cpGst =
        Number(newdata?.cost_price) +
        (Number(newdata?.cost_price) * Number(newdata?.gst)) / 100;
      let margin = Number(newdata?.mrp) - Number(cpGst);
      let marginPercent = (Number(cpGst) / Number(newdata?.mrp)) * 100;
      marginPercent = 100 - marginPercent;
      marginPercent = Math.round(marginPercent * 100) / 100;
      margin = Math.round(margin * 100) / 100;
      cpGst = Math.round(cpGst * 100) / 100;
      if (arr[index - 1]?.generic !== newdata?.generic) flag = false;
      else flag = true;
      colorClass = flag ? colorClass : !colorClass;
      flag ? (categoryLabel = categoryLabel + 1) : (categoryLabel = 1);

    });

    location?.state?.map((newdata) => {
      let cpGst =
        Number(newdata?.cost_price) +
        (Number(newdata?.cost_price) * Number(newdata?.gst)) / 100;
      let margin = Number(newdata?.mrp) - Number(cpGst);
      margin = Math.round(margin * 100) / 100;
      let marginPercent = (Number(cpGst) / Number(newdata?.mrp)) * 100;
      marginPercent = 100 - marginPercent;
      marginPercent = Math.round(marginPercent * 100) / 100;
      newdata.marginValue = margin;
      newdata.margin_parcentage = marginPercent;
    })
 
    location?.state?.map((val) => {
      if (val.approval_status === 1) {
        val.approval_status = "Approved";
      }
      else if (val.approval_status === -1) {
        val.approval_status = "Rejected";
      }
      else {
        val.approval_status = "Not Selected";
      }

    })

    // console.log("status approval", location?.state);

    let result = location?.state?.map((val)=>{
      let exportDATA = {
        "generic":val.generic,
        "description":val.brand_name,
        "vendor_company":val.company_name,
        "material_category":val.material_category,
        "cost_price":val.cost_price,
        "gst":val.gst,
        "mrp":val.mrp,
        "margin":val.marginValue,
        "margin_parcentage":val.margin_parcentage,
        "quote_category":val.quote_category,
        "status_approval":val.approval_status,
        "expected_price":val.expected_price,
        "discounted_price":val.discounted_price,
      }
      return exportDATA;
    })
    console.log(result);

    // console.log("updated_category", exportVendorQuoteData)
    let header = [
      "GENERIC",
      "DESCIPTION",
      "VENDOR COMPANY",
      "MATERIAL CATEGORY",
      "COST PRICE",
      "GST",
      "MRP",
      "MARGIN",
      "MARGIN PERCENTAGE %",
      "QUOTE CATEGORY",
      "STATUS APPROVAL",
      "EXPECTED PRICE",
      "DISCOUNTED PRICE"
    ];

    const ws = XLSX.utils.book_new();

    XLSX.utils.sheet_add_aoa(ws, [header], {
      origin: "A1",
    });
    XLSX.utils.sheet_add_json(ws, result, {
      origin: "A2",
      skipHeader: true,
    });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"], range: 5 };

    ws["A1"].style = {
      font: {
        name: "arial",
        sz: 24,
        bold: true,
        color: "#F2F2F2",
      },
    };

    var wscols = [
      { width: 40 },
      { width: 30 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 19 },
      { width: 20 },
      { width: 19 },
      { width: 20 },
      { width: 24 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];
    ws["!cols"] = wscols;

    const excelBuffer = XLSX.write(wb, {
      bookType: fileExtn,
      type: "array",
      cellStyles: true,
    });
    const finalData = new Blob([excelBuffer], { type: "xlsx" });
    FileSaver.saveAs(finalData, filename);

  }

  
  return (
    <>
      <PageTitle activeMenu="Revaluation" motherMenu="Preview" />
      <div className="card" style={{ padding: "40px", fontSize: "0.82rem" }}>
        <div className="card-header   justify-content-end">
          <i
            id="btn-export"
            onClick={(e) => fnExport("xlsx", "VendorQuotePreviewData.xlsx", e)}
            className="fa fa-cloud-download  mx-2 "
            style={{
              fontSize: "30px",
              color: "var(--primary)",
              cursor: "pointer",
            }}
            aria-hidden="true"
          ></i>
        </div>
        <div className="w-100 table-responsive">
          <div className="form-group">
            <table className="table shadow-hover text-nowrap text-center card-table border-no tbl-btn text-left text-black">
              <div style={{ height: "40vh", overflow: "auto" }}>
                <thead className="text-wrap">
                  <tr>
                    <th style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}>GENERIC</th>
                    <th style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}>DESCRIPTION</th>
                    <th style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}>VENDOR COMPANY</th>
                    <th style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}>MATERIAL CATEGORY</th>
                    <th style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}>CP+GST</th>
                    <th style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}>MRP</th>
                    <th style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}>MARGIN</th>
                    <th style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}>%</th>
                    <th style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}>CATEGORY</th>
                    <th style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}>APPROVAL STATUS</th>
                    <th style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}>EXPECTED PRICE</th>
                    <th style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}>Discounted Price</th>
                  </tr>
                </thead>
                <tbody className="text-wrap">
                  {location?.state?.map((newdata, index, arr) => {
                     let price = newdata?.discounted_price === 0 ? newdata?.cost_price : newdata?.discounted_price;
                     let cpGst =
                       Number(price) +
                       (Number(price) *
                         Number(newdata?.gst)) /
                       100;
                    let margin = Number(newdata?.mrp) - Number(cpGst);
                    let marginPercent =
                      (Number(cpGst) / Number(newdata?.mrp)) * 100;
                    marginPercent = 100 - marginPercent;
                    marginPercent = Math.round(marginPercent * 100) / 100;
                    margin = Math.round(margin * 100) / 100;
                    cpGst = Math.round(cpGst * 100) / 100;
                    if (arr[index - 1]?.generic !== newdata?.generic)
                      flag = false;
                    else flag = true;
                    colorClass = flag ? colorClass : !colorClass;
                    flag
                      ? (categoryLabel = categoryLabel + 1)
                      : (categoryLabel = 1);
                      newdata.quote_category = 'L' + categoryLabel;
                    return (
                      <tr
                        onClick={(e) => setHighlight(index)}
                        className={
                          colorClass
                            ? index === highlight ? "bg-primary text-white" : "text-light text-muted"
                            : index === highlight ? "bg-primary text-white" : "bg-light text-dark"
                        }
                      >
                        <>
                          <td style={{ maxWidth: "200px" }} >{newdata?.generic}</td>
                          <td className="">{newdata?.brand_name}</td>
                          <td className="text-center">{newdata?.company_name}</td>
                          <td className="text-center">
                            {newdata?.material_category}
                          </td>
                          <td className="text-center">{cpGst}</td>
                          <td className="text-center">{newdata?.mrp}</td>
                          <td className="text-center">{margin}</td>
                          <td className="text-center">{marginPercent}</td>
                          <td className="text-center">
                                {"L" + categoryLabel}
                              </td><td>
                            {newdata?.approval_status === 1 && "Approved" || newdata?.approval_status === -1 && "Rejected" || newdata?.approval_status === 0 && "Not selected"}
                          </td>
                          <td className="text-center">
                            {newdata?.expected_price}
                          </td>
                        </>
                        <td className="text-center">
                          {newdata?.discounted_price}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </div>
            </table>
          </div>
        </div>
        <div className="card-footer">
          <div className="col-md-12 d-flex justify-content-end">
            <button
              onClick={() => history.push(
                {
                  pathname: "/revaluation",
                  state: location?.state
                })}
              className="btn btn-warning mx-2"
            >
              BACK
            </button>

            <button onClick={submitHandle} className="btn btn-primary mx-2" disabled={isSubmit}>
              SUBMIT
            </button>
          </div>
          {error && <span style={{ fontSize: "20px" }} className="text-danger">*Unable to submit, please enter discounted price. </span>}
        </div>
      </div>
    </>
  );
}
