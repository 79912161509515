import {
  
    FORGOT_SUCESS_ACTION,
    FORGOT_FAILED_ACTION,
} from '../actions/forgotPassAction';



const initialState = {
    auth: {
        email: '',
        idToken: '',
        localId: '',
        expiresIn: '',
        refreshToken: '',
    },
    forgotErrorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function forgotReducer(state = initialState, action) {
    

    if (action.type === FORGOT_SUCESS_ACTION) { 
        
        return {
            ...state,
            auth: action.payload,
            forgotErrorMessage: action.payload,
            successMessage: 'Login Successfully Completed',
            showLoading: false,
        };
    }

    if (
       
        action.type === FORGOT_FAILED_ACTION
    ) {
        
        return {
            ...state,
            forgotErrorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }
    return state;
}

    
