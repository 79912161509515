import axios from "axios";
import swal from "sweetalert";
import {
  GET_USER_ROLES_ACTION,
  CREATE_USER_SUCCESS,
  CREATE_USER_ACTION,
  GET_USER_ACTION,
  DELETE_USER_ACTION,
  GET_SINGLEUSER_ACTION,
  GET_UEP_ACTION,
  USER_CREATE_OTP_SHOW,
  UPDATE_USER_ACTION,
} from "./UserTypes";

const getUsers = (users) => ({
  type: GET_USER_ACTION,
  payload: users,
});

export const loadUsers = () => {
  return function (dispatch) {
    axios
      .get("/user/getAllUser")
      .then((res) => {
        /*   console.log("getusers", res); */
        dispatch(getUsers(res.data.data));
      })
      .catch((error) => console.log(error));
  };
};

const getRoles = (userroles) => ({
  type: GET_USER_ROLES_ACTION,
  payload: userroles,
});

export const loadUserRoles = () => {
  return function (dispatch) {
    axios
      .get("/user/getAllUserRole")
      .then((res) => {
        /*   console.log("getusers", res); */

        dispatch(getRoles(res.data.data));
      })
      .catch((error) => console.log(error));
  };
};

const userDeleted = () => ({
  type: DELETE_USER_ACTION,
});
export const deleteUser = (id) => {
  return function (dispatch) {
    axios.delete(`/user/delete_user/${id}`).then((response) => {
      console.log(response);
      dispatch(userDeleted());
      dispatch(loadUsers());
      dispatch(loadUserRoles());
    });
  };
};

const userCreateOTPshow = (otpload) => ({
  type: USER_CREATE_OTP_SHOW,
  payload: otpload,
});

const userAdded = () => ({
  type: CREATE_USER_ACTION,
});

export const addUser = (user) => {
  return function (dispatch) {
    axios.post(`/user/create_user`, user).then((response) => {
      console.log(response);
      dispatch(userCreateOTPshow(response.data.data.mandatory_pwd_change));
      console.log(response.data.data.mandatory_pwd_change);
      dispatch(userAdded());
      dispatch(loadUsers());
      dispatch(loadUserRoles());
    });
  };
};

const userUpdated = () => ({
  type: UPDATE_USER_ACTION,
});

export const updateUser = (userup) => {
  return function (dispatch) {
    console.log(userup);
    axios.put(`/user/update_user`, userup).then((res) => {
      dispatch(userUpdated());
      dispatch(loadUsers());
      dispatch(loadUserRoles());
      console.log(res.data.message);

      if (res.data.message === "User Updated successfully") {
        swal(res.data.message, {
          icon: "success",
          timer: 2000,
        });
      } else {
        swal(res.data.message, {
          icon: "error",
          timer: 2000,
        });
      }
    });
  };
};

const getUser = (usersingle) => ({
  type: GET_SINGLEUSER_ACTION,
  payload: usersingle,
});

export const GetSingleUser = (id) => {
  return function (dispatch) {
    axios
      .get(`/user/getSingleUser/${id}`)
      .then((res) => {
        /*   console.log("getusers", res); */

        dispatch(getUser(res.data.data));
      })
      .catch((error) => console.log(error));
  };
};

const getUEPT = (UEPTD) => ({
  type: GET_UEP_ACTION,
  payload: UEPTD,
});

export const GETUEPTD = (FT) => {
  return function (dispatch) {
    dispatch(getUEPT(FT));
  };
};
/* export const GetUserAction = (users) => {
  return {
    type: GET_USER_ACTION,
    payload: users,
  };
}; */

/* export const CreateUserAction = (postData) => {
  return {
    type: CREATE_USER_ACTION,
    payload: postData,
  };
}; */

/* export const CreateUserSuccess = (response) => {
  return {
    type: CREATE_USER_SUCCESS,
    payload: response,
  };
}; */

/* export const GetUserRoles = (userroles) => {
  return {
    type: GET_USER_ROLES_ACTION,
    payload: userroles,
  };
};
 */
