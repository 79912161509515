import axios from "axios";
import { Carousel } from "react-bootstrap";

import { CreateUserSuccess } from "../actions/UserActions";
import {
  CREATE_USER_ACTION,
  CREATE_USER_SUCCESS,
  DELETE_USER_ACTION,
  GET_USER_ACTION,
  GET_USER_ROLES_ACTION,
  GET_SINGLEUSER_ACTION,
  GET_UEP_ACTION,
  USER_CREATE_OTP_SHOW,
} from "../actions/UserTypes";

/* const initialState = {
  users: [],
};

const initialRoles = {
  userroles: [],
};
 */
/* export const UserReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_USER_ACTION:
      return { ...state, users: payload };
    case CREATE_USER_ACTION:
      return { ...state, users: payload };
    default:
      return state;
  }
}; */

const intitialState = {
  users: [],
  user: {},
  userroles: [],
  loading: true,
  UEP: false,
  usershowOTP: [],
};

export const UserReducer = (state = intitialState, action) => {
  switch (action.type) {
    case GET_USER_ACTION:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case GET_USER_ROLES_ACTION:
      return {
        ...state,
        userroles: action.payload,
      };
    case DELETE_USER_ACTION:
    case CREATE_USER_ACTION:
      return {
        ...state,
        loading: false,
      };
    case GET_SINGLEUSER_ACTION:
      return {
        ...state,
        user: action.payload,
      };
    case USER_CREATE_OTP_SHOW:
      return {
        ...state,
        usershowOTP: action.payload,
      };
    case GET_UEP_ACTION:
      return {
        ...state,
        UEP: action.payload,
      };

    default:
      return state;
  }
};

/* export const UserrolesReducer = (state = initialRoles, { type, payload }) => {
  switch (type) {
    case GET_USER_ROLES_ACTION:
      return { ...state, userroles: payload };
    default:
      return state;
  }
};
 */
