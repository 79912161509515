import axios from "axios";
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import {
  addUser,
  CreateUserAction,
  GETUEPTD,
  loadUserRoles,
  loadUsers,
  updateUser,
} from "../../../../store/actions/UserActions";
import {
  CREATE_USER_ACTION,
  GET_USER_ACTION,
} from "../../../../store/actions/UserTypes";

function EditUserModal(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const ueptd = useSelector((state) => state.users.UEP);
  const userroles = useSelector((state) => state.users.userroles);
  const singleuserdata = useSelector((state) => state.users.user[0]);

  /*   useEffect(() => {
    setEditUserData(props.user);
  }, []); */

  const [modalCentered, setModalCentered] = useState(false);

  const [selectvalues, setSelectValues] = useState(userroles);
  const [activeStatus, setActiveStatus] = useState();
  const [editnewdata, setEditNewData] = useState([]);

  const localuserid = localStorage.getItem("userId");
  const updateStatusState = (editUserData) => {
    if ((editUserData = "active")) {
      setActiveStatus(true);
    } else {
      setActiveStatus(false);
    }
  };

  useEffect(() => {
    updateStatusState();
  }, []);

  const AddUserHandle = () => {
    swal("User Added", "", "success");
    setModalCentered(false);
  };

  /*  props.setEditUserData({
    ...props.editUserData[0],
    [e.target.name]: e.target.value,
  }); */

  const editUserChange = (e) => {
    props.setEditUserData({
      ...props.editUserData,
      [e.target.name]: e.target.value,
    });

    console.log({ [e.target.name]: e.target.value });
  };

  /*   const newuserChange = (e) => {
    console.log({ [e.target.name]: e.target.value });

    const newdata = {
      id: props.edituserid,
      [e.target.name]: e.target.value,
    };

    console.log(newdata);

    setEditNewData(newdata);
  }; */

  /*   console.log(editnewdata); */

  const statusactive = () => {
    const statusactive = {
      status: "ACTIVE",
    };

    axios
      .put(`/user/userStatusChange/${props.edituserid}`, statusactive)
      .then((response) => {
        dispatch(loadUsers());
        dispatch(loadUserRoles());
        props.setShowEditModal(false);

        swal("User Status Has Been Changed As ACTIVE ", {
          icon: "success",
          timer: 2000,
        });
      });
  };

  const statussuspend = () => {
    const statussuspended = {
      status: "SUSPENDED",
    };

    axios
      .put(`/user/userStatusChange/${props.edituserid}`, statussuspended)
      .then((response) => {
        props.setShowEditModal(false);
        dispatch(loadUsers());
        dispatch(loadUserRoles());
        swal("Your account has been suspended, please contact Admin. ", {
          icon: "success",
          timer: 2000,
        });
      });
  };

  /*   useEffect(() => {
    editUserChange();
  }, [suspendHandle]); */

  const EditUserHandle = (e) => {
    e.preventDefault();
    console.log(props.editUserData);

    /* props.setEditUserData({
      ...props.editUserData[0]
      
    }); */
    dispatch(updateUser(props.editUserData));

    console.log(editnewdata);

    props.setShowEditModal(false);
    console.log(props.editUserData);
  };

  /*  const userCreate = () => {
    props.setEditUserData({ created_by_user: "2" });
  }; */

  const closeButtonHandle = () => {
    setModalCentered(false);
    dispatch(GETUEPTD(false));
  };

  /*   useEffect(() => {
    fetchRoles();
  }, []); */

  console.log("editUserData", props.editUserData);

  return (
    <div>
      <div className="col-sm-6 col-md-12 d-flex justify-content-end mb-0">
        {/*      <button
          className="btn text-primary"
          onClick={() => setModalCentered(true)}
        >
          <i class="fa fa-user-plus fa-2x" aria-hidden="true"></i>
        </button> 
      </div>

      <div>
  {/*         <Button
          variant="primary"
          type="button"
          className="mb-2 mr-2"
          onClick={() => setModalCentered(true)}
        >
          Modal centered
        </Button> */}
        {/* <!-- Modal --> */}
        {/*         <React.Fragment>
          <button
            onClick={() => setModalCentered(true)}
            className="btn btn-warning shadow btn-xs sharp mr-1"
          >
            <i className="fa fa-pencil"></i>
          </button>
        </React.Fragment> */}

        <Modal
          className="fade"
          show={props.showeditmodal}
          onHide={props.closeEditModalButtonHandle}
        >
          <form onSubmit={EditUserHandle}>
            <Modal.Header>
              <h4 className="text-black">Edit User</h4>
              <Button
                onClick={props.closeEditModalButtonHandle}
                variant=""
                className="close"
              >
                <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>
              <div className="col-sm-6 col-md-12">
                <div className=" flex-wrap justify-content-around">
                  <div className="form-group  ">
                    <label htmlFor="">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      /*         value={editUserData[0].first_name} */
                      className="form-control"
                      value={props.editUserData?.first_name}
                      onChange={editUserChange}
                      name="first_name"
                      required
                    />

                    <input
                      type="hidden"
                      name="created_by_user"
                      value={localuserid}
                      required
                      onChange={editUserChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      value={props.editUserData?.last_name}
                      required
                      className="form-control"
                      onChange={editUserChange}
                      name="last_name"
                    />
                  </div>
                </div>
                <div className="flex-wrap justify-content-around">
                  <div className="form-group  ">
                    <label htmlFor="">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      value={props.editUserData?.email_id}
                      onChange={editUserChange}
                      name="email_id"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">
                      Phone Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      value={props.editUserData?.phone_number}
                      onChange={editUserChange}
                      name="phone_number"
                    />
                  </div>
                </div>
                <div>
                  <div className="">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1">
                        Select Role <span className="text-danger">*</span>
                      </label>

                      <select
                        class="form-control"
                        id="exampleFormControlSelect1"
                        name="user_role"
                        value={props.editUserData?.user_role}
                        onChange={editUserChange}
                        required
                      >
                        <option value="">Select Role</option>
                        {userroles?.map((role) => (
                          <option value={role.id}>{role.role_name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="col-md-12 d-flex justify-content-between">
                <div>
                  <Button
                    onClick={props.closeEditModalButtonHandle}
                    variant="danger light"
                  >
                    CLOSE
                  </Button>
                </div>

                <div className="">
                  {/* SUSPEND */}
                  {props.editUserData?.status === "suspended" ? (
                    <Button
                      className="mx-2"
                      variant="success text-white"
                      onClick={statusactive}
                    >
                      ACTIVE
                    </Button>
                  ) : (
                    <Button
                      className="mx-2"
                      variant="danger text-white"
                      onClick={statussuspend}
                    >
                      SUSPEND
                    </Button>
                  )}

                  <button
                    className="btn btn-warning text-white"
                    /*      onClick={EditUserHandle} */ type="submit"
                  >
                    UPDATE
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </div>
  );
}

export default EditUserModal;
