/// Menu
import MetisMenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link, useHistory, useLocation } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import profile from "../../../images/Untitled-1.jpg";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    //  this.mm("dispose");
    // console.log(this.mm);
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = (props) => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  const [dashboardType, setDashboardType] = useState("Dashboard");
  const [permissons, setPermissons] = useState({
    isDashboard: false,
    isUsers: false,
    isMaterial: false,
    isVendorMaster: false,
    isDrugMaster: false,
    isSettings: false,
    isGenerateBid: false,
  });
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }, []);

  useEffect(() => {
    // var btn = document.querySelector(".nav-control");
    // var aaa = document.querySelector("#main-wrapper");
    // function toggleFunc() {
    //   return aaa.classList.toggle("menu-toggle");
    // }
    // btn.addEventListener("click", toggleFunc);

    if (localStorage.getItem("userType")?.replace(/["]+/g, "") === "vendor") {
      if (localStorage.getItem("userType")?.replace(/["]+/g, "") === "vendor") {
        setDashboardType("Vendor");
      }
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("userType")?.replace(/["]+/g, "") === "vendor") {
      setDashboardType("Vendor");
    }
  }, []);

  /*  useEffect(() => {
    if (localStorage.getItem("userType")?.replace(/["]+/g, "") === "vendor") {
      setDashboardType("Vendor");
    }
     let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1]; 
  }); */

  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const sampleLocation = useLocation();
  const newurlpath = sampleLocation.pathname;
  const furlpath = newurlpath.split("/");
  const f1urlpath = furlpath[furlpath.length - 1];

  /// Active menu
  let deshBoard = [
      "",
      "my-wallets",
      "transactions",
      "coin-details",
      "portofolio",
      "market-capital",
    ],
    users = ["users"],
    vendorquote = ["preview", "vendor-quote"],
    settings = ["roles", "rolemanagment"],
    revaluation = ["revaluation", "preview"],
    app = [
      "app-profile",
      "post-details",
      "app-calender",
      "email-compose",
      "email-inbox",
      "email-read",
      // "ecom-product-grid",
      // "ecom-product-list",
      // "ecom-product-order",
      // "ecom-checkout",
      // "ecom-invoice",
      "ecom-customers",
      "post-details",
      "ecom-product-detail",
    ],
    email = ["email-compose", "email-inbox", "email-read"],
    shop = [
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "ecom-product-detail",
    ],
    charts = [
      "chart-rechart",
      "chart-flot",
      "chart-chartjs",
      "chart-chartist",
      "chart-sparkline",
      "chart-apexchart",
    ],
    bootstrap = [
      "ui-accordion",
      "ui-badge",
      "ui-alert",
      "ui-button",
      "ui-modal",
      "ui-button-group",
      "ui-list-group",
      "ui-media-object",
      "ui-card",
      "ui-carousel",
      "ui-dropdown",
      "ui-popover",
      "ui-progressbar",
      "ui-tab",
      "ui-typography",
      "ui-pagination",
      "ui-grid",
    ],
    plugins = [
      "uc-select2",
      "uc-nestable",
      "uc-sweetalert",
      "uc-toastr",
      "uc-noui-slider",
      "map-jqvmap",
      "uc-lightgallery",
    ],
    redux = ["todo", "form-redux", "form-redux-wizard"],
    widget = ["widget-basic"],
    forms = [
      "form-element",
      "form-wizard",
      "form-editor-summernote",
      "form-pickers",
      "form-validation-jquery",
    ],
    table = [
      "table-bootstrap-basic",
      "table-datatable-basic",
      "table-sorting",
      "table-filtering",
    ],
    pages = [
      "page-register",
      "page-login",
      "page-lock-screen",
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ],
    error = [
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ];
  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          {dashboardType === "Vendor" ? (
            <>
              <li
                className={`${
                  path === "vendor-dashboard"
                    ? "mm-active"
                    : path === "dashboard"
                    ? "mm-active"
                    : ""
                }`}
              >
                <Link className="" to="/vendor-dashboard">
                  {/*   <i className="flaticon-077-menu-1"></i> */}
                  <i class="fa fa-dashboard"></i>
                  <span className="nav-text">Dashboard</span>
                </Link>
              </li>
              {/* <li className={`${path === "vendormaster" ? "mm-active" : ""}`}>
         <Link className="" to="/vendormaster">
           <i class="fa fa-address-card" aria-hidden="true"></i>
           <span className="nav-text">Vendor Master</span>
         </Link>
       </li> */}
              {/* <li
                className={`${vendorquote.includes(path) ? "mm-active" : ""}`}
              >
                <Link className="" to="/vendor-quote">
                  <i className="flaticon-061-puzzle"></i>
                  <i class="fa fa-calculator" aria-hidden="true"></i>
                  <span className="nav-text">Vendor Quote</span>
                </Link>
              </li> */}
              <li className={`${path === "revaluation" ? "mm-active" : ""}`}>
                <Link className="" to="/revaluation">
                  {/*     <i className="flaticon-061-puzzle"></i> */}
                  <i class="fa fa-exchange" aria-hidden="true"></i>
                  <span className="nav-text">Revaluation</span>
                </Link>
              </li>
            </>
          ) : (
            <>
              <li className={`${path === "dashboard" ? "mm-active" : ""}`}>
                {localStorage.getItem("DashboardPermissons") ? (
                  <Link className="" to="/dashboard">
                    {/*   <i className="flaticon-077-menu-1"></i> */}
                    <i class="fa fa-dashboard"></i>
                    <span className="nav-text">Dashboard</span>
                  </Link>
                ) : (
                  ""
                )}
              </li>
              {/* <li className={`${path === "vendor-dashboard" ? "mm-active" : ""}`}>
            <Link className="" to="/vendor-dashboard">
            
              <i class="fa fa-dashboard"></i>
              <span className="nav-text">Vendor Dashboard</span>
            </Link>
          </li> */}
              <li className={`${path === "users" ? "mm-active" : ""}`}>
                {localStorage.getItem("UsersPermissons") ? (
                  <Link className="" to="/users">
                    {/*       <i className="flaticon-077-menu-1"></i> */}
                    <i class="fa fa-users" aria-hidden="true"></i>
                    <span className="nav-text">Users</span>
                  </Link>
                ) : (
                  ""
                )}
              </li>
              <li
                className={`${path === "material-category" ? "mm-active" : ""}`}
              >
                {localStorage.getItem("MaterialPermissons") ? (
                  <Link className="" to="/material-category">
                    <i class="fa fa-database" aria-hidden="true"></i>

                    <span className="nav-text">Material Category</span>
                  </Link>
                ) : (
                  ""
                )}
              </li>
              <li className={`${path === "vendormaster" ? "mm-active" : ""}`}>
                {localStorage.getItem("VendorMasterPermissons") ? (
                  <Link className="" to="/vendormaster">
                    <i class="fa fa-address-card" aria-hidden="true"></i>
                    <span className="nav-text">Vendor Master</span>
                  </Link>
                ) : (
                  ""
                )}
              </li>
              <li className={`${path === "drug-master" ? "mm-active" : ""}`}>
                {localStorage.getItem("DrugsMasterPermissons") ? (
                  <Link className="" to="/drug-master">
                    {/*   <i className="flaticon-061-puzzle"></i> */}
                    <i className="fa fa-medkit" aria-hidden="true"></i>
                    <span className="nav-text">Drugs Master</span>
                  </Link>
                ) : (
                  ""
                )}
              </li>
              <li className={`${path === "generate-bid" ? "mm-active" : ""}`}>
                {localStorage.getItem("GenerateBidPermissons") ? (
                  <Link className="" to="/generate-bid">
                    <i class="fa fa-gavel" aria-hidden="true"></i>
                    <span className="nav-text">Generate Bid</span>
                  </Link>
                ) : (
                  ""
                )}
              </li>
              <li className={`${settings.includes(path) ? "mm-active" : ""}`}>
                {localStorage.getItem("SettingPermissons") ? (
                  <Link className="" to="/rolemanagment">
                    {/*     <i className="flaticon-061-puzzle"></i> */}
                    <i class="fa fa-gear"></i>
                    <span className="nav-text">Settings</span>
                  </Link>
                ) : (
                  ""
                )}
              </li>
            </>
          )}
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
    mandatory_pwd_change: state.auth.auth.mandatory_pwd_change,
    userId: state.auth.auth.id,
    userEmail: state.auth.auth.email_id,
    emailError: state.forget.forgotErrorMessage,
    userPermission: state.auth.dataUser.permission,
  };
};

export default connect(mapStateToProps)(SideBar);
