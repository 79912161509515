import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import PostsReducer from "./reducers/PostsReducer";
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
import todoReducers from "./reducers/Reducers";
import { reducer as reduxFormReducer } from "redux-form";
import { UserReducer, UserrolesReducer } from "./reducers/UserReducer";
import { VendorReducer } from "./reducers/VendorReducer";
import { forgotReducer } from "./reducers/forgotReducer";
import { ChangePasswordReducer } from "./reducers/ChangePasswordReducer";
const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  posts: PostsReducer,
  auth: AuthReducer,
  todoReducers,
  /*   vendors: VendorReducer, */
  users: UserReducer,
  vendors: VendorReducer,
  forget: forgotReducer,
  changePassword: ChangePasswordReducer,
  /*   userroles: UserrolesReducer, */
  form: reduxFormReducer,
});

//const store = createStore(rootReducers);

export const store = createStore(reducers, composeEnhancers(middleware));
