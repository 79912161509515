import {
  CREATE_VENDOR_ACTION,
  DELETE_VENDOR_ACTION,
  GET_ALLVENDORS_ACTION,
  GET_VENDOR_ACTION,
  GET_VEP_ACTION,
} from "../actions/VendorTypes";

const intitialState = {
  vendors: [],
  vendor: {},
  loading: false,
  VEP: false,
};

export const VendorReducer = (state = intitialState, action) => {
  switch (action.type) {
    case GET_ALLVENDORS_ACTION:
      return {
        ...state,
        vendors: action.payload,
        loading: false,
      };
    case DELETE_VENDOR_ACTION:
    case CREATE_VENDOR_ACTION:
      return {
        ...state,
        loading: false,
      };
    case GET_VENDOR_ACTION:
      return {
        ...state,
        user: action.payload,
      };
    case GET_VEP_ACTION:
      return {
        ...state,
        VEP: action.payload,
      };

    default:
      return state;
  }
};
