import React, { Fragment, useRef, useState, useEffect } from "react";

import { Link } from "react-router-dom";

import PageTitle from "../../layouts/PageTitle";

import AddUserModal from "./components/AddUserModal";
import EditUserModal from "./components/EditUserModal";
import swal from "sweetalert";
import { styled } from "@mui/system";

import TablePaginationUnstyled from "@mui/base/TablePaginationUnstyled";

import "./Userscss.css";

/* import { UserData } from "./jsondata/UserData"; */
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import {
  deleteUser,
  GetSingleUser,
  GETUEPTD,
  GetUserAction,
  loadUserRoles,
  loadUsers,
} from "../../../store/actions/UserActions";
import axios from "axios";

import { Spinner } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
// import "./Css/tbl.css"
import Swal from "sweetalert2";
function Users_Index() {
  const dispatch = useDispatch();

  const users = useSelector((state) => state.users.users);
  const userroles = useSelector((state) => state.users.userroles);
  const ueptd = useSelector((state) => state.users.UEP);
  const loading = useSelector((state) => state.users.loading);

  const [editmodal, setEditModal] = useState(ueptd);

  const [userData, setUserData] = useState();

  /******************Pagination Code******************************************/
  const [currentPage, setCurrentPage] = useState(1);
  const [editmodalpopupshow, setEditModalPopupShow] = useState(false);
  const [postsPerPage, setPostsPerPage] = useState(5);
  const [data, setData] = useState(
    document.querySelectorAll("#patientTable_basic_table tbody tr")
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [editUserData, setEditUserData] = useState([{}]);
  console.log(editUserData.first_name);
  const [rows, setRows] = useState(5);
  const [showeditmodal, setShowEditModal] = useState(false);
  const [edituserid, setEditUserID] = useState({});

  const [searchResult, setSearchResult] = useState({});

  const closeEditModalButtonHandle = () => {
    setShowEditModal(false);
    /*     setEditUserID("");
    setEditUserData(""); */
  };

  useEffect(() => {
    dispatch(loadUsers());
    dispatch(loadUserRoles());
    setUserData(users);
  }, []);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sort = rows;
  const activePag = useRef(1);
  const [test, settest] = useState(0);

  function selectNum() {
    let selectedValue = document.getElementById("rowsSelect").value;
    setRows(selectedValue);
    // setPostsPerPage(selectedValue);
  }

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  const CustomTablePagination = styled(TablePaginationUnstyled)`
    & .MuiTablePaginationUnstyled-toolbar {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }

    & .MuiTablePaginationUnstyled-selectLabel {
      margin: 0;
    }

    & .MuiTablePaginationUnstyled-displayedRows {
      margin: 0;

      @media (min-width: 768px) {
        margin-left: auto;
      }
    }

    & .MuiTablePaginationUnstyled-spacer {
      display: none;
    }

    & .MuiTablePaginationUnstyled-actions {
      display: flex;
      gap: 0.25rem;
    }
  `;

  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#patientTable_basic_table tbody tr"));
    //chackboxFun();
  }, [test]);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };
  /**********************************************************/

  const Root = styled("div")`
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    td,
    th {
      border: 1px solid #ddd;
      text-align: left;
      padding: 8px;
    }

    th {
      background-color: #ddd;
    }
  `;

  useEffect(() => {
    // setUserData(users);
    dispatch(loadUsers());
    dispatch(loadUserRoles());
  }, []);

  const UserDeleteHandle = (user) => {
    swal({
      title: "Are You Sure ?",
      text: "You Want to Delete The User !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteUser(user.id));
        swal("User has been deleted!", {
          icon: "success",
          timer: 1000,
        });
      } else {
      }
    });
  };

  const UserEditHandle = (user) => {
    dispatch(GetSingleUser(user.id));
    dispatch(GETUEPTD(true));
  };

  // getsingleuseredit
  /*  const getSingleUserID = () => {
    const id = edituserid;

    axios.get(`/user/getSingleUser/${id}`).then((res) => {
      setEditUserData(res.data.data);
    });
  };

  useEffect(() => {
    if (edituserid) {
      getSingleUserID();
    }
  }, [showeditmodal]); */

  const [deleteValue, setDeleteValue] = useState();

  const showuserotp = (e) => {
    /*   const id = edituserid; */

    axios.get(`/vendor/viewOtp/${e}`).then((res) => {
      /*   setEditUserData(res.data.data); */

      const isotp = res.data.OTP;

      Swal.fire({
        icon: "success",
        html: `<h3> User OTP is ${isotp} </h3>`,
      });
    });
  };

  const lowersearch = searchResult?.result?.toString()?.toLowerCase()?.trim();

  const usersearchall = users
    ?.filter(
      (sv) =>
        sv.first_name?.toLowerCase()?.includes(lowersearch) ||
        sv.last_name?.toLowerCase()?.includes(lowersearch) ||
        sv.email_id?.toLowerCase()?.includes(lowersearch)
    )
    .map((sitem) => {
      return sitem;
    });

  const onSearchHandler = (e) => {
    console.log(e.target.value, e.target.value.length);
    setSearchResult({ result: e.target.value, length: e.target.value.length });
  };

  const getSingleUserID = (vas) => {
    /*   const id = edituserid; */
    console.log(vas.id);

    axios.get(`/user/getSingleUser/${vas.id}`).then((res) => {
      console.log(res.data.data[0]);

      setEditUserData(res.data.data[0]);
    });
  };

  /*   useEffect(() => {
    if (edituserid) {
      getSingleUserID();
    }
  }, [showeditmodal]); */

  return (
    <>
      <PageTitle activeMenu="Users" motherMenu="User" />

      {/*       <UsersNewList /> */}

      {loading ? (
        <div className="row ">
          <div className="col-md-12 d-flex justify-content-center ">
            <div className="card px-5 py-3 d-flex flex-row align-items-start  ">
              <Spinner animation="border" variant="primary" />
              <h3 class="text-primary ml-2 mb-0"> Loading...</h3>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <React.Fragment>
            <EditUserModal
              user={edituserid}
              closeEditModalButtonHandle={closeEditModalButtonHandle}
              showeditmodal={showeditmodal}
              setShowEditModal={setShowEditModal}
              edituserid={edituserid}
              setEditUserData={setEditUserData}
              editUserData={editUserData}
            />
          </React.Fragment>
          {/*  <PageTitle activeMenu="Users" motherMenu="Dashboard" /> */}

          <div className="col-md-12">
            {/* <h4 className="px-3">Users</h4> */}
            <div className="card ">
              <div className="card-header ">
                <input
                  className="form-control text-black"
                  type="text"
                  name=""
                  onChange={onSearchHandler}
                  placeholder="Search User"
                  id="searchInput"
                  /*       onKeyUp={mySearchFunction} */
                />
                <div className="d-flex">
                  <AddUserModal userroles={userroles} />
                </div>
              </div>
              <div className="card-body py-1">
                {users?.length === 0 ? (
                  <p style={{ paddingLeft: "20px" }}>
                    No users currently available
                  </p>
                ) : (
                  <div className="w-100 table-responsive">
                    <div
                      id="patientTable_basic_table"
                      className="dataTables_wrapper"
                    >
                      <table
                        className="table text-center shadow-hover card-table border-no tbl-btn text-black"
                        id="user_table"
                      >
                        <thead>
                          <th>Username</th>
                          <th>Email</th>
                          <th>Role</th>
                          <th>Status</th>
                          <th className="text-left ">Actions</th>
                        </thead>
                        <tbody>
                          {searchResult.length > 0 ? (
                            <>
                              {usersearchall.length === 0 ? (
                                <>
                                  <tr>
                                    <td colSpan={7}>
                                      <h6 className="text-danger">
                                        No Records Found
                                      </h6>
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <>{""}</>
                              )}
                            </>
                          ) : (
                            <></>
                          )}

                          {searchResult.length > 0 ? (
                            <>
                              {usersearchall?.map((uitem, i) => (
                                <React.Fragment>
                                  {usersearchall?.length > 0 ? (
                                    <>
                                      <tr>
                                        <td>
                                          {uitem.first_name +
                                            " " +
                                            uitem.last_name}
                                        </td>
                                        <td>{uitem.email_id}</td>
                                        <td>
                                          {userroles
                                            .filter(
                                              (rol) => rol.id == uitem.user_role
                                            )
                                            .map((users) => (
                                              <div>{users.role_name}</div>
                                            ))}
                                        </td>
                                        <td>
                                          <span className="badge light badge-success">
                                            <i className="fa fa-circle text-success mr-1"></i>
                                            <span
                                              style={{ fontfamily: "poppins" }}
                                            >
                                              {uitem.status.toUpperCase()}
                                            </span>
                                          </span>
                                        </td>

                                        <td className="d-flex justify-content-between">
                                          <div className="d-flex justify-content-end">
                                            <div>
                                              <button
                                                onClick={() => {
                                                  setShowEditModal(true);
                                                  setEditUserID(uitem);
                                                }}
                                                className="btn btn-warning shadow btn-xs sharp mr-1"
                                              >
                                                <i className="fa fa-pencil"></i>
                                              </button>

                                              <button
                                                onClick={() =>
                                                  UserDeleteHandle(uitem)
                                                }
                                                className="btn btn-danger shadow btn-xs sharp"
                                              >
                                                <i className="fa fa-trash"></i>
                                              </button>
                                            </div>

                                            <div>
                                              {uitem.mandatory_pwd_change ===
                                              "1" ? (
                                                <Tooltip
                                                  title="Show OTP"
                                                  placement="right"
                                                >
                                                  <button
                                                    className="btn btn-success shadow btn-xs text-white sharp mx-1 mr-1"
                                                    onClick={(e) => {
                                                      setEditUserID(uitem.id);
                                                      showuserotp(uitem.id);
                                                    }}
                                                  >
                                                    <i
                                                      class="fa fa-eye"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </button>
                                                </Tooltip>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </React.Fragment>
                              ))}
                            </>
                          ) : (
                            <>
                              {(rowsPerPage > 0
                                ? users.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                : users
                              ).map((vas) => (
                                <tr className="" key={vas.id}>
                                  <td>
                                    {vas.first_name + " " + vas.last_name}
                                  </td>
                                  <td>{vas.email_id}</td>

                                  <td>
                                    {/*       {user.user_role} */}
                                    {userroles
                                      .filter((rol) => rol.id == vas.user_role)
                                      .map((users) => (
                                        <div>{users.role_name}</div>
                                      ))}
                                  </td>

                                  <td className="mr-3">
                                    {vas.status === "active" ? (
                                      <span className="badge light badge-success">
                                        <i className="fa fa-circle text-success mr-1"></i>
                                        <span style={{ fontfamily: "poppins" }}>
                                          {vas.status.toUpperCase()}
                                        </span>
                                      </span>
                                    ) : (
                                      <span className="badge light badge-danger">
                                        <i className="fa fa-circle text-danger mr-1 "></i>
                                        <span>{vas.status.toUpperCase()}</span>
                                      </span>
                                    )}
                                  </td>
                                  <td className="d-flex justify-content-between">
                                    <div className="d-flex justify-content-end">
                                      <div>
                                        <button
                                          onClick={(e) => {
                                            getSingleUserID(vas);
                                            setShowEditModal(true);
                                            setEditUserID(vas.id);
                                          }}
                                          className="btn btn-warning shadow btn-xs sharp mr-1"
                                        >
                                          <i className="fa fa-pencil"></i>
                                        </button>

                                        <button
                                          onClick={() => UserDeleteHandle(vas)}
                                          className="btn btn-danger shadow btn-xs sharp"
                                        >
                                          <i className="fa fa-trash"></i>
                                        </button>
                                      </div>

                                      <div>
                                        {vas.mandatory_pwd_change === "1" ? (
                                          <Tooltip
                                            title="Show OTP"
                                            placement="right"
                                          >
                                            <button
                                              className="btn btn-success shadow btn-xs text-white sharp mx-1 mr-1"
                                              onClick={(e) => {
                                                setEditUserID(vas.id);
                                                showuserotp(vas.id);
                                              }}
                                            >
                                              <i
                                                class="fa fa-eye"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </Tooltip>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}

                          {emptyRows > 0 && (
                            <tr style={{ height: 41 * emptyRows }}>
                              <td colSpan={5} />
                            </tr>
                          )}
                        </tbody>

                        <tfoot>
                          <tr>
                            <CustomTablePagination
                              rowsPerPageOptions={[
                                5,
                                10,
                                25,
                                { label: "All", value: -1 },
                              ]}
                              colSpan={5}
                              count={users?.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              componentsProps={{
                                select: {
                                  "aria-label": "rows per page",
                                },
                                actions: {
                                  showFirstButton: true,
                                  showLastButton: true,
                                },
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                          </tr>
                        </tfoot>
                      </table>
                      <div id="error" className="text-center"></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Users_Index;
