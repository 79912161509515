import { useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import React from "react";
import Checkbox from "./Checkbox";
import swal from "sweetalert";

export default function AdminRevaluation() {
  return (
    <div>
      <PageTitle activeMenu="Revaluation" motherMenu="AdminRevaluation" />
      <Body />
    </div>
  );
}

function Body() {
  const location = useLocation();
  console.log("bidId", location?.state?.bidId);
  console.log("vendorId", location?.state?.vendorId);
  const gstData = [0, 5, 15, 28];
  const [data, setData] = useState([]);
  const [expectedValue, setExpectedValue] = useState([]);
  const history = useHistory();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [highlight, setHighlight] = useState(null);
  // const vendor_user_id = location?.state?.bidId;
  // const gen_bid_id =location?.state?.vendorId;

  console.log("backtoadmin", location?.state?.AdminSelectedItems);

  const backisvalues = location?.state?.AdminSelectedItems?.map((val) => {
    return val.id;
  });
  console.log("selected values only", backisvalues);

  useEffect(() => {
    setIsCheck([]);
    axios
      .get(
        `/bid/getQuote/${location?.state?.bidId}/${location?.state?.vendorId}`
      )
      .then((res) => {
        console.log("VendorBidData", res.data.data);
        // res?.data?.data.map((val)=>{
        //     if(val?.status)
        // })
        setData(res?.data?.data);
        if (backisvalues?.length > 0) {
          setIsCheck(backisvalues?.map((val) => val.toString()));
        }
      });
  }, []);

  let colorCode = null;
  let flag = true;
  let colorClass = true;
  let categoryLabel = 1;

  let SubmitData = data?.filter((val, i) => {
    return val?.status === "1" && val?.approval_status === 0;
  });

  console.log("Submitted", SubmitData);

  // data?.map((val) => {
  //     if (val.approval_status === 1) {
  //         val.approval_status = "Approved"
  //     }
  //     else if (val.approval_status === -1) {
  //         val.approval_status = "Rejected"
  //     }
  //     else if (val.approved_status === 0) {
  //         val.approval_status = "Not Selected"
  //     }
  // })

  console.log(data);
  //     useEffect(()=>{
  // setIsCheck(backisvalues.map(liv => liv.toString()));
  //     },[])

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(SubmitData?.map((li) => li?.id.toString()));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;

    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck?.filter((item) => item !== id.toString()));
    }
  };

  console.log(isCheck);
  // var check = [5,6]
  var range = [];
  for (var i = 0; i < isCheck?.length; i++) {
    range.push(parseInt(isCheck[i]));
  }

  var selectedItems = [];
  data?.map((val) => {
    range?.map((item) => {
      if (item === val.id) {
        selectedItems.push(val);
        console.log(item);
      }
    });
  });

  console.log("selectedItems", selectedItems);

  const handlePreview = () => {
    console.log("checked", isCheck);
    if (isCheck?.length < 1) {
      swal("Please select atleast one generic", "", "warning");
    }

    selectedItems?.map((val, i) => {
      if (val?.expected_price === "") {
        swal({
          title: "Please enter your expected price ",
          // text: "You clicked the button!",
          icon: "error",
          button: "Okay!",
        });
      } else if (val?.expected_price === 0) {
        swal({
          title: "Expected price should not be a Empty Or Zero ",
          // text: "You clicked the button!",
          icon: "warning",
          button: "Okay!",
        });
      } else {
        history.push({
          pathname: "/admin-preview-revalution",
          state: {
            bidId: location?.state?.bidId,
            vendorId: location?.state?.vendorId,
            SelectedItem: selectedItems,
          },
        });
      }
    });
  };

  var validNumber = new RegExp(/^\d*\.?\d*$/);

  return (
    <div className="card" style={{ padding: "40px", fontSize: "0.82rem" }}>
      <div className="w-100 table-responsive">
        <div className="form-group">
          <table className="table shadow-hover card-table border-no tbl-btn text-nowrap text-black">
            <div style={{ height: "50vh", overflow: "auto" }}>
              <thead className="text-wrap">
                <tr>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    <Checkbox
                      type="checkbox"
                      name="selectAll"
                      id="selectAll"
                      handleClick={handleSelectAll}
                      isChecked={
                        isCheck.length === SubmitData.length ? true : false
                      }
                    />
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    GENERIC
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    DESCRIPTION
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    VENDOR COMPANY
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    MATERIAL CATEGORY
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    CP+GST
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    MRP
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    MARGIN
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    %
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    CATEGORY
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    PRIORITY
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    REMARK
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    APPROVAL STATUS
                  </th>
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    EXPECTED PRICE
                  </th>
                </tr>
              </thead>
              <tbody className="text-wrap">
                {data?.length === 0 ? (
                  <tr>
                    <td className="text-danger" colSpan={10}>
                      No records are there to revaluate
                    </td>
                  </tr>
                ) : (
                  data?.map((newdata, index, arr) => {
                    if (newdata?.status === "0") {
                      return <></>;
                    }
                    if (newdata?.approval_status === 1) {
                      return <></>;
                    }
                    let price =
                      newdata?.discounted_price === 0
                        ? newdata?.cost_price
                        : newdata?.discounted_price;
                    let cpGst =
                      Number(price) +
                      (Number(price) * Number(newdata?.gst)) / 100;
                    let margin = Number(newdata?.mrp) - Number(cpGst);
                    let marginPercent =
                      (Number(cpGst) / Number(newdata?.mrp)) * 100;
                    marginPercent = 100 - marginPercent;
                    marginPercent = Math.round(marginPercent * 100) / 100;
                    margin = Math.round(margin * 100) / 100;
                    cpGst = Math.round(cpGst * 100) / 100;
                    if (arr[index - 1]?.generic !== newdata?.generic)
                      flag = false;
                    else flag = true;
                    colorClass = flag ? colorClass : !colorClass;
                    flag
                      ? (categoryLabel = categoryLabel + 1)
                      : (categoryLabel = 1);
                    return (
                      <tr
                        onClick={(e) => setHighlight(index)}
                        className={
                          colorClass
                            ? index === highlight
                              ? "bg-primary text-white"
                              : "text-light text-muted"
                            : index === highlight
                            ? "bg-primary text-white"
                            : "bg-light text-dark"
                        }
                      >
                        <td>
                          <Checkbox
                            key={newdata?.id}
                            type="checkbox"
                            name={newdata?.generic}
                            id={newdata?.id?.toString()}
                            handleClick={handleClick}
                            isChecked={isCheck?.includes(
                              newdata?.id.toString()
                            )}
                          />
                        </td>
                        <>
                          <td style={{ maxWidth: "200px" }}>
                            {newdata?.generic}
                          </td>
                          <td className="">{newdata?.brand_name}</td>
                          <td className="text-center">
                            {newdata?.company_name}
                          </td>
                          <td className="text-center">
                            {newdata?.material_category}
                          </td>
                          <td className="text-center">{cpGst}</td>
                          <td className="text-center">{newdata?.mrp}</td>
                          <td className="text-center">{margin}</td>
                          <td className="text-center">{marginPercent}</td>
                          <td className="text-center">{"L" + categoryLabel}</td>
                          <td>
                            {newdata?.quote_priority === null ? "" : "P"}
                            {newdata?.quote_priority ?? "No priority set."}
                          </td>
                          <td>
                            {newdata?.quote_priority === -1
                              ? "No remarks added."
                              : newdata?.quote_remark ?? "No remarks added."}
                          </td>
                          <td>
                            {(newdata?.approval_status === 1 && "Approved") ||
                              (newdata?.approval_status === -1 && "Rejected") ||
                              (newdata?.approval_status === 0 &&
                                "Not selected")}
                          </td>
                        </>

                        <td>
                          <input
                            name="expected_price"
                            type="number"
                            value={newdata?.expected_price}
                            onChange={(e) => {
                              setData((prev) => {
                                let temp = [...prev];
                                temp[index].expected_price = e.target.value;
                                return temp;
                              });
                            }}
                            onBlur={async (e) => {
                              if (cpGst < e.target.value) {
                                swal(
                                  "Value was not updated. Please ensure that expected price cannot be more than cost price + GST.",
                                  { icon: "error", button: "Okay" }
                                ).then((okay) => {
                                  history.go(0);
                                });
                                return;
                              }
                              const res = await axios.put(
                                "/revaluation/updateRevaluationAdmin",
                                {
                                  vendor_id: newdata?.id,
                                  expected_price: e.target.value,
                                }
                              );
                              console.log("record updated", res);
                            }}
                            className="form-control"
                            // onKeyPress={(event) => {
                            //     if (/^\d*\.?\d*$/.test(event.key)) {
                            //         event.preventDefault();
                            //     }
                            // }}
                          />
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </div>
          </table>
        </div>
      </div>
      <div className="col-md-12 d-flex justify-content-end mt-4">
        <button
          className="btn btn-warning text-white mx-2"
          onClick={(e) => {
            history.push({
              pathname: "/viewQuotes",
              state: location?.state?.bidId,
            });
          }}
        >
          BACK
        </button>
        {SubmitData.length !== 0 ? (
          <button
            className="btn btn-primary text-white mx-2"
            onClick={(e) => handlePreview(e)}
          >
            PREVIEW
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
