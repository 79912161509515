import axios from "axios";
import React, { useEffect } from "react";
import IndexTab from "../zenix/Home/IndexTab";

function VendorDashboard() {
  return (
    <div>
      <div className="col-xl-12">
        <IndexTab vendorOnly={true}/>
      </div>

      <div></div>
    </div>
  );
}

export default VendorDashboard;
