import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";
import axios from "axios";
//
// import logo from "../../images/logo.png";
// import textlogo from "../../images/logo-text.png";
import login from "../../images/bg-login2.png";
import loginbg from "../../images/bg-login.jpg";
import logo1 from "../../images/logo-full.png";
import ChangePassword from "./ChangePassword";
import ForgotPassword from "./ForgotPassword";

function Login(props) {
  const [email, setEmail] = useState("");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const [otp, setOTP] = useState("");

  const [value, setValue] = useState("123456");

  const [newPassword, setNewPassword] = useState("");
  const [confirm_password, setConfirm] = useState("");

  const [hideChangePass, setHideChangePass] = useState(false);
  const [hideLoginPage, setHideLogin] = useState(true);
  const [hideForgotPass, setHideForgotPass] = useState(false);
  const defaultValue = {
    password: "",
    confirm_password: "",
  };

const [hidePassword,setHidepassword] =useState("")
const [hideError,setHideError] =useState(false)
const showPassword =()=>{
  var x = document.getElementById("input-password");
  if (x.type === "password") {
    x.type = "text";
  } else {
    x.type = "password";
  }
  setHidepassword(!hidePassword);
}
  useEffect(() => {
    console.log("window",window.location.href.indexOf("change-password") > -1)
    if(window.location.href.indexOf("change-password") > -1){
      setHideChangePass(true)  
      // window.location.reload();
    }else if( window.location.href.indexOf("forgot-password") > -1){
      setHideForgotPass(true)
    }

  }, [ ]);
 
  const handleSubmit = (values) => {
    console.log(values.password, "Password is");
    console.log(props.userId);
    const data = {
      password: values.password,
    };
    axios
      .put(
        `/user/changePassword/${props.userId}`,
        data
      )
      .then((response) => {
        console.log(response.data);
        let flag = response.data.message;
        switch (flag) {
          case "User password updated successfully":
            localStorage.removeItem("id");
            console.log("Password changed successfully");
            dispatch(loginAction(props.userEmail, password, props.history));
            break;

          default:
            return "";
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const redirectForgotPassword =() =>{
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  const dispatch = useDispatch();

  function onLogin(e) {
    console.log("email", "password", email, password);
    ;
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(loadingToggleAction(true));
    dispatch(loginAction(email, password, props.history));
    setTimeout(() => {
      setHideError(true);    
    }, 10000)
    setTimeout(() => {
      setHideError(false);   
    }, 1000)
   
  }

  return (
    <> 
    {hideChangePass ? <ChangePassword/> : hideForgotPass ? <ForgotPassword/> :
    <div
      className="login-main-page"
      style={{ backgroundImage: "url(" + loginbg + ")" }}
    >
      <div className="login-wrapper">
        <div
          className="login-aside-left"
          style={{ backgroundImage: "url(" + login + ")" }}
        >
          <Link className="login-logo">
            <img src={logo1} alt="logo" />
          </Link>
          <div className="login-description">
            <h2 className="text-black  mb-2">Lorem Ipsum</h2>
            <p className="fs-12 text-dark">
              {/* Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also th */}
            </p>
          </div>
        </div>

        <div
          className="login-aside-right"
          id="loginpage"
          hidden={!hideLoginPage}
        >
          <div className="row m-0 justify-content-center h-100 align-items-center">
            <div className="col-xl-7 col-xxl-7">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form-1">
                      <div className="mb-4">
                        <h3 className="text-white mb-1">Welcome to Rainbow</h3>
                        <p className="">
                          Sign in by entering information below
                        </p>
                      </div>
                      { props.errorMessage && (
                        hideError === false ?
                          <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2" style={{backgroundColor:"red",color:"aliceblue"}}>
                          {props.errorMessage ===   "Your account has been suspended, please contact Admin" ?  "Your account has been suspended, please contact Admin" : "Invalid Email-Id or Password"}
                        </div>  
                         :""
                          ) }
                      {props.successMessage && (
                        <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                          {props.successMessage}
                        </div>
                      )}

                      <form onSubmit={onLogin}>
                        <div className="form-group mb-3">
                          <label className="mb-2 ">
                            <strong>Email</strong>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {errors.email && (
                            <div className="text-danger fs-12">
                              {"Please enter your Email-Id"}
                            </div>
                          )}
                        </div>
                        <div className="form-group mb-3">
                          <label className="mb-2 ">
                            <strong>Password</strong>
                          </label>
                          <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                          <input style={{paddingRight:"30px"}}
                          id="input-password"
                            type="password"
                            className="form-control"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          {hidePassword ?  <i className="fa fa-eye" onClick={showPassword} style={{marginRight:"10px",position:"absolute",right:"0px",color:"#6610f2",cursor:"pointer"}}></i>:
                          
                           <i class="fa fa-eye-slash" onClick={showPassword} style={{marginRight:"10px",position:"absolute",right:"0px",color:"#6610f2",cursor:"pointer"}} aria-hidden="true"></i>}
                           </div>
                          {/* {errors.value && (
                            <div className="text-danger fs-12">
                              {errors.value}
                            </div>
                          )} */}
                          {errors.password && (
                            <div className="text-danger fs-12">
                              {"Please enter your Password"}
                            </div>
                          )}
                          {/* {errors.otp && (
                            <div className="text-danger fs-12">
                              {errors.otp}
                            </div>
                          )} */}
                        </div>
                        <div className="form-row d-flex justify-content-between mt-4 mb-2">
                          <div className="form-group">
                            <div className="custom-control custom-checkbox ml-1 "  style={{cursor:"pointer"}}>
                              <input
                                type="checkbox"
                                className="custom-control-input mr-2"
                                id="basic_checkbox_1"
                                style={{cursor:"pointer"}}
                              />
                             
                              <label
                                className="custom-control-label"
                                htmlFor="basic_checkbox_1"
                                style={{cursor:"pointer"}}
                              >
                                Remember me
                              </label>
                            </div>
                          </div>
                          <div className="form-group">
                             <Link to="/forgot-password">
                              <p onClick={redirectForgotPassword}  style={{cursor:"pointer"}}
                              >
                                Forgot Password?
                              </p>
                              </Link>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-warning text-white btn-block"
                          >
                            Sign In
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="login-aside-right"
          id="Changepage"
          hidden={!hideChangePass}
        >
          <ChangePassword />
        </div>
      </div>
    </div>
}
</>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
    mandatory_pwd_change: state.auth.auth.mandatory_pwd_change,
    userId: state.auth.auth.id,
    userEmail: state.auth.auth.email_id,
  };
};
export default connect(mapStateToProps)(Login);
