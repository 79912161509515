import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { loginAction } from "../../store/actions/AuthActions";
import axios from "axios";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { Link, useHistory } from "react-router-dom";
import login from "../../images/bg-login2.png";
import loginbg from "../../images/bg-login.jpg";
import logo1 from "../../images/logo-full.png";

function ChangePassword(props) {
  const history = useHistory();

  useEffect(() => {
    if (window.location.href.indexOf("login") > -1) {
      localStorage.removeItem("userEmail");
      localStorage.removeItem("userType");
    }
  });
  const [locationKeys, setLocationKeys] = useState([]);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("userType");
        window.location.assign(`/login`);
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);

          // Handle back event
        }
      }
    });
  }, [locationKeys]);

  let errorsObj = { newpassword: "", confirm_password: "" };
  const [hidePassword, setHidepassword] = useState("");
  const [hidePassword2, setHidepassword2] = useState("");
  const showPassword = () => {
    var x = document.getElementById("input-password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
    setHidepassword(!hidePassword);
  };
  const showPassword2 = () => {
    var x = document.getElementById("input-password2");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
    setHidepassword2(!hidePassword2);
  };
  const dispatch = useDispatch();

  const defaultValue = {
    password: "",
    confirm_password: "",
  };

  const validationSchema = yup.object().shape({
    password: yup.string().required("Password is required"),
    confirm_password: yup
      .string()
      .required("COnfirm password is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  // const [status, setStatus] = useState("")
  // const [id, setId] = useState('');

  // const checkLogin = (status) => {

  // }
  let userId = localStorage.getItem("id");

  const clickBack = () => {
    window.location.assign(`/login`);
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userType");
    localStorage.clear();
  };

  const handleSubmit = (values) => {
    const data = {
      password: values.password,
    };
    axios
      .put(
        `/user/changePassword/${localStorage.getItem(
          "userId"
        )}`,
        data
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.message === "User Password Updated successfully") {
          setTimeout(() => {
            dispatch(
              loginAction(
                localStorage.getItem("userEmail"),
                data.password,
                props.history
              )
            );
            history.push("/dashboard");
          }, 500);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div
      className="login-main-page"
      style={{ backgroundImage: "url(" + loginbg + ")" }}
    >
      <div className="login-wrapper">
        <div
          className="login-aside-left"
          style={{ backgroundImage: "url(" + login + ")" }}
        >
          <Link className="login-logo">
            <img src={logo1} alt="logo" />
          </Link>
          <div className="login-description"></div>
        </div>

        <div className="login-aside-right" id="loginpage">
          <i
            className="fa fa-arrow-left"
            aria-hidden="true"
            onClick={clickBack}
            style={{
              marginTop: "5%",
              marginLeft: "10%",
              fontSize: "20px",
              cursor: "pointer",
            }}
          ></i>
          <div className="row m-0 justify-content-center h-100 align-items-center">
            <div className="col-xl-7 col-xxl-7">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form-1">
                      <div className="mb-4">
                        <h3 className="text-white mb-1">Welcome to Rainbow</h3>
                        <p className="">
                          Sign in by entering information below
                        </p>
                      </div>
                      <Formik
                        initialValues={defaultValue}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                      >
                        {({ errors, touched }) => (
                          <Form>
                            <div className="form-group mb-3">
                              <label className="mb-2 ">
                                <strong>New Password</strong>
                              </label>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Field
                                  type="password"
                                  name="password"
                                  className="form-control"
                                  id="input-password"
                                  // value={newpassword}
                                  // onChange={(e) => setNewPassword(e.target.value)}
                                />
                                {hidePassword ? (
                                  <i
                                    className="fa fa-eye"
                                    onClick={showPassword}
                                    style={{
                                      marginRight: "20px",
                                      position: "absolute",
                                      right: "0px",
                                      color: "#6610f2",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    class="fa fa-eye-slash"
                                    onClick={showPassword}
                                    style={{
                                      marginRight: "20px",
                                      position: "absolute",
                                      right: "0px",
                                      color: "#6610f2",
                                      cursor: "pointer",
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </div>
                              {errors.password && touched.password ? (
                                <div className="text-danger">
                                  {"Please Enter Password"}
                                </div>
                              ) : null}
                            </div>

                            <div className="form-group mb-3">
                              <label className="mb-2 ">
                                <strong>Confirm Password</strong>
                              </label>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Field
                                  type="password"
                                  name="confirm_password"
                                  className="form-control"
                                  id="input-password2"
                                  // value={confirm_password}
                                  // onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                {hidePassword2 ? (
                                  <i
                                    className="fa fa-eye"
                                    onClick={showPassword2}
                                    style={{
                                      marginRight: "20px",
                                      position: "absolute",
                                      right: "0px",
                                      color: "#6610f2",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    class="fa fa-eye-slash"
                                    onClick={showPassword2}
                                    style={{
                                      marginRight: "20px",
                                      position: "absolute",
                                      right: "0px",
                                      color: "#6610f2",
                                      cursor: "pointer",
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </div>
                              {errors.confirm_password &&
                              touched.confirm_password ? (
                                <div className="text-danger">
                                  {
                                    "Please enter Confirm Password same as New Password"
                                  }
                                </div>
                              ) : null}
                              {/* <ErrorMessage name="confirm_password" /> */}
                            </div>
                            <div className="d-flex justify-content-center">
                              <button
                                type="submit"
                                className="btn btn-warning text-white btn-block"
                              >
                                Login
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
    mandatory_pwd_change: state.auth.auth.mandatory_pwd_change,
    userId: state.auth.auth.id,
    userEmail: state.auth.auth.email_id,
  };
};

export default connect(mapStateToProps)(ChangePassword);
