import axios from "axios";
import { setDate } from "date-fns";
import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle";
// import { Slider } from "material-ui";
import SelectSearch from "./SelectSearch";
import { FormControl, MenuItem, InputLabel, Select } from "@mui/material";
import { makeStyles, useTheme } from "@material-ui/core/styles";
export default function VendorQuote() {
  const [selected, setSelected] = useState([]);
  return (
    <div>
      <PageTitle activeMenu="Vendor Quote" motherMenu="Quotes" />

      <Header />
      <Footer />
      <Body />
    </div>
  );
}

function Header() {
  useEffect(() => {
    getHeaderData().then((res) => {
      console.log(res);
      setCompanyName(res?.data?.data?.[0]?.company_name);
      setContactName(res?.data?.data?.[0]?.contact_person_1);
      setContactPhone(res?.data?.data?.[0]?.contact_person_1_phone);
      setDateCreated(res?.data?.data?.[0]?.created_at);
    });
  }, []);

  async function getHeaderData() {
    const userId = localStorage.getItem("userId");
    const res = await axios.get(`/vendor/getSingleVendor/${userId}`);
    return res;
  }
  const [companyName, setCompanyName] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [dateCreated, setDateCreated] = useState("");
  return (
    <div className="card" style={{ padding: "40px", fontSize: "0.82rem" }}>
      <div className="row text-black">
        <div className="col-sm-6 ">
          <p>Company Name: {companyName}</p>
        </div>
        <div className="col-sm-6">
          <p>Contact Person: {contactName}</p>
        </div>
      </div>
      <div className="row text-black">
        <div className="col-sm-6">
          <p>Date of bidding: {new Date(dateCreated)?.toDateString()}</p>
        </div>
        <div className="col-sm-6">
          <p>Contact Number: {contactPhone}</p>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    width: 250,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

function Body() {
  const [companyName, setCompanyName] = useState("");
  const gstData = ["Not selected", "0", "5", "15", "28"];
  const [quoteData, setQuoteData] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const [searchError, setSearchError] = useState(false);
  const classes = useStyles();
  const [materialSelected, setMaterialSelected] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  useEffect(() => {
    getQuoteData().then((res) => {
      console.log(res);
      setDefaultCompanyName(res?.data);
      setQuoteData(res?.data);
      console.log(res?.data);
      setSelectedValue([...Array(res?.data.length)].map((row) => null));
      getBrandNames(res?.data).then((result) => {
        const temp = [...res?.data];
        temp?.map((row, index) => {
          let sum = 0;
          result?.[index]?.data?.data.forEach((element) => {
            sum = sum + Number(element?.twelve_month_purchase);
          });
          row.annual_volume = sum;
          return row;
        });
        setQuoteData([...temp]);
        setBrandList(result);
      });
    });
  }, []);

  useEffect(() => {
    getMaterialList().then((res) => {
      console.log(res);
      setMaterialList(res);
    });
  }, []);

  async function getHeaderData() {
    const userId = localStorage.getItem("userId");
    const res = await axios.get(`/vendor/getSingleVendor/${userId}`);
    return res;
  }

  async function getQuoteData() {
    const userId = localStorage.getItem("userId");
    const res = await axios.get(`/bid/getQuote/${location?.state}/${userId}`);
    return res?.data;
  }

  function setDefaultCompanyName(data) {
    getHeaderData().then((res) => {
      setCompanyName(res?.data?.data?.[0]?.company_name);
      const temp = [...data];
      console.log(temp);
      temp.forEach((element) => {
        if (element.company_name === null)
          element.company_name = res?.data?.data?.[0]?.company_name;
      });
      console.log("Data", temp);
      setQuoteData([...temp]);
    });
  }

  async function getBrandNames(data) {
    const temp = data?.map(
      async (val) =>
        (val.brandList = await axios.post(`/drug/getBrandName`, {
          genric: val?.generic,
          mat_id: val?.material_category_id,
        }))
    );
    return Promise.all(temp);
  }

  async function handleFocusOut(id, index) {
    //console.log(id)
    console.log(selectedValue[index]?.brandName?.brand_name);
    const findObject = quoteData?.find((val) => val?.id === id);
    //console.log(temp)
    const userId = localStorage.getItem("userId");
    // let company_name = localStorage.getItem('companyName');
    const temp = {
      data: [
        {
          drug_id: findObject?.drug_id,
          annual_volume: findObject?.annual_volume ?? 0,
          material_category_id: findObject?.material_category_id,
          brand_name:
            selectedValue?.[index]?.brandName?.brand_name === "Not selected"
              ? ""
              : selectedValue?.[index]?.brandName?.brand_name ??
                findObject?.brand_name ??
                "",
          cost_price: findObject?.cost_price ?? "",
          gst: findObject?.gst === "Not selected" ? "" : findObject?.gst ?? "",
          mrp: findObject?.mrp ?? "",
          hsn: findObject?.hsn ?? "",
          company_name: findObject?.company_name ?? companyName,
          vendor_user_id: userId,
        },
      ],
      vender_quote_id: findObject?.id ?? "",
      vendor_user_id: userId,
    };
    console.log(temp);
    if (
      temp.data?.[0]?.cost_price === "" ||
      temp.data?.[0]?.mrp === "" ||
      temp.data?.[0]?.gst === ""
    ) {
      const res = await axios.post("/vendor_quote/create_vendorQuote", temp);
      return res;
    }
    let cpGst =
      Number(temp.data?.[0]?.cost_price) +
      (Number(temp.data?.[0]?.cost_price) * Number(temp.data?.[0]?.gst)) / 100;
    console.log(cpGst);
    console.log(temp.data?.[0]?.mrp);
    if (cpGst > temp.data?.[0]?.mrp) {
      swal(
        "Value was not updated. Please ensure that cost price + GST cannot be more than MRP.",
        { icon: "error", button: "Okay" }
      ).then((okay) => {
        history.go(0);
      });
      return;
    }
    const res = await axios.post("/vendor_quote/create_vendorQuote", temp);
    return res;
  }

  async function inputValidation() {
    let valid = true;
    const temp = await getQuoteData();
    temp?.data.forEach((element) => {
      console.log(element?.brand_name);
      console.log(element?.brand_name === null || element?.brand_name === "");
      console.log(element?.cost_price === null || element?.cost_price === "");
      console.log(element?.mrp === null || element?.mrp === "");
      let rowFlag = false;
      if (element?.brand_name === null || element?.brand_name === "")
        rowFlag = rowFlag;
      else rowFlag = true;
      if (element?.cost_price === null || element?.cost_price === "")
        rowFlag = rowFlag;
      else rowFlag = true;
      if (element?.mrp === null || element?.mrp === "") rowFlag = rowFlag;
      else rowFlag = true;
      if (
        element?.gst === null ||
        element?.gst === "" ||
        element?.gst === "Not selected"
      )
        rowFlag = rowFlag;
      else rowFlag = true;
      console.log("rowflag", rowFlag);
      if (rowFlag) {
        if (element?.brand_name === null || element?.brand_name === "")
          valid = false;
        if (element?.cost_price === null || element?.cost_price === "")
          valid = false;
        if (element?.mrp === null || element?.mrp === "") valid = false;
        if (
          element?.gst === null ||
          element?.gst === "" ||
          element?.gst === "Not selected"
        )
          valid = false;
      }
    });
    return valid;
  }

  const mySearchFunction = () => {
    var input, filter, table, tr, td, i, j, txtValue, alltags;
    let isFound;
    let count = 0;

    input = document.getElementById("searchInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("vendor_quote_table");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      // td = tr[i].getElementsByTagName("td")[0];
      alltags = tr[i].getElementsByTagName("td");
      isFound = false;
      for (j = 0; j < alltags.length; j++) {
        td = alltags[j];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
            j = alltags.length;
            isFound = true;
            count++;
          }
        }
      }
      if (!isFound && tr[i].className !== "header") {
        tr[i].style.display = "none";
      }
    }

    var error = document.getElementById("error");
    if (count === 0) {
      setSearchError(true);
    } else {
      setSearchError(false);
    }
  };

  async function handleMaterialChange(e) {
    setMaterialSelected(e.target.value);
    const res = await getQuoteData();
    let temp = [...res?.data];
    temp?.map((row, index) => {
      let sum = 0;
      brandList?.[index]?.data?.data.forEach((element) => {
        sum = sum + Number(element?.twelve_month_purchase);
      });
      row.annual_volume = sum;
      return row;
    });
    setQuoteData([...temp]);
    if (e.target.value.length === 0) {
      return res;
    }
    temp = temp.filter((row) => {
      return e.target.value.some((r) => r === row?.material_category);
    });
    setQuoteData(temp);
    return;
  }

  async function getMaterialList() {
    const res = await axios.get(
      `/vendor_quote/getBidMaterialCategory/${location?.state}`
    );
    return res?.data?.data;
  }
  const [highlight, setHighlight] = useState(null);
  return (
    <div className="card" style={{ padding: "40px", fontSize: "0.82rem" }}>
      {/* <Slider aria-label="Volume"   /> */}
      <div className="w-100 table-responsive">
        <div className="form-group">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <input
              type="text"
              className="form-control text-black"
              name=""
              placeholder="Search generics"
              id="searchInput"
              onKeyUp={mySearchFunction}
              onChange={(e) => setSearchError(false)}
              style={{ width: "60%" }}
            />
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">
                Material Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={materialSelected}
                onChange={handleMaterialChange}
                defaultValue={materialSelected}
                multiple
              >
                {/* <MenuItem value={"all"}>All</MenuItem> */}
                {materialList.map((val) => (
                  <MenuItem value={val?.material_category}>
                    {val?.material_category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ height: "60vh", overflowY: "scroll" }}>
            <table
              className="table shadow-hover card-table border-no tbl-btn text-left text-black"
              id="vendor_quote_table"
            >
              <div>
                <thead className="text-wrap">
                  <th
                    style={{
                      // width: "200px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    Generic
                  </th>
                  <th
                    style={{
                      // width: "100px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    Annual Volume
                  </th>
                  <th
                    style={{
                      // width: "100px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    Material Category
                  </th>
                  <th
                    style={{
                      // width: "100px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                    className="text-center"
                  >
                    Brand Name
                  </th>
                  <th
                    style={{
                      // width: "100px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    Cost Price{" "}
                  </th>
                  <th
                    style={{
                      // width: "100px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    GST
                  </th>
                  <th
                    style={{
                      // width: "100px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    MRP
                  </th>
                  <th
                    style={{
                      // width: "100px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    HSN
                  </th>
                  <th
                    style={{
                      // width: "100px",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      backgroundColor: "white",
                    }}
                  >
                    Company Name
                  </th>
                </thead>
                <tbody className="text-wrap">
                  {searchError ? (
                    <p
                      style={{
                        marginTop: "20px",
                        color: "red",
                        textAlign: "left",
                      }}
                    >
                      No data found.
                    </p>
                  ) : (
                    ""
                  )}
                  {quoteData?.map((val, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: val?.status === "1" ? "#D6EFC7" : "",
                      }}
                      onClick={(e) => setHighlight(index)}
                      className={
                        val?.status === "1"
                          ? ""
                          : index === highlight
                          ? "bg-warning text-black"
                          : ""
                      }
                    >
                      <td style={{ maxWidth: "200px" }}>{val?.generic}</td>
                      <td className="text-center">{val?.annual_volume}</td>
                      <td className="text-center">{val?.material_category} </td>
                      <td>
                        <SelectSearch
                          handleFocusOut={handleFocusOut}
                          id={quoteData?.[index]?.id}
                          brandList={brandList?.[index]?.data?.data}
                          selectedValue={selectedValue}
                          setSelectedValue={setSelectedValue}
                          brandSelected={val?.brand_name}
                          setQuoteData={setQuoteData}
                          quoteData={quoteData}
                          index={index}
                          status={val?.status === "1"}
                          highlight={highlight === index}
                        />
                        {/* <select className="form-control"  style={{ width: "200px" }}>
                      {val.brandName.map((temp) => (
                        <option>{temp}</option>
                      ))}
                    </select> */}
                        {/* <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      Select Brand Name
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {val.brandName.map((temp) => (
                        <Dropdown.Item style={{ width: "auto" }}>
                          {temp}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown> */}
                      </td>
                      <td>
                        <input
                          className="form-control"
                          style={{
                            width: "100px",
                            backgroundColor:
                              val?.status === "1" ? "#D6EFC7" : "",
                          }}
                          type="text"
                          value={
                            val?.cost_price === 0 ? "" : val?.cost_price ?? ""
                          }
                          onBlur={(e) => {
                            if (val?.status === "1") return;
                            handleFocusOut(quoteData?.[index]?.id, index);
                          }}
                          onChange={(e) =>
                            setQuoteData((prev) => {
                              if (isNaN(Number(e.target.value))) return prev;
                              if (val?.status === "1") return prev;
                              let temp = quoteData;
                              temp[index].cost_price = e.target.value;
                              return [...temp];
                            })
                          }
                        ></input>
                      </td>
                      <td>
                        <select
                          disabled={val?.status === "1" ? true : null}
                          className="form-control"
                          style={{
                            width: "80px",
                            backgroundColor:
                              val?.status === "1" ? "#D6EFC7" : "",
                          }}
                          onBlur={(e) => {
                            if (val?.status === "1") return;
                            handleFocusOut(quoteData?.[index]?.id, index);
                          }}
                          onChange={(e) => {
                            setQuoteData((prev) => {
                              if (val?.status === "1") return prev;
                              let temp = [...quoteData];
                              temp[index].gst = e.target.value;
                              // .substring(
                              //   0,
                              //   e.target.value.length - 1
                              // );
                              return [...temp];
                            });
                          }}
                        >
                          <option value={quoteData?.[index]?.gst} selected>
                            {quoteData?.[index]?.gst === "Not selected" ||
                            quoteData?.[index]?.gst === "" ||
                            quoteData?.[index]?.gst === null
                              ? "Not selected"
                              : quoteData?.[index]?.gst + "%"}
                          </option>
                          {gstData?.map((temp) => {
                            const returnValue =
                              temp === quoteData?.[index]?.gst ||
                              (temp === "Not selected" &&
                                (quoteData?.[index]?.gst === "" ||
                                  quoteData?.[index]?.gst === null)) ? (
                                ""
                              ) : (
                                <option value={temp}>
                                  {temp === "Not selected" ? temp : temp + "%"}
                                </option>
                              );
                            return returnValue;
                          })}
                        </select>
                      </td>
                      <td>
                        <input
                          className="form-control"
                          style={{
                            width: "100px",
                            backgroundColor:
                              val?.status === "1" ? "#D6EFC7" : "",
                          }}
                          type="text"
                          value={val?.mrp === 0 ? "" : val?.mrp ?? ""}
                          onBlur={(e) => {
                            if (val?.status === "1") return;
                            handleFocusOut(quoteData?.[index]?.id, index);
                          }}
                          onChange={(e) =>
                            setQuoteData((prev) => {
                              if (isNaN(Number(e.target.value))) return prev;
                              if (val?.status === "1") return prev;
                              let temp = quoteData;
                              temp[index].mrp = e.target.value;
                              return [...temp];
                            })
                          }
                        ></input>{" "}
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          style={{
                            width: "100px",
                            backgroundColor:
                              val?.status === "1" ? "#D6EFC7" : "",
                          }}
                          value={val?.hsn ?? ""}
                          onBlur={(e) => {
                            if (val?.status === "1") return;
                            handleFocusOut(quoteData?.[index]?.id, index);
                          }}
                          onChange={(e) =>
                            setQuoteData((prev) => {
                              if (val?.status === "1") return prev;
                              let temp = quoteData;
                              temp[index].hsn = e.target.value;
                              return [...temp];
                            })
                          }
                        ></input>
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          style={{
                            width: "100px",
                            backgroundColor:
                              val?.status === "1" ? "#D6EFC7" : "",
                          }}
                          value={val?.company_name}
                          onBlur={(e) => {
                            if (val?.status === "1") return;
                            handleFocusOut(quoteData?.[index]?.id, index);
                          }}
                          onChange={(e) =>
                            setQuoteData((prev) => {
                              if (val?.status === "1") return prev;
                              let temp = quoteData;
                              temp[index].company_name = e.target.value;
                              return [...temp];
                            })
                          }
                        ></input>{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </div>
            </table>
          </div>
        </div>
      </div>
      <div className="col-md-12 d-flex justify-content-end mt-4">
        <div
          onClick={async (e) => {
            const valid = await inputValidation();
            console.log(valid);
            valid
              ? history.push({ pathname: "/preview", state: location?.state })
              : swal(
                  "Incomplete records found. Please either complete or discard them to continue",
                  { icon: "error" }
                );
          }}
        >
          <button className="btn btn-warning text-white">Preview</button>
        </div>
      </div>
    </div>
  );
}

function Footer() {
  const [data, setData] = useState([
    { city: "Chennai", inputValue: "" },
    { city: "VijayWada", inputValue: "" },
    { city: "Vizag", inputValue: "" },
    { city: "Hyderabad", inputValue: "" },
    { city: "Bangalore", inputValue: "" },
    { city: "Delhi", inputValue: "" },
  ]);
  return (
    <div className="card" style={{ padding: "40px" }}>
      <div className="col-sm-12 ">
        <p className="text-black">Enter distributor details</p>
      </div>
      <div className="w-100 table-responsive">
        <div className="form-group">
          <table className="table shadow-hover card-table border-no tbl-btn text-left text-black">
            <thead className="text-center">
              <tr>
                {data.map((val, index) => {
                  return <th>{val.city}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                {data.map((val, index) => (
                  <td>
                    <input
                      className="form-control"
                      style={{ width: "200px" }}
                      type="text"
                      value={val.inputValue}
                      onChange={(e) =>
                        setData((prev) => {
                          let temp = data;
                          temp[index].inputValue = e.target.value;
                          return [...temp];
                        })
                      }
                    ></input>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
