import React, { useState, useEffect } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import swal from "sweetalert";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import PageTitle from "../../layouts/PageTitle";
import { Modal, Button } from "react-bootstrap";
import { styled } from "@mui/system";
import TablePaginationUnstyled from "@mui/base/TablePaginationUnstyled";

function BiddingGroup() {
  /****************************************/

  const [records, setRecords] = useState([]);
  const [rowData, setRowData] = useState([]);
  console.log(rowData.material_category);
  const [modalCentered, setModalCentered] = useState(false);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [searchResult, setSearchResult] = useState({});

  /******************Pagination Code******************************************/
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - records?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const CustomTablePagination = styled(TablePaginationUnstyled)`
    & .MuiTablePaginationUnstyled-toolbar {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }

    & .MuiTablePaginationUnstyled-selectLabel {
      margin: 0;
    }

    & .MuiTablePaginationUnstyled-displayedRows {
      margin: 0;

      @media (min-width: 768px) {
        margin-left: auto;
      }
    }

    & .MuiTablePaginationUnstyled-spacer {
      display: none;
    }

    & .MuiTablePaginationUnstyled-actions {
      display: flex;
      gap: 0.25rem;
    }
  `;

  /*********************************************************/

  //modal
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  /************************************/

  //search function
  /*   const mySearchFunction = () => {
    var input, filter, table, tr, td, i, j, txtValue, alltags;
    let isFound;
    let count = 0;
    input = document.getElementById("searchInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("bidgroup_table");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      // td = tr[i].getElementsByTagName("td")[0];
      alltags = tr[i].getElementsByTagName("td");
      isFound = false;
      for (j = 0; j < alltags.length; j++) {
        td = alltags[j];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
            j = alltags.length;
            isFound = true;
            count++;
          }
        }
      }
      if (!isFound && tr[i].className !== "header") {
        tr[i].style.display = "none";
      }
    }

    var error = document.getElementById("error");
    if (count === 0) {
      error.innerHTML = "<p style='color: red;'>" + "Data Not Found</p>";
    } else {
      error.innerHTML = "";
    }
  }; */

  //table data export function
  function fnExport(fileExtn, filename, e) {
    e.preventDefault();
    let exportMaterialData = records?.map((item, i, arr) => {
      return Object.keys(arr[i])
        .filter(
          (key) => key.includes("code") || key.includes("material_category")
        )
        .reduce((cur, key) => {
          return Object.assign(cur, { [key]: arr[i][key] });
        }, {});
    });

    console.log("filterMaterialData", exportMaterialData);
    let header = ["CODE", "MATERIAL CATEGORY"];
    const ws = XLSX.utils.book_new();

    XLSX.utils.sheet_add_aoa(ws, [header], {
      origin: "A1",
    });
    XLSX.utils.sheet_add_json(ws, exportMaterialData, {
      origin: "A2",
      skipHeader: true,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    var wscols = [
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 25 },
      { width: 20 },
      { width: 30 },
    ];
    ws["!cols"] = wscols;
    const excelBuffer = XLSX.write(wb, {
      bookType: fileExtn,
      type: "array",
      cellStyles: true,
    });
    const finalData = new Blob([excelBuffer], { type: "xlsx" });
    FileSaver.saveAs(finalData, filename);
  }

  //fetch API or Get API call

  const [error, setErrors] = useState(false);
  const getAllMaterialCategory = async () => {
    await axios
      .get("/material_category/getAllMaterialCategory")
      .then((response) => {
        // console.log(response.data.data);
        if (response?.data?.data?.length === 0) {
          setErrors(true);
          // console.log(response?.data?.data?.length, "length")
        } else {
          setErrors(false);
          setRecords(response?.data?.data);
          console.log(response?.data?.data);
        }
      });
  };

  useEffect(() => {
    getAllMaterialCategory();
  }, []);

  useEffect(() => {
    function restAPI() {
      fetch("https://jsonplaceholder.typicode.com/posts/1")
        .then((response) => response.json())
        .then((json) => console.log("json", json));
    }
    restAPI();
  }, []);
  //delete by id
  const MaterialDeleteHandle = (id) => {
    console.log("delete bid id " + id);
    swal({
      title: "Are you sure? You want to delete this material category",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .delete(`/material_category/delete_material_category/${id}`)
          .then(function (response) {
            if (!response.data.hasError) {
              getAllMaterialCategory();
              swal(`${response.data.message}`, {
                icon: "success",
              });
            } else {
              console.log(response.data);
            }
          })
          .catch(function ({ response }) {
            console.log(response);
          });
        console.log(id + " deleted this material category");
      }
    });
  };

  //edit by id
  const MaterialEditById = (id) => {
    console.log(id, "Edit id");
    axios
      .get(`/material_category/getMaterialCategory/${id}`)
      .then((response) => {
        if (!response.data.hasError) {
          setRowData(response?.data?.data[0]);
        } else {
          console.log(response.data.data[0]);
        }
      });
  };

  //Update handle change
  function handleChangeUpdateData(e) {
    setRowData({ ...rowData, [e.target.name]: e.target.value });
  }

  const UpdatedData = {
    id: rowData?.id,
    code: rowData?.code,
    material_category: rowData?.material_category,
  };

  const handleSubmitUpdate = (e) => {
    e.preventDefault();

    if (rowData?.code === "") {
      document.getElementById("error_code").innerHTML = "Please enter code";
    } else if (rowData?.material_category === "") {
      document.getElementById("error_material").innerHTML =
        "Please enter material category";
    } else {
      console.log(UpdatedData);
      // console.log("Update Data ", rowData.code, " ", rowData.material_category)
      axios
        .put("/material_category/update_material_category", UpdatedData)
        .then((response) => {
          if (response.data.hasError) {
            document.getElementById("material_errorExist").innerHTML =
              "<p style='color: red;'>" + `${response.data.message}</p>`;
          } else {
            setModalCentered(false);
            getAllMaterialCategory();
            swal({
              title: "Material Category Updated Successfully",

              // text: "You clicked the button!",
              icon: "success",
              button: "Okay!",
            });
            // setRowData(response.data.data[0]);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  //add new data
  var myurl = "/material_category/create_material";

  const handleSubmitNewData = (values, { setSubmitting, resetForm }) => {
    var bodyFormData = {
      code: values.code,
      material_category: values.material_category,
    };

    console.log(bodyFormData);
    axios
      .post(myurl, bodyFormData)
      .then(function (response) {
        if (response.data.hasError) {
          document.getElementById("material_errorExist").innerHTML =
            "<p style='color: red;'>" + `${response.data.message}</p>`;
        } else {
          resetForm({});
          setShow(false);
          getAllMaterialCategory();
          swal({
            title: response.data.message,
            // text: "You clicked the button!",
            icon: "success",
            button: "Okay!",
          });
        }
      })
      .catch(function ({ response }) {
        //handle error
        setSubmitting(false);
        console.log(response);
      });
  };

  const defaultValue = {
    code: "",
    material_category: "",
  };

  const validationSchema = yup.object().shape({
    code: yup.string().required("Please enter code"),
    material_category: yup.string().required("Please enter Material Catergory"),
  });

  const onSearchHandler = (e) => {
    console.log(e.target.value, e.target.value.length);
    setSearchResult({ result: e.target.value, length: e.target.value.length });
  };

  const lowersearch = searchResult?.result?.toString().toLowerCase().trim();
  // console.log(lowersearch);
  const materialsearchall = records
    ?.filter(
      (sv) =>
        sv.code.toLowerCase().includes(lowersearch) ||
        sv.material_category.toLowerCase().includes(lowersearch)
    )
    .map((sitem) => {
      return sitem;
    });

  return (
    <>
      {/* body part */}
      <PageTitle activeMenu="Material Category" motherMenu="Material" />
      <div className="row">
        {/*  <PageTitle activeMenu="Users" motherMenu="Dashboard" /> */}

        <div className="col ">
          {/* <h4 className="px-3">Users</h4> */}
          <div className="card ">
            <div className="card-header ">
              <input
                className="form-control mx-4 text-black"
                type="text"
                placeholder="Search Material Category"
                id="searchInput"
                onChange={onSearchHandler}
                // onKeyUp={searchTable}
                /*              onKeyUp={mySearchFunction} */
              />
              <div className="d-flex">
                <i
                  className="fa fa-plus-circle"
                  style={{
                    fontSize: "30px",
                    color: "var(--primary)",
                    cursor: "pointer",
                  }}
                  onClick={handleShow}
                ></i>

                <i
                  id="btn-export"
                  onClick={(e) => fnExport("xlsx", "MaterialSheet.xlsx", e)}
                  className="fa fa-cloud-download  mx-3"
                  style={{
                    fontSize: "30px",
                    color: "var(--primary)",
                    cursor: "pointer",
                  }}
                  aria-hidden="true"
                ></i>
              </div>
            </div>
            <div className="card-body py-1">
              <div className="w-100 table-responsive">
                <div
                  id="patientTable_basic_table"
                  className="dataTables_wrapper"
                >
                  <table
                    className="table shadow-hover card-table border-no tbl-btn text-center text-black"
                    id="bidgroup_table"
                  >
                    <thead>
                      <th scope="">Code</th>
                      <th scope="">Material Category</th>
                      <th scope="">Action</th>
                    </thead>

                    <tbody>
                      {records?.length === 0 ? (
                        <tr>
                          <td></td>
                          <td className="text-danger">
                            Material Category has been not created yet
                          </td>
                          <td></td>
                        </tr>
                      ) : (
                        <>
                          {searchResult.length > 0 ? (
                            <>
                              {materialsearchall.length === 0 ? (
                                <>
                                  <tr>
                                    <td colSpan={3}>
                                      <h6 className="text-danger">
                                        No Records Found
                                      </h6>
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <> </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}

                          {searchResult.length > 0 ? (
                            <>
                              {materialsearchall?.map((mitem, i) => (
                                <React.Fragment>
                                  {materialsearchall.length > 0 ? (
                                    <>
                                      <tr>
                                        <td>{mitem.code}</td>
                                        <td>{mitem.material_category}</td>
                                        <td className="d-flex justify-content-center">
                                          <button
                                            onClick={() => {
                                              setModalCentered(true);
                                              MaterialEditById(mitem?.id);
                                            }}
                                            className="btn btn-warning shadow btn-xs sharp mr-1"
                                          >
                                            <i className="fa fa-pencil"></i>
                                          </button>
                                          <button
                                            onClick={(e) =>
                                              MaterialDeleteHandle(mitem?.id)
                                            }
                                            className="btn btn-danger shadow btn-xs sharp"
                                          >
                                            <i className="fa fa-trash"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </React.Fragment>
                              ))}
                            </>
                          ) : (
                            <>
                              {(rowsPerPage > 0
                                ? records?.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                : records
                              ).map((val, i) => (
                                <tr key={i}>
                                  <td>{val?.code}</td>
                                  <td>
                                    <span> {val?.material_category}</span>
                                  </td>
                                  <td className="d-flex justify-content-center">
                                    <div>
                                      <button
                                        onClick={() => {
                                          setModalCentered(true);
                                          MaterialEditById(val?.id);
                                        }}
                                        className="btn btn-warning shadow btn-xs sharp mr-1"
                                      >
                                        <i className="fa fa-pencil"></i>
                                      </button>
                                      <button
                                        onClick={(e) =>
                                          MaterialDeleteHandle(val?.id)
                                        }
                                        className="btn btn-danger shadow btn-xs sharp"
                                      >
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}

                          {emptyRows > 0 && (
                            <tr style={{ height: 41 * emptyRows }}>
                              <td colSpan={3} />
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                    <React.Fragment>
                      <tfoot className="col-md-12">
                        <tr className="">
                          <CustomTablePagination
                            rowsPerPageOptions={[
                              5,
                              10,
                              25,
                              { label: "All", value: -1 },
                            ]}
                            colSpan={3}
                            count={records?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            componentsProps={{
                              select: {
                                "aria-label": "rows per page",
                              },
                              actions: {
                                showFirstButton: true,
                                showLastButton: true,
                              },
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </tr>
                      </tfoot>
                    </React.Fragment>
                  </table>
                  <div id="error" className="text-center"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add new data modal */}
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>
            Create a material category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Create group module */}
          <div className="col-sm-12 col-md-12">
            <div className=" flex-wrap justify-content-around">
              <Formik
                initialValues={defaultValue}
                validationSchema={validationSchema}
                onSubmit={handleSubmitNewData}
              >
                {({ isSubmitting, errors, isValid, touched }) => (
                  <Form>
                    <div className="my-3">
                      <label className="form-label">
                        Code<span className="text-danger mx-1">*</span>
                      </label>
                      <Field
                        type="text"
                        name="code"
                        className="form-control"
                        placeholder="for example: '#24122'"
                      />

                      {errors.code && touched.code ? (
                        <div className="text-danger">{errors.code}</div>
                      ) : null}
                    </div>

                    <div className="my-auto">
                      <label className="form-label">
                        Material category
                        <span className="text-danger mx-1">*</span>
                      </label>
                      <Field
                        type="text"
                        name="material_category"
                        className="form-control"
                        placeholder="for example: 'Pharma'"
                      />
                      {errors.material_category && touched.material_category ? (
                        <div className="text-danger">
                          {errors.material_category}
                        </div>
                      ) : null}

                      <div
                        id="material_errorExist"
                        className="text-danger"
                      ></div>
                    </div>

                    <div className="d-flex justify-content-end my-4">
                      <button
                        onClick={handleClose}
                        className="btn btn-danger mx-2"
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-primary">
                        SUBMIT
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* //Update Modal */}
      <Modal
        className="fade"
        show={modalCentered}
        onHide={setModalCentered}
        backdrop="static"
      >
        <Modal.Header>
          <h4 className="text-black">Edit Material Category</h4>
          <Button
            onClick={() => {
              setModalCentered(false);
              getAllMaterialCategory();
            }}
            variant=""
            className="close"
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="col-sm-12 col-md-12">
            <div className=" flex-wrap justify-content-center">
              <form onSubmit={handleSubmitUpdate}>
                <div className="my-3">
                  <label className="form-label">
                    Code<span className="text-danger mx-1">*</span>
                  </label>
                  <input
                    id="code"
                    name="code"
                    type="text"
                    value={rowData?.code}
                    onChange={(e) => handleChangeUpdateData(e)}
                    className="form-control my-2"
                  />

                  <div id="error_code" className="text-danger"></div>

                  <label className="form-label">
                    Material Category<span className="text-danger mx-1">*</span>
                  </label>
                  <input
                    id="material_catergory"
                    name="material_category"
                    type="text"
                    value={rowData?.material_category}
                    onChange={(e) => handleChangeUpdateData(e)}
                    className="form-control my-2"
                  />
                  <div id="error_material" className="text-danger"></div>
                  <div id="material_errorExist" className="text-danger"></div>
                </div>

                <div className="d-flex justify-content-end my-4">
                  <Button
                    onClick={() => {
                      setModalCentered(false);
                      getAllMaterialCategory();
                    }}
                    variant=""
                    className="btn btn-danger mx-2"
                  >
                    Close
                  </Button>
                  <button type="submit" className="btn btn-secondary">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BiddingGroup;
