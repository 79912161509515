import React, { useEffect, useState } from "react";
import PageTitle from "../../../layouts/PageTitle";
import swal from "sweetalert";
import axios from "axios";
// import { Redirect } from 'react-router-dom'
import { useHistory } from "react-router-dom";

function Add_Role() {
  const [permissionAll, setPermissionAll] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  const [state, setState] = useState({
    role_name: "",
    created_by_user: ""
  })
  let errorsObj = { role_name: "" };
  const [errors, setErrors] = useState(errorsObj);

  const history = useHistory();

  const [nameError, setNameError] = useState(false);
  const [nameLenError, setNameLenError] = useState(false)
  const [permissionError, setPermissionError] = useState(false);

  useEffect(() => {
    axios.get('/setting/getAllPermission')
      .then((response) => {
        // console.log(response.data.data);
        console.log(response.data.data.message);
        setPermissionAll(response.data.data);
      })
      .catch(function (response) {
        console.log(response.data);
      })
  }, [])

  const handleClick = (e) => {
    const { id, checked } = e.target;

    setIsCheck([...isCheck, id]);

    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  function handleRole(evt) {

    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  }





  let UserId = localStorage.getItem("userId");
  console.log(UserId);

  const roles = {
    role_name: state.role_name,
    created_by_user: UserId,
    role_permission: isCheck
  }
  // console.log(isCheck);

  const handleSubmit = (e) => {
    e.preventDefault();
    var errorPermission = document.getElementById("errorPermission")
    let error = false;
    const errorObj = { ...errorsObj };

    if (state.role_name === "") {
      setNameLenError(true);
      return
    }

    setErrors(errorObj);  
    if (error) {
      return;
    }
    else if (isCheck.length === 0) {
      setPermissionError(true)
    }
    else {
      errorPermission.innerHTML = ""
      // console.log(roles);
      axios.post('/setting/create_role', roles)
        .then((response) => {
          console.log(response.data.message)
          var status = response.data.message;
          if (status.toUpperCase() === "ROLE NAME ALREADY EXIST") {
              setNameError(true)
          }
          else{
            // history.push("/settings/roles")
            // console.log(status)
          }
          if(response.data.hasError){
            swal({
              title: "Role was not created",
              // text: "You clicked the button!",
              icon: "error",
              button: "Okay!",
          });
          }
          else{
            swal({
              title: "Role Successfully Created!",
              // text: "You clicked the button!",
              icon: "success",
              button: "Okay!",
          });
          history.push("/settings/roles")
          }
        })
        .catch(function (response) {
          console.log(response.data);
        })


    }






  }



  return (
    <>
      <div className="row">
        <div>
          <PageTitle activeMenu="Add Role" motherMenu="Users" />
        </div>
        <div className="col-md-12">
          <div className="card ">
            <div className="card-header">
              <h2>Add Role </h2>
            </div>
            <div className="card-body mx-3 py-3">
              <div className="w-100 table-responsive text-black">
                <form onSubmit={handleSubmit} onChange={(e)=>{setPermissionError(false); setNameError(false); setNameLenError(false)}}>
                  <div className="form-group">
                    <label htmlFor="">Role Name</label>
                    <input
                      type="text"
                      name="role_name"
                      value={state.role_name}
                      onChange={handleRole}
                      className="form-control"
                      id="role_name"
                    />
                    <div id="errorExist" className="text-danger">

                    </div>
                    {errors.role_name && (
                      <div className="text-danger fs-12">
                        {errors.role_name}
                      </div>
                    )}
                  </div>
                  <p style={{textAlign:"left", color:"red"}} className="danger">{nameError?"Selected name already exists, please select another role name":""}</p>
                  <p style={{textAlign:"left", color:"red"}} className="danger">{nameLenError?"Please enter a role name":""}</p>
                  <div>
                    <label htmlFor="">Permission</label>
                    {permissionAll.map((val, i) => {
                      return (
                        <div className="custom-control custom-checkbox my-2 mx-auto" key={i}>
                          <input
                            type="checkbox"
                            onChange={handleClick}
                            className="custom-control-input"
                            name={val.permission}
                            id={val.permission}
                            checked={isCheck.includes(val.permission)}
                          />

                          <label
                            className="custom-control-label mx-2"
                            htmlFor={val.permission}
                          />

                          <span>{val.permission}</span>
                        </div>
                      );
                    })}

                  </div>
                  <div id="errorPermission" className="text-center">
                  </div>
                  <p style={{textAlign:"left", color:"red"}} className="danger">{permissionError?"Please select at least one permission":""}</p>
                  <div className="col-md-6 float-right">
                    <div className="d-flex justify-content-end">
                      <button type="submit" className="btn btn-primary mr-4">
                        SUBMIT
                      </button>
                      <button className="btn btn-danger light mr-4" onClick={(e) => history.push("/settings/roles")}>CANCEL</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Add_Role;
