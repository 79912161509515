import React, { useState, useEffect } from "react";
import Multistep from "react-multistep";
import VendorList from "./jsondata/VendorData";
// import { Pagination, InputGroup, FormControl, Form } from "react-bootstrap";
// import { useState } from "react";
// import "./Css/tbl.css"
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import axios from "axios";

const StepThree = (props) => {
  const history = useHistory();
  /*   const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]); */
  const [list, setList] = useState([]);

  /*   useEffect(() => {
    setList(VendorList);
  }, [list]); */

  const handleSelectAll = (e) => {
    props.setIsCheckAll3(!props.isCheckAll3);

    const vendorcheckall = props.vendorList.map((li, i) => {
      return {
        vendor_user_id: li.id.toString(),
      };
    });
    props.setIsCheck3(vendorcheckall);
    if (props.isCheckAll3) {
      props.setIsCheck3([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    props.setIsCheck3([...props.isCheck3, { vendor_user_id: id }]);
    if (!checked) {
      props.setIsCheck3(
        props.isCheck3.filter((item) => item.vendor_user_id !== id.toString())
      );
    }
  };

  console.log(props.isCheck3);

  const userInviteHandle = () => {
    swal({
      title: "Are you sure?",
      text: "Click OK to Generate Bid and \n Send Invite to Selected vendors",

      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("All vendors have been invited!", {
          icon: "success",
          timer: 2000,
        });
        setTimeout(() => {
          history.push("/");
        }, 2000);
      } else {
      }
    });
  };

  /*   const GetVendorsList = () => {
    axios.get("/vendor/getAllVendor").then((res) => {
      console.log(res.data.data);
      setList(res.data.data);
    });
  };

  useEffect(() => {
    GetVendorsList();
  }, []);
 */

  // const data = [
  //   {
  //     companyName: "Pfizer",
  //     contact: "Abhishek Shetty",
  //     email: "pfizer@gmail.com",
  //     isChecked: true,
  //   },
  //   {
  //     companyName: "J&J",
  //     contact: "Jayesh Sawant",
  //     email: "jjbid@gmail.com",
  //     isChecked: true,
  //   },
  //   {
  //     companyName: "Moderna",
  //     contact: "Sanika Reddy",
  //     email: "sanika.moderna@gmail.com",
  //     isChecked: true,
  //   },
  //   {
  //     companyName: "Moderna",
  //     contact: "Jobin George",
  //     email: "moderna@lls.com",
  //     isChecked: true,
  //   },
  // ];

  // const [active, setActive] = useState(1);
  // let items = [];
  // for (let number = 1; number <= 5; number++) {
  //   items.push(
  //     <Pagination.Item
  //       key={number}
  //       active={number === active}
  //       onClick={(e) => setActive(number)}
  //     >
  //       {number}
  //     </Pagination.Item>
  //   );
  // }

  const chackbox = document.querySelectorAll(".sorting_1 input");
  const motherChackBox = document.querySelector(".sorting_asc input");
  // console.log(document.querySelectorAll(".sorting_1 input")[0].checked);
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };

  const mySearchFunction = () => {
    var input, filter, table, tr, td, i, j, txtValue, alltags;
    let isFound;
    let count = 0;

    input = document.getElementById("searchInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("vendor_table");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      // td = tr[i].getElementsByTagName("td")[0];
      alltags = tr[i].getElementsByTagName("td");
      isFound = false;
      for (j = 0; j < alltags.length; j++) {
        td = alltags[j];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
            j = alltags.length;
            isFound = true;
            count++;
          }
        }
      }
      if (!isFound && tr[i].className !== "header") {
        tr[i].style.display = "none";
      }
    }
    var error = document.getElementById("error");
    if (count === 0) {
      error.innerHTML = "<p style='color: red;'>" + "Data Not Found</p>";
    } else {
      error.innerHTML = "";
    }
  };
  const propscheck3id = props.isCheck3.map((val) => val.vendor_user_id);

  console.log(propscheck3id);
  return (
    <>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon3">
            Search Vendors
          </span>
        </div>
        <input
          type="text"
          class="form-control"
          placeholder="For example - 'Pfizer'"
          id="searchInput"
          aria-describedby="basic-addon3"
          onKeyUp={mySearchFunction}
        />
      </div>
      <div className="card">
        <div className="card-body">
          <div className="w-100 table-responsive">
            <div id="patientTable_basic_table" className="dataTables_wrapper">
              <table
                id="vendor_table"
                className="table shadow-hover card-table border-no tbl-btn text-left text-black"
                style={{ minWidth: 800 }}
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <th
                    className="sorting_asc"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-sort="ascending"
                    aria-label=": activate to sort column descending"
                    style={{ width: 32 }}
                  >
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        onChange={handleSelectAll}
                        className="custom-control-input"
                        id="selectAll"
                        checked={props.isCheckAll3}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="selectAll"
                      />
                    </div>
                  </th>

                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Date Check in: activate to sort column ascending"
                    style={{ width: 100 }}
                  >
                    Company Name
                  </th>

                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Doctor Assgined: activate to sort column ascending"
                    style={{ width: 120 }}
                  >
                    Contact
                  </th>
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="example5"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Doctor Assgined: activate to sort column ascending"
                    style={{ width: 120 }}
                  >
                    Email ID
                  </th>
                </thead>
                <tbody>
                  {props.vendorList?.map((vendor, i) => (
                    <tr key={i} role="row" className="odd">
                      <td className="sorting_1">
                        <div className="custom-control custom-checkbox ">
                          <input
                            type="checkbox"
                            onChange={handleClick}
                            className="custom-control-input"
                            name={vendor.id.toString()}
                            id={vendor.id.toString()}
                            checked={propscheck3id.includes(
                              vendor.id.toString()
                            )}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={vendor.id.toString()}
                          />
                        </div>
                      </td>
                      <td>{vendor.company_name}</td>
                      <td>{vendor.contact_person_1}</td>
                      <td>{vendor.email_id}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div id="error" className="text-center"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepThree;
