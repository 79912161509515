import React, { useState } from "react";
import { RangeDatePicker } from "@y0c/react-datepicker";
import { DateRangePicker, DateRange } from "react-date-range";
/* import DropdownMultiselect from "react-multiselect-dropdown-bootstrap"; */
import { addDays, endOfToday } from "date-fns";
import DateRangeModal from "./components/DateRangeModal";
import { useEffect } from "react";
import axios from "axios";
import Select from "react-select";

import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

const StepOne = (props) => {
  const [getDateShow, setGetDateShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [getmateriallist, setGetMaterialList] = useState([]);
  const [selectedmaterial, setSelectedMaterial] = useState([]);
  const [state, setState] = useState();
  const [materialnewlist, setMaterialNewList] = useState([]);
  const [filtermaterialid, setFilterMaterialId] = useState([]);

  const [biddescriptionerror, setBidDescriptionError] = useState("");

  const [selectmaterialcategory, setSelectMaterialCategory] = useState();

  const onchangeStep1 = () => {
    const step1 = {
      bid_description: props.biddescription,
      selected_category: props.selectedOption,
      start_date: props.startenddates[0].datenowstart,
      end_date: props.startenddates[0].datenowend,
    };

    // alert(JSON.stringify(step1, null, 2));
  };

  /*  const selectHandleChange = (e) => {
 

    setFilterMaterialId(e.target.value);
    setSelectedOption(e.target.value);
  }; */

  /*   useEffect((e) => {
    setFilterMaterialId(selectedOption({ value: e.target.value }));
  }); */

  /*   useEffect(() => {
    onchangeStep1();
  }, []); */

  const GetMaterialAxios = () => {
    axios.get("/material_category/getAllMaterialCategory").then((res) => {
      setGetMaterialList(res.data.data);
    });
  };

  useEffect(() => {
    GetMaterialAxios();
  }, [selectedmaterial]);
  const getDateHandle = (datenow) => {
    props.setStartEndDates(datenow);
    props.setGetDateShow(true);
    setModalShow(false);
  };

  const cancelDateHandle = () => {
    setModalShow(false);
    setGetDateShow(false);
  };

  const datesHandle = (state) => {
    props.setDates(state);
  };

  /*   const step1error = () => {
     if (!props.selectedOption) {
      setSelectMaterialError("Please Select Material Category");
    }
    if (!props.biddescription) {
      setBidDescriptionError("Plese Enter Bid Description");
    } 
  };



  useEffect(() => {
    step1error();
  }, [props.biddescription]);
 */
  // Get Material Category By Axios

  /*   useEffect(() => {
    setMaterialNewList(getmateriallist);
  }, [getmateriallist]); */
  const onchangedisable = () => {};

  const optiond = [
    { label: "one", value: 1, disabled: true },
    { label: "two", value: 2 },
  ];

  return (
    <section>
      <div
        className="row d-flex"
        style={{ pointerEvents: props.stepcompleted === 1 ? "" : "none" }}
      >
        <div className="col-lg-6 mb-2 mt-3 text-black">
          <div className="form-group">
            <label className="text-label ">Bid Description</label>
            {/*   <input
              type="textarea"
              name="firstName"
              className="form-control"
              required
            /> */}
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              value={props.biddescription}
              onChange={
                props.stepcompleted === 1
                  ? (e) => props.setBidDescription(e.target.value)
                  : onchangedisable
              }
            ></textarea>
            {props.biddescriptionerror ? (
              <small className="text-red">Please Enter Bid Description</small>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="col-md-6  text-black">
          <div className="form-group "></div>
          <label htmlFor="select_ssmaterial_categodry">
            Select Material Category
          </label>

          {/*  <DropdownMultiselect
            handleOnChange={(selected) => {
              setSelectedMaterial(selected);
            }} 
            buttonClass="btn-light "
            options={getmateriallist.map((mat) => {
              return {
                key: mat.id,
                label: mat.material_category,
              };
            })}
            name="select_ssmaterial_categodry"
          /> */}

          <div>
            <Select
              name="filters"
              placeholder="Select Material Category"
              options={getmateriallist.map((mat) => {
                return {
                  value: mat.id,
                  label: mat.material_category,
                };
              })}
              defaultValue={props.selectedOption}
              onChange={props.setSelectedOption}
              isMulti
            />
          </div>
        </div>

        <div className="col-lg-12 mb-2 text-black">
          <div className="">
            <p className="mb-1">Date Range Pick</p>
            {/*   <RangeDatePicker
              startText="Start"
              endText="End"
              startPlaceholder="Start Date"
              endPlaceholder="End Date"
              dateFormat="DD-MM-YYYY"
            /> */}
            {/*        <DateRangePicker
              onChange={(item) => setState([item.selection])}
                showSelectionPreview={true} 
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={state}
              direction="horizontal"
            />
            ; */}

            <div className="col">
              <button
                onClick={
                  props.stepcompleted === 1
                    ? () => setModalShow(true)
                    : onchangedisable
                }
                className="btn "
                style={{ background: "teal", color: "white" }}
              >
                <i className="fa fa-calendar mx-2"></i> Select Bid Start Date &
                End Date
              </button>

              <div className={props.getDateShow ? "align" : "d-none"}>
                {props.draftbidboolean ? (
                  <div className="col-md-4 my-3 card shadow  ">
                    <div className="my-2 text-center">
                      <span>
                        Start Date : {props.draftbidstendate?.stdate}{" "}
                      </span>
                    </div>
                    <div className="my-2 text-center">
                      <span>End Date : {props.draftbidstendate?.endate}</span>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-4 my-3 card shadow  ">
                    <div className="my-2 text-center">
                      <span>
                        Start Date : {props.startenddates[0].datenowstart}{" "}
                      </span>
                    </div>
                    <div className="my-2 text-center">
                      <span>
                        End Date : {props.startenddates[0].datenowend}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              {props.getDateShow ? (
                ""
              ) : (
                <div className="text-red">
                  <small>Please Select Bid Start & End Dates</small>
                </div>
              )}
            </div>

            <DateRangeModal
              show={modalShow}
              onHide={cancelDateHandle}
              getDate={getDateHandle}
              dates={datesHandle}
            />

            {/*     <DateRange
              editableDateInputs={true}
              onChange={(item) => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={state}
              months={2}
              direction="horizontal"
              minDate={new Date()}
            /> */}
          </div>
        </div>
        {/*    <div className="col-md-6">
          <div className="form-group "></div>
          <label htmlFor="">Select Bid Group</label>
          <DropdownMultiselect
            buttonClass="btn-light"
            options={[
              "VACCINES",
              "PHARMA",
              "SURGICALS",
              "IMPLANTS",
              "POWDERS",
              "DRAPES",
              "CSSD",
              "SUTURES",
              "GENERAL",
            ]}
            name="countries"
          />
        </div> */}
        <div></div>
      </div>
      <div className="my-5">
        {/*   <button onClick={onchangeStep1}>click</button> */}
      </div>
    </section>
  );
};

export default StepOne;
