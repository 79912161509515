import {
  
    CHANGE_PASSWORD_FAILED_ACTION,
    CHANGE_PASSWORD_SUCESS_ACTION,
} from '../actions/changePasswordAction';



const initialState = {
    changePasswords: {
    },
    showLoading: false,
};

export function ChangePasswordReducer(state = initialState, action) {
 
    if (action.type === CHANGE_PASSWORD_SUCESS_ACTION) {
        return {
            ...state,
            changePasswords: action.payload,
            showLoading: false,
        };
    }

    if (action.type === CHANGE_PASSWORD_FAILED_ACTION) {
        return {
            ...state,
            changePasswords: action.payload,
            showLoading: false,
        };
    }
    return state;
}

    
