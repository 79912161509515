import React, { useEffect } from "react";
import "./viewquote.css";
import { Button } from "react-bootstrap";
import { useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import TextField from "@material-ui/core/TextField";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function View_Quotes() {
  const orderdataBlog = [];
  const history = useHistory();
  const [colors, setColor] = useState([
    "bg-light",
    "bg-dark",
    "bg-secondary",
    "bg-success",
  ]);

  /*   function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  } */
  // const randomColor = Math.floor(Math.random() * 16777215).toString(16);

  const [newViewData, setNewViewData] = useState(orderdataBlog);
  console.log(newViewData);

  useEffect(() => {
    getVendorData().then((res) => {
      let temp = res?.data;
      temp?.map((val) => {
        val.value = val.company_name;
        val.label = val.company_name;
      });
      setOptions(temp);
      handleVendorChangeInitial(temp);
    });
  }, []);

  async function getVendorData() {
    const res = await axios.get(
      `/vendor_quote/getAllVendorByBid/${location?.state}`
    );
    return res?.data;
  }

  const location = useLocation();
  const [options, setOptions] = useState([]);

  //temporary purpose i used setNewViewData useState function in below for removing warning
  // setNewViewData("");
  const mySearchFunction = () => {
    var input, filter, table, tr, td, i, j, txtValue, alltags;
    let isFound;
    let count = 0;
    input = document.getElementById("searchInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("viewQuote_table");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      // td = tr[i].getElementsByTagName("td")[0];
      alltags = tr[i].getElementsByTagName("td");
      isFound = false;
      for (j = 0; j < alltags.length; j++) {
        td = alltags[j];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
            j = alltags.length;
            isFound = true;
            count++;
          }
        }
      }
      if (!isFound && tr[i].className !== "header") {
        tr[i].style.display = "none";
      }
    }
  };

  const [materiallist, setMaterial] = useState([]);
  function getAllMaterialCategory() {
    axios.get("/material_category/getAllMaterialCategory").then((response) => {
      // console.log(response.data.data, "material list");
      setMaterial(response.data.data);
    });
  }

  useEffect(() => {
    getAllMaterialCategory();
  }, []);

  //export
  function fnExport(fileExtn, filename) {
    let flag = true;
    let colorClass = true;
    let categoryLabel = 1;
    let Priority = "P";
    let dynamicData = [];
    let ViewDATA = [...newViewData];
    ViewDATA = ViewDATA?.filter((val) => {
      if (val.approval_status !== 1 && val.status === "1") {
        return val;
      }
    });
    ViewDATA.map((newdata, index, arr) => {
      newdata.cost_price =
        newdata?.discounted_price === 0
          ? newdata?.cost_price
          : newdata?.discounted_price;
      let cpGst =
        Number(newdata?.cost_price) +
        (Number(newdata?.cost_price) * Number(newdata?.gst)) / 100;
      let margin = Number(newdata?.mrp) - Number(cpGst);
      let marginPercent = (Number(cpGst) / Number(newdata?.mrp)) * 100;
      marginPercent = 100 - marginPercent;
      marginPercent = Math.round(marginPercent * 100) / 100;
      margin = Math.round(margin * 100) / 100;
      cpGst = Math.round(cpGst * 100) / 100;
      if (arr[index - 1]?.generic !== newdata?.generic) flag = false;
      else flag = true;
      colorClass = flag ? colorClass : !colorClass;
      flag ? (categoryLabel = categoryLabel + 1) : (categoryLabel = 1);
      let res =
        newdata.quote_priority === null
          ? "priority is not added"
          : Priority + newdata.quote_priority;
      let res1 =
        newdata.quote_remark === null
          ? "remark is not added"
          : newdata.quote_remark;
      dynamicData.push({
        margin: margin,
        marginPercent: marginPercent,
        res: res,
        res1: res1,
        categoryLabel: categoryLabel,
      });
    });
    ViewDATA?.map((val, index) => {
      if (val.approval_status === 1) {
        dynamicData[index].approval_status = "Approved";
      } else if (val.approval_status === -1) {
        dynamicData[index].approval_status = "Rejected";
      } else {
        dynamicData[index].approval_status = "Not Selected";
      }
    });
    console.log("status approval", ViewDATA);
    let result = ViewDATA.map((val, i) => {
      let exportDATA = {
        generic: val.generic,
        description: val.brand_name,
        vendor_company: val.company_name,
        material_category: val.material_category,
        cost_price: val.cost_price,
        gst: val.gst,
        mrp: val.mrp,
        margin: dynamicData[i].margin,
        margin_parcentage: dynamicData[i].marginPercent,
        quote_category: "L" + dynamicData[i].categoryLabel,
        quote_priority: dynamicData[i].res,
        quote_remark: dynamicData[i].res1,
        status_approval: dynamicData[i].approval_status,
      };
      return exportDATA;
    });
    console.log(result);
    console.log(vendorSelected);
    if (vendorSelected !== "all") {
      result = result.filter((val) => val.vendor_company === vendorSelected);
    }

    let header = [
      "GENERIC",
      "DESCIPTION",
      "VENDOR COMPANY",
      "MATERIAL CATEGORY",
      "COST PRICE",
      "GST",
      "MRP",
      "MARGIN",
      "MARGIN PERCENTAGE %",
      "QUOTE CATEGORY",
      "QUOTE PRIORITY",
      "QUOTE REMARK",
      "STATUS APPROVAL",
    ];
    const ws = XLSX.utils.book_new();

    XLSX.utils.sheet_add_aoa(ws, [header], {
      origin: "A1",
    });
    XLSX.utils.sheet_add_json(ws, result, {
      origin: "A2",
      skipHeader: true,
    });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"], range: 5 };

    ws["A1"].style = {
      font: {
        name: "arial",
        sz: 24,
        bold: true,
        color: "#F2F2F2",
      },
    };

    var wscols = [
      { width: 40 },
      { width: 20 },
      { width: 38 },
      { width: 20 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 25 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];
    ws["!cols"] = wscols;

    const excelBuffer = XLSX.write(wb, {
      bookType: fileExtn,
      type: "array",
      cellStyles: true,
    });
    const finalData = new Blob([excelBuffer], { type: "xlsx" });
    FileSaver.saveAs(finalData, filename);
    // history.go(0);
  }

  function handleVendorChange(e) {
    setVendorSelected(e.target.value);
    return;
  }

  async function handleVendorChangeInitial(temp) {
    const find = temp?.[0];
    const res = await axios.get(`/bid/getQuote/${find?.gen_bid_id}`);
    res?.data?.data.sort(compare);
    setNewViewData([...res?.data?.data]);
    getStatusCount(res?.data?.data);
    const unique = [...new Set(res?.data?.data?.map((item) => item.generic))];
    setNames(unique);
    console.log("getquote", res.data);
    return res?.data;
  }

  function compare(a, b) {
    if (a?.generic === b?.generic) {
      let cpGstA =
        Number(a?.cost_price) + (Number(a?.cost_price) * Number(a?.gst)) / 100;
      let cpGstB =
        Number(b?.cost_price) + (Number(b?.cost_price) * Number(b?.gst)) / 100;
      let marginPercentA = (Number(cpGstA) / Number(a?.mrp)) * 100;
      let marginPercentB = (Number(cpGstB) / Number(b?.mrp)) * 100;
      if (a?.status === "0") {
        return 0;
      }
      if (b?.status === "0") {
        return -1;
      }
      return marginPercentA - marginPercentB;
    }
    if (a?.generic < b?.generic) {
      return -1;
    }
    if (a?.last_nom > b?.last_nom) {
      return 1;
    }
    return 0;
  }

  // let colorCode = null;
  let flag = true;
  let colorClass = true;
  let categoryLabel = 1;

  const [names, setNames] = useState([]);

  const classes = useStyles();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  const [vendorSelected, setVendorSelected] = useState("all");
  const [statusCount, setStatusCount] = useState(null);

  function getStatusCount(res) {
    let count = 0;
    res.forEach((element) => {
      if (element?.status === "1") count = count + 1;
    });
    setStatusCount(count);
    return count;
  }

  const [modalShow, setModalShow] = React.useState(false);
  const [remarkModalShow, setRemarkModalShow] = React.useState(false);
  const [remarkObjectIndex, setRemarkObjectIndex] = useState(null);
  async function postPriority(id) {
    const res = await axios.put("/revaluation/updateQuotePriority", {
      quote_id: id,
      quote_priority: -1,
      quote_remark: "No remarks added.",
    });
    return res;
  }
  async function postApproval(id, approval, cat) {
    const res = await axios.put("/revaluation/updateApproveStatus", {
      quoteId: id,
      approvalStatus: approval,
      quoteCategory: cat,
    });
    return res;
  }
  let approveData = [
    { id: -1, label: "Rejected" },
    { id: 0, label: "Not selected" },
    { id: 1, label: "Approved" },
  ];

  const [highlight, setHighlight] = useState(null);
  let catData = [];

  return (
    <>
      <PageTitle activeMenu="View Quotes" motherMenu="View" />

      <div className="col-md-12">
        <div className="card cardheight px-0" style={{ overflow: "hidden" }}>
          <div className="">
            <div className="col-md-12">
              <div className="card-header">
                <div className="col d-flex justify-content-around align-items-center">
                  <div className="col-md-4 ">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control text-black"
                        name=""
                        placeholder="Search"
                        id="searchInput"
                        onKeyUp={mySearchFunction}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="col-md-3 mr-3">
                      <FormControl
                        className={classes.formControl}
                        style={{
                          minWidth: "280px",
                        }}
                      >
                        <InputLabel id="demo-mutiple-chip-label">
                          Generic
                        </InputLabel>
                        <Select
                          labelId="demo-mutiple-chip-label"
                          id="demo-mutiple-chip"
                          multiple
                          value={personName}
                          onChange={handleChange}
                          input={<Input id="select-multiple-chip" />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={value}
                                  className={classes.chip}
                                />
                              ))}
                            </div>
                          )}
                          MenuProps={MenuProps}
                        >
                          {names.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, personName, theme)}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="d-flex col-md-2 mx-3">
                    <div className="">
                      <div className="">
                        <FormControl
                          className={classes.formControl}
                          style={{
                            minWidth: "150px",
                          }}
                        >
                          <InputLabel id="demo-simple-select-label">
                            Vendor
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={vendorSelected}
                            onChange={handleVendorChange}
                            defaultValue={vendorSelected}
                          >
                            <MenuItem value={"all"}>All</MenuItem>
                            {options.map((val) => (
                              <MenuItem value={val.company_name}>
                                {val.company_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1 mx-2">
                    <i
                      id="btn-export"
                      onClick={(e) =>
                        fnExport("xlsx", "ViewQuoteSheet.xlsx", e)
                      }
                      class="fa fa-cloud-download   "
                      style={{
                        fontSize: "30px",
                        color: "var(--primary)",
                        cursor: "pointer",
                      }}
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body p-1">
            {statusCount === 0 ? (
              <p style={{ padding: "20px" }}>
                Vendors have not submitted the quote yet.
              </p>
            ) : (
              <div className="w-100 table-responsive">
                <div
                  id="example_wrapper"
                  className="dataTables_wrapper"
                  style={{ height: "60vh", overflowY: "scroll" }}
                >
                  <table
                    className="table shadow-hover card-table border-no tbl-btn text-left text-black"
                    id="viewQuote_table"
                  >
                    <div>
                      <thead className="text-wrap">
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          Generic
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          Description
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          Vendor Company
                          {/* <Select options={options} />  */}
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          Material Category{" "}
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          CP+GST
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          MRP
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          MARGIN
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          %
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          Category
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          Priority
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          Remark
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          Status Approval
                        </th>
                        <th
                          style={{
                            // width: "200px",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            backgroundColor: "white",
                          }}
                          className=""
                        >
                          Revaluation Status
                        </th>
                      </thead>

                      <tbody className="text-wrap">
                        {newViewData?.map((newdata, index, arr) => {
                          if (newdata?.approval_status !== 0) {
                            return <></>;
                          }
                          let price =
                            newdata?.discounted_price === 0
                              ? newdata?.cost_price
                              : newdata?.discounted_price;
                          let cpGst =
                            Number(price) +
                            (Number(price) * Number(newdata?.gst)) / 100;
                          let margin = Number(newdata?.mrp) - Number(cpGst);
                          let marginPercent =
                            (Number(cpGst) / Number(newdata?.mrp)) * 100;
                          marginPercent = 100 - marginPercent;
                          marginPercent = Math.round(marginPercent * 100) / 100;
                          margin = Math.round(margin * 100) / 100;
                          cpGst = Math.round(cpGst * 100) / 100;
                          if (arr[index - 1]?.generic !== newdata?.generic)
                            flag = false;
                          else flag = true;
                          colorClass = flag ? colorClass : !colorClass;
                          flag
                            ? (categoryLabel = categoryLabel + 1)
                            : (categoryLabel = 1);
                          catData.push(categoryLabel);
                          if (vendorSelected !== "all") {
                            if (vendorSelected !== newdata?.company_name) {
                              return <></>;
                            }
                          }
                          if (newdata?.status === "0") {
                            return (
                              <tr
                                onClick={(e) => setHighlight(index)}
                                className={"text-dark"}
                                style={{ backgroundColor: "#ffe0de" }}
                              >
                                <td style={{ maxWidth: "200px" }}>
                                  {newdata?.generic}
                                </td>
                                <td colSpan={11}>
                                  This drug has not been evaluated by the vendor
                                  yet.
                                </td>
                                <td></td>
                              </tr>
                            );
                          }
                          return (
                            <tr
                              onClick={(e) => setHighlight(index)}
                              className={
                                colorClass
                                  ? index === highlight
                                    ? "bg-primary text-white"
                                    : "text-light text-muted"
                                  : index === highlight
                                  ? "bg-primary text-white"
                                  : "bg-light text-dark"
                              }
                            >
                              <td style={{ maxWidth: "200px" }}>
                                {newdata?.generic}
                              </td>
                              <td className="">{newdata?.brand_name}</td>
                              <td>
                                {newdata?.company_name ?? "Vendor deleted."}
                              </td>
                              <td className="text-center">
                                {newdata?.material_category}
                              </td>
                              <td>{cpGst}</td>
                              <td>{newdata?.mrp}</td>
                              <td>{margin}</td>
                              <td>{marginPercent}</td>
                              <td className="text-center">
                                {"L" + categoryLabel}
                              </td>
                              <td>
                                <FormControl className={classes.formControl}>
                                  <Select
                                    style={{
                                      color: highlight === index ? "white" : "",
                                    }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={newdata?.quote_priority ?? -1}
                                    defaultValue={newdata?.quote_priority ?? -1}
                                    onChange={(e) => {
                                      let proceed = true;
                                      setNewViewData((prev) => {
                                        const temp = [...newViewData];
                                        const filterdByGeneric = temp?.filter(
                                          (row) =>
                                            row?.generic === newdata?.generic
                                        );
                                        const priorityExists =
                                          filterdByGeneric?.find(
                                            (row) =>
                                              row?.quote_priority ===
                                              e.target.value
                                          );
                                        console.log(priorityExists);
                                        if (priorityExists === undefined) {
                                          temp[index].quote_priority =
                                            e.target.value;
                                          console.log(temp);
                                          return temp;
                                        } else {
                                          proceed = false;
                                          swal(
                                            `P${e.target.value} is already set.`,
                                            "",
                                            "error"
                                          );
                                          return temp;
                                        }
                                      });
                                      if (proceed === false) {
                                        return;
                                      }
                                      if (e.target.value === -1) {
                                        postPriority(newdata?.id);
                                        return;
                                      }
                                      setRemarkObjectIndex(index);
                                      setRemarkModalShow(true);
                                    }}
                                  >
                                    <MenuItem value={-1}>
                                      No Priority Set
                                    </MenuItem>
                                    {[...Array(options?.length)].map(
                                      (row, index) => {
                                        return (
                                          <MenuItem value={index + 1}>
                                            P{index + 1}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                </FormControl>
                              </td>
                              <td style={{ minWidth: "200px" }}>
                                {newdata?.quote_priority === -1
                                  ? "No remarks added."
                                  : newdata?.quote_remark ??
                                    "No remarks added."}
                              </td>
                              <td>
                                <FormControl className={classes.formControl}>
                                  <Select
                                    style={{
                                      color: highlight === index ? "white" : "",
                                    }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={newdata?.approval_status}
                                    defaultValue={newdata?.approval_status}
                                    onChange={(e) => {
                                      setNewViewData((prev) => {
                                        let temp = [...prev];
                                        temp[index].approval_status =
                                          e.target.value;
                                        return temp;
                                      });
                                      postApproval(
                                        newdata?.id,
                                        e.target.value,
                                        "L" + catData[index]
                                      );
                                    }}
                                  >
                                    <MenuItem value={0}>Not selected</MenuItem>
                                    {approveData.map((row, index) => {
                                      return row.id === 0 ? (
                                        ""
                                      ) : (
                                        <MenuItem value={row.id}>
                                          {row.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </td>
                              <td>
                                {newdata?.expected_price === 0 ? (
                                  <h6 className="text-info">
                                    Revaluation is not done by
                                    <span className="text-warning">
                                      {" "}
                                      "ADMIN & VENDOR"
                                    </span>
                                  </h6>
                                ) : newdata?.discounted_price === 0 ? (
                                  <h6 className="text-info">
                                    Revaluation is not done by
                                    <span className="text-danger">
                                      {" "}
                                      "VENDOR"
                                    </span>
                                  </h6>
                                ) : (
                                  <h6 className="text-info">
                                    Revaluation is
                                    <span className="text-success">
                                      {" "}
                                      "COMPLETED"
                                    </span>
                                  </h6>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </div>
                  </table>
                  <div>
                    <Button
                      onClick={() => setModalShow(true)}
                      style={{ marginLeft: "20px", marginBottom: "20px" }}
                    >
                      Go to Revaluation
                    </Button>
                    <Button
                      onClick={() => {
                        history.push({
                          pathname: "/approvedquotes",
                          state: location?.state,
                        });
                      }}
                      style={{ marginLeft: "20px", marginBottom: "20px" }}
                    >
                      Approved Quotations
                    </Button>
                    <RevaluationModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      options={options}
                    />
                    <RemarkModal
                      show={remarkModalShow}
                      onHide={() => setRemarkModalShow(false)}
                      options={options}
                      setRemarkModalShow={setRemarkModalShow}
                      newViewData={newViewData}
                      remarkObjectIndex={remarkObjectIndex}
                      setNewViewData={setNewViewData}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const useStylesReval = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function RevaluationModal(props) {
  const history = useHistory();
  const location = useLocation();
  const classes = useStylesReval();
  const [vendorSelected, setVendorSelected] = useState("Select a vendor");
  function handleVendorChange(e) {
    setVendorSelected(e.target.value);
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Select a vendor you want to re-evaluate.
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Vendor</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={vendorSelected}
            onChange={handleVendorChange}
          >
            {props.options.map((val) => (
              <MenuItem value={val.vendor_user_id}>{val.company_name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            if (vendorSelected === "Select a vendor") {
              swal("Please select a vendor first.", "", "error");
              return;
            }
            history.push({
              pathname: "/admin-revalution",
              state: { bidId: location?.state, vendorId: vendorSelected },
            });
          }}
        >
          Re-evaluate
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function RemarkModal(props) {
  const [remarkText, setRemarkText] = useState("");
  async function postPriority() {
    const res = await axios.put("/revaluation/updateQuotePriority", {
      quote_id: props.newViewData?.[props.remarkObjectIndex]?.id,
      quote_priority:
        props.newViewData?.[props.remarkObjectIndex]?.quote_priority,
      quote_remark: remarkText,
    });
    return res;
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Please enter your remarks for this preference.
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
        <TextField
          id="remark-text"
          label="Remark"
          value={remarkText}
          onChange={(e) => setRemarkText(e.target.value)}
          multiline
        />
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={(e) => {
            console.log(props.newViewData?.[props.remarkObjectIndex]?.generic);
            console.log(
              props.newViewData?.[props.remarkObjectIndex]?.quote_priority
            );
            console.log(remarkText);
            postPriority().then((res) => {
              if (res?.data?.hasError) {
                swal(
                  "Was not able to save. Please contact the admin.",
                  "",
                  "error"
                );
              }
              swal("Posted successfully", "", "success");
            });
            props.setNewViewData((prev) => {
              const temp = [...props.newViewData];
              temp[props.remarkObjectIndex].quote_remark = remarkText;
              return temp;
            });
            setRemarkText("");
            props.setRemarkModalShow(false);
          }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default View_Quotes;
