import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import axios from "axios";
import Swal from "sweetalert2";
import { Dropdown } from "react-bootstrap";
import Select from "react-select";
import "./indextabn.css";
import { Modal } from "react-bootstrap";
import axiosInstance from "../../../../services/AxiosInstance";
/* import gavelimg from "../../../../images/gaveliconpng.png"; */

const IndexTab = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [biddeleted, setBidDeleted] = useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const history = useHistory();
  useEffect(() => {
    if (props.vendorOnly) {
      getDataForVendor().then((res) => {
        setRecentBidData(res?.data);
        setBidStatusNewData(res.data);
      });
    }

    if (props.vendorOnly === false) {
      getDataForAdmin().then((res) => {
        /*     const temp = res?.data.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        ); */
        setRecentBidData(res.data);
        setBidStatusNewData(res.data);
        /*       props.setUniqData(temp); */
      });
    }
  }, [biddeleted]);

  async function getDataForAdmin() {
    const res = await axios.get("/bid/getAllRecentBid");
    return res?.data;
  }

  async function getDataForVendor() {
    const userId = localStorage.getItem("userId");
    const res = await axios.get(`/bid/getAllRecentBidVendor/${userId}`);
    return res?.data;
  }
  const [recentBidData, setRecentBidData] = useState([]);
  console.log(recentBidData);
  const data = [
    { biddingName: "ICUBAC INJ 125MG", date: "02 Jan 2022", status: true },
    { biddingName: "ICUBAC INJ 125MG", date: "02 Jan 2022", status: true },
    { biddingName: "ICUBAC INJ 125MG", date: "02 Jan 2022", status: true },
    { biddingName: "ICUBAC INJ 125MG", date: "02 Jan 2022", status: true },
    { biddingName: "ICUBAC INJ 125MG", date: "02 Jan 2022", status: true },
    { biddingName: "ICUBAC INJ 125MG", date: "02 Jan 2022", status: true },
    { biddingName: "ICUBAC INJ 125MG", date: "02 Jan 2022", status: true },
    { biddingName: "ICUBAC INJ 125MG", date: "02 Jan 2022", status: false },
  ];
  const [showAll, setShowAll] = useState(false);
  const [bidStatus, setBidStatus] = useState({
    value: "",
    label: "All BIDS",
  });

  const [bidStatusnewdata, setBidStatusNewData] = useState(recentBidData);
  const [getmateriallist, setGetMaterialList] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const todaydatenow = new Date();
  const todaydate = new Date(todaydatenow.setDate(todaydatenow.getDate() - 1));
  const todaydatenotify = new Date(
    todaydatenow.setDate(todaydatenow.getDate() + 1)
  ).toDateString();

  const [deletemodalshow, setDeleteModalShow] = useState(false);
  const [bidtitle, setBidTitle] = useState("");
  const [bidtitlevalue, setBidTitleValue] = useState("");
  const [bidid, setBidId] = useState({ bid_id: "", bid_name: "" });

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  const deletehandleClose = () => setDeleteModalShow(false);
  const deletehandleShow = () => setDeleteModalShow(true);

  console.log(todaydate);

  console.log(todaydatenotify);

  /*   useEffect(() => {
    axios.get("/bid/getAllRecentBid").then((res) => {
      setRecentBidData(res.data.data);
      setBidStatusNewData(res.data.data);
      console.log("called api", res.data.data);
    });
  }, [props.downloadstart]); */

  const bidclosedhandle = () => {
    Swal.fire({
      icon: "warning",
      title: "BID Already Closed !",
      /*       text: '', */
      /*      footer: '<a href="">Why do I have this issue?</a>' */
    });
  };

  /*  const getMaterialListAxios = async () => {
    await axios.get("/material_category/getAllMaterialCategory").then((res) => {
      setGetMaterialList(res?.data);
      console.log(res.data);
    });
  };

  useEffect(() => {
    getMaterialListAxios();
  }, []); */

  /*   async function getMaterialListAxios() {
    const res = await axios.get("/material_category/getAllMaterialCategory");
    return res?.data;
  } */

  const getMaterialListAxios = () => {
    axios.get("/material_category/getAllMaterialCategory").then((res) => {
      console.log(res.data.data);
      setGetMaterialList(res.data.data);
    });
  };

  useEffect(() => {
    getMaterialListAxios();
  }, []);

  const [searchResult, setSearchResult] = useState({});

  const onSearchHandler = (e) => {
    console.log(e.target.value, e.target.value.length);
    setSearchResult({
      result: e.target.value.toLowerCase(),
      length: e.target.value.length,
    });
  };
  const lowersearch = searchResult?.result?.toString().toLowerCase().trim();
  console.log(lowersearch);
  const vendorsearchall = bidStatusnewdata
    ?.filter((sv) => sv.bid_description.toLowerCase().includes(lowersearch))
    .map((sitem) => {
      return sitem;
    });

  console.log(vendorsearchall);

  useEffect(() => {
    const bidprogress = props.vendorOnly
      ? recentBidData
          .filter((filp) => new Date(filp.end_date) > todaydate)
          .filter((stv) => stv.status === "inprogress")
          .filter((rev) => rev.bid_status === "0")
      : recentBidData
          .filter((filp) => new Date(filp.end_date) > todaydate)
          .filter((stv) => stv.status === "inprogress");

    const bidprogressclosed = recentBidData
      .filter((filp) => new Date(filp.end_date) < todaydate)
      .filter((stv) => stv.status === "inprogress");

    const biddraftstatus = recentBidData.filter(
      (stv) => stv.status === "draft"
    );

    const bidallstatus = recentBidData;

    const bidsubmittedv = recentBidData
      .filter((filp) => new Date(filp.end_date) > todaydate)
      .filter((rev) => rev.bid_status === "1")
      .map((sa) => sa);

    console.log(bidsubmittedv);

    setBidStatusNewData(recentBidData);

    if (bidStatus.value === "inprogress") {
      setBidStatusNewData(bidprogress);
    }

    if (bidStatus.value === "submitted") {
      console.log(bidsubmittedv);
      setBidStatusNewData(bidsubmittedv);
    }

    if (bidStatus.value === "closed") {
      setBidStatusNewData(bidprogressclosed);
    }

    if (bidStatus.value === "draft") {
      setBidStatusNewData(biddraftstatus);
    }

    if (bidStatus.value === "draft") {
      setBidStatusNewData(biddraftstatus);
    }
  }, [bidStatus.value, biddeleted]);

  console.log(bidStatusnewdata);

  console.log(selectedOption);

  const selectedLabels = selectedOption?.map((val) => {
    return val.label.toString();
  });

  const bidfilter = bidStatusnewdata?.filter((rv) =>
    rv.material_category.some((item) =>
      selectedLabels?.length > 0
        ? selectedLabels?.includes(item)
        : rv.material_category.includes(item)
    )
  );

  console.log(selectedLabels);
  console.log(bidfilter);

  const DeleteModalHandle = (e) => {
    console.log(e);
    setBidTitle(e);

    setDeleteModalShow(true);
  };

  const deleteconfirmhandle = (e) => {
    console.log(e);

    if (bidtitle.toLowerCase() !== bidtitlevalue.toLowerCase()) {
      Swal.fire({
        icon: "error",
        title: "Confirmation Failed",
        /*         text: "You have entered an invalid bid title", */
      });
      /*  setDeleteModalShow(false); */
    } else {
      axios.delete(`/bid/delete_bid/${bidid}`).then((res) => {
        console.log(res);
      });

      Swal.fire({
        icon: "success",
        title: "Bid Deleted",
      });
      setDeleteModalShow(false);

      setBidTitleValue("");
      setBidDeleted(!biddeleted);

      axios.get("/bid/getAllRecentBid").then((res) => {
        setRecentBidData(res.data.data);
        setBidStatusNewData(res.data.data);
        console.log("called api", res.data.data);
      });
    }
  };

  console.log(bidtitle);

  /*   bidtitle.toLowerCase() !== bidtitlevalue.toLowerCase() */
  const handleSelectAll = (e) => {
    props.setIsCheckAll(!props.isCheckAll);

    const bidselectall = bidfilter.map((li, i) => {
      return {
        bid_id: li.id.toString(),
        bid_name: li.bid_description,
      };
    });

    props.setIsCheck(bidselectall);
    if (props.isCheckAll) {
      props.setIsCheck([]);
    }
  };

  console.log("handleAll", props.isCheckAll);

  const handleClick = (bidtitle, e) => {
    const { id, checked } = e.target;

    console.log("handleclick", e);

    const data = {
      bid_id: id,
      bid_name: bidtitle,
    };

    props.setIsCheck([...props.isCheck, data]);

    /*     props.setExportBidIDS([...props.exportbidids, data]); */
    if (!checked) {
      props.setIsCheck(
        props.isCheck.filter((item) => item.bid_id !== id.toString())
      );
    }
  };

  const singlecheckboxid = props.isCheck?.map((val) => val.bid_id);

  console.log("isCheck", props.isCheck);

  return (
    <>
      <>
        <Modal show={deletemodalshow} onHide={deletehandleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Are you absolutely sure ?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ color: "black" }}>
              <div>
                <span>This action CANNOT be undone. This will delete the </span>
                <span
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  {bidtitle}
                </span>
                <span> bid, vendors quotations permanently.</span>
              </div>
              <div style={{ marginTop: "25px" }}>
                {" "}
                <span>To Confirm, Please type </span>
                <span style={{ color: "red", fontWeight: "bold" }}>
                  {bidtitle.toLowerCase()}
                </span>{" "}
                <span>in the box below.</span>
              </div>

              <div className="form-group">
                <input
                  type="text"
                  name=""
                  onChange={(e) => setBidTitleValue(e.target.value)}
                  className="form-control"
                  id=""
                />
              </div>

              <div className="form-group">
                <button
                  className="btn btn-danger btn-block"
                  onClick={deleteconfirmhandle}
                  style={{
                    background:
                      bidtitle.toLowerCase() !== bidtitlevalue.toLowerCase()
                        ? "grey"
                        : "red",
                    border: "none",
                  }}
                >
                  I Understand the consequences, delete this bid
                </button>
              </div>
            </div>
          </Modal.Body>
          {/*         <Modal.Footer>
            <button className="btn btn-primary" onClick={deletehandleClose}>
              Close
            </button>
            <button className="btn btn-warning" onClick={deletehandleClose}>
              Confirm
            </button>
          </Modal.Footer> */}
        </Modal>
      </>

      <div className="mb-3">
        <h4 className="fs-20" style={{ color: "grey" }}>
          {recentBidData?.length !== 0 ? "Recent Biddings" : ""}
        </h4>
        {/*  <p className="mb-0 fs-12">Lorem ipsum dolor sit amet, consectetur</p> */}
      </div>
      <div className="card col-md-12">
        <div className="card-header p-0 pt-3 d-flex justify-content-around pb-2 d-block d-sm-flex flex-wrap border-0">
          <div className="form-group col-md-4 ">
            <input
              type="text"
              placeholder="Search BID"
              name=""
              className="form-control"
              id=""
              onChange={onSearchHandler}
            />
          </div>

          <div className="form-group col-md-4">
            <Select
              name="filters"
              placeholder="Select Material Category"
              options={getmateriallist?.map((mat) => {
                return {
                  value: mat.id,
                  label: mat.material_category,
                };
              })}
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              isMulti
            />
          </div>

          <div className="" style={{ minWidth: "110px" }}>
            <div
              className="weather-btn mb-2 bg-warning"
              style={{ cursor: "pointer" }}
            >
              <span className="mr-2 fs-20 font-w600 text-black">
                <i class="fa fa-gavel" aria-hidden="true"></i> {"   "}
                {bidStatusnewdata.length}
              </span>
              <Dropdown>
                <Dropdown.Toggle
                  variant=""
                  as="div"
                  className="form-control style-1 bg-warning default-select mr-3 p-0"
                >
                  {bidStatus.label}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() =>
                      setBidStatus({ value: "", label: "All BIDS" })
                    }
                  >
                    All BIDS
                  </Dropdown.Item>

                  {props.vendorOnly ? (
                    <Dropdown.Item
                      onClick={() =>
                        setBidStatus({ value: "submitted", label: "SUBMITTED" })
                      }
                    >
                      SUBMITTED
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      onClick={() =>
                        setBidStatus({ value: "draft", label: "IN DRAFT" })
                      }
                    >
                      IN DRAFT
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item
                    onClick={() =>
                      setBidStatus({
                        value: "inprogress",
                        label: "IN PROGRESS",
                      })
                    }
                  >
                    IN PROGRESS
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      setBidStatus({ value: "closed", label: "CLOSED" })
                    }
                  >
                    CLOSED
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          {props.vendorOnly === false && (
            <div>
              <i
                id="btn-export"
                onClick={(e) =>
                  props.startbidexport("xlsx", "BidSheet.xlsx", e)
                }
                class="fa fa-cloud-download   "
                style={{
                  fontSize: "30px",
                  color: "var(--primary)",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
                aria-hidden="true"
              ></i>
            </div>
          )}
        </div>
        <div className="card-body tab-content p-0">
          <div className="tab-pane active show fade" id="monthly">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div className="table-responsive">
                  <table className="table shadow-hover  card-table border-no tbl-btn short-one text-center ">
                    <thead>
                      <tr>
                        {props.vendorOnly === false && (
                          <th>
                            {" "}
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                name="selectAll"
                                onChange={handleSelectAll}
                                className="custom-control-input"
                                id="selectAll"
                                checked={
                                  props.isCheck?.length === bidfilter?.length
                                    ? true
                                    : false
                                }
                                /*  checked={props.isCheck?.drug_id} */
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="selectAll"
                              />
                            </div>
                          </th>
                        )}

                        <th></th>

                        <th>BID Title</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Status</th>
                        {props.vendorOnly === false && <th>Actions</th>}
                      </tr>
                    </thead>
                    {/*        .filter((filv) => filv.status === bidStatus.value) */}
                    <tbody>
                      <tr className="fs-20" style={{ color: "grey" }}>
                        <td colSpan={10}>
                          {recentBidData?.length === 0
                            ? "No active bids available"
                            : ""}
                        </td>
                      </tr>
                      {searchResult.length > 0 ? (
                        <>
                          {vendorsearchall.length === 0 ? (
                            <>
                              <tr>
                                <td colSpan={8}>
                                  <h6 className="text-danger">No Bids Found</h6>
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>{""}</>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {searchResult.length > 0 ? (
                        <>
                          {vendorsearchall?.map((sitem, i) => (
                            <React.Fragment>
                              {vendorsearchall.length > 0 ? (
                                <tr className="text-center">
                                  <td>
                                    <span>
                                      <div>
                                        <div
                                          /*     className={
                                            new Date(row?.end_date) < todaydate
                                              ? "bg-danger"
                                              : "bg-success"
                                          } */
                                          style={{
                                            padding: "5px",
                                            backgroundColor: "white",
                                            borderRadius: "50%",
                                            border:
                                              new Date(sitem?.end_date) <
                                              todaydate
                                                ? "2px solid #ff4c41"
                                                : "2px solid #8bc34a",
                                          }}
                                        >
                                          <div
                                            className={
                                              new Date(sitem?.end_date) <
                                              todaydate
                                                ? "bg-danger"
                                                : "bg-success"
                                            }
                                            style={{
                                              padding: "5px",
                                              color: "white",
                                              borderRadius: "50%",
                                            }}
                                          >
                                            <i
                                              class="fa fa-gavel fa-2x"
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  </td>
                                  <td>{sitem.bid_description}</td>
                                  <td>
                                    {new Date(sitem?.start_date).toDateString()}{" "}
                                  </td>
                                  <td>
                                    {new Date(sitem?.end_date).toDateString()}{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    {new Date(sitem?.end_date) < todaydate ? (
                                      <button
                                        onClick={(e) => {
                                          if (
                                            props.vendorOnly &&
                                            new Date(sitem?.end_date) >
                                              todaydate
                                          ) {
                                            history.push({
                                              pathname: "/vendor-quote",
                                              state: sitem?.id,
                                            });
                                          }
                                        }}
                                        style={{ minWidth: "110px" }}
                                        className="btn btn-danger redclosed "
                                      >
                                        CLOSED
                                      </button>
                                    ) : (
                                      <button
                                        style={{ minWidth: "110px" }}
                                        className="btn btn-success text-white greenopen"
                                        // : "btn btn-outline-danger float-right"
                                      >
                                        {props.vendorOnly
                                          ? sitem?.bid_status === "0"
                                            ? "IN PROGRESS"
                                            : "Submitted"
                                          : sitem?.status === "inprogress"
                                          ? "IN PROGRESS"
                                          : sitem?.status === "draft"
                                          ? "DRAFT"
                                          : "Completed"}
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ) : (
                                <>
                                  <tr>
                                    <td colSpan="4">No Records Found</td>
                                  </tr>
                                </>
                              )}
                            </React.Fragment>
                          ))}
                        </>
                      ) : (
                        <>
                          {bidfilter
                            .sort((a, b) => b.id - a.id)
                            .map((row, index) => {
                              if (showAll === false) {
                                if (index >= 10) {
                                  return;
                                }
                              }

                              return (
                                <tr
                                  key={
                                    index
                                  } /* style={{ cursor: "pointer" }} */
                                >
                                  <React.Fragment />

                                  {props.vendorOnly === false && (
                                    <td>
                                      <div className="custom-control custom-checkbox ">
                                        <input
                                          type="checkbox"
                                          /*     onChange={(e) => {
                              setCheckMatID({
                                drugid: e.target.id,
                                matid: `${drug.material_category}`,
                              });
                            }} */
                                          onChange={(e) =>
                                            handleClick(row.bid_description, e)
                                          }
                                          className="custom-control-input"
                                          name={row?.id.toString()}
                                          id={row?.id.toString()}
                                          checked={singlecheckboxid?.includes(
                                            row?.id.toString()
                                          )}

                                          /*    checked={
                              props.isCheckAll
                                ? "checked"
                                : props.isCheck.drug_id?.includes(
                                    drug.id.toString()
                                  )
                            } */
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={row?.id.toString()}
                                        />
                                      </div>
                                    </td>
                                  )}

                                  <td
                                    style={{
                                      height: "60px",
                                      width: "60px",
                                      textAlign: "right",
                                    }}
                                  >
                                    {/*              <span>
          <svg
            width="63"
            height="63"
            viewBox="0 0 63 63"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="63"
              height="63"
              rx="14"
              fill="#71B945"
            />
            <path
              d="M40.6186 32.7207L40.6186 32.7207L40.6353 39.6289C40.6354 39.6328 40.6354 39.6363 40.6353 39.6396M40.6186 32.7207L40.1353 39.6341L40.6353 39.635C40.6353 39.6481 40.6347 39.6583 40.6345 39.6627L40.6344 39.6642C40.6346 39.6609 40.6351 39.652 40.6353 39.6407C40.6353 39.6403 40.6353 39.64 40.6353 39.6396M40.6186 32.7207C40.6167 31.9268 39.9717 31.2847 39.1777 31.2866C38.3838 31.2885 37.7417 31.9336 37.7436 32.7275L37.7436 32.7275L37.7519 36.1563M40.6186 32.7207L37.7519 36.1563M40.6353 39.6396C40.6329 40.4282 39.9931 41.0705 39.2017 41.0726C39.2 41.0726 39.1983 41.0727 39.1965 41.0727L39.1944 41.0727L39.1773 41.0726L32.2834 41.056L32.2846 40.556L32.2834 41.056C31.4897 41.054 30.8474 40.4091 30.8494 39.615C30.8513 38.8211 31.4964 38.179 32.2903 38.1809L32.2903 38.1809L35.719 38.1892L22.5364 25.0066C21.975 24.4452 21.975 23.5351 22.5364 22.9737C23.0978 22.4123 24.0079 22.4123 24.5693 22.9737L37.7519 36.1563M40.6353 39.6396C40.6353 39.6376 40.6353 39.6356 40.6353 39.6336L37.7519 36.1563M39.1964 41.0726C39.1957 41.0726 39.1951 41.0726 39.1944 41.0726L39.1964 41.0726Z"
              fill="white"
              stroke="white"
            />
          </svg>
        </span> */}

                                    <span>
                                      <div>
                                        <div
                                          /*     className={
                                            new Date(row?.end_date) < todaydate
                                              ? "bg-danger"
                                              : "bg-success"
                                          } */
                                          style={{
                                            padding: "5px",
                                            backgroundColor: "white",
                                            borderRadius: "50%",
                                            border:
                                              new Date(row?.end_date) <
                                              todaydate
                                                ? "2px solid #ff4c41"
                                                : "2px solid #8bc34a",
                                          }}
                                        >
                                          <div
                                            className={
                                              new Date(row?.end_date) <
                                              todaydate
                                                ? "bg-danger"
                                                : "bg-success"
                                            }
                                            style={{
                                              padding: "5px",
                                              color: "white",
                                              borderRadius: "50%",
                                            }}
                                          >
                                            <i
                                              class="fa fa-gavel fa-2x"
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        </div>
                                      </div>
                                    </span>

                                    {/*      <img
                                      src={gavelimg}
                                      alt=""
                                      width="50"
                                      height="50"
                                    /> */}
                                  </td>
                                  <td
                                    style={{
                                      maxWidth: "227px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <span className="font-w600 text-black">
                                      {row?.bid_description}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="text-black">
                                      {new Date(row?.start_date).toDateString()}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="text-black">
                                      {new Date(row?.end_date).toDateString()}
                                      <br></br>
                                      {new Date(
                                        row?.end_date
                                      ).toDateString() === todaydatenotify ? (
                                        <span className="text-danger">
                                          Bid Will Be Closed Today
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </td>
                                  <td>
                                    {new Date(row?.end_date) < todaydate ? (
                                      <button
                                        onClick={(e) => {
                                          if (props.vendorOnly) {
                                            if (
                                              new Date(row?.end_date) >
                                              todaydate
                                            ) {
                                              history.push({
                                                pathname: "/vendor-quote",
                                                state: row?.id,
                                              });
                                            } else {
                                              bidclosedhandle();
                                            }
                                          }

                                          if (props.vendorOnly === false) {
                                            history.push({
                                              pathname: "/viewQuotes",
                                              state: row?.id,
                                            });
                                          }
                                        }}
                                        /*       onClick={(e) => {
                                          if (
                                            props.vendorOnly &&
                                            new Date(row?.end_date) > todaydate
                                          ) {
                                            history.push({
                                              pathname: "/vendor-quote",
                                              state: row?.id,
                                            });
                                          }
                                        }} */
                                        style={{
                                          minWidth: "110px",
                                          background: "orange",
                                          color: "white",
                                        }}
                                        className="btn  redclosed btn-sm"
                                      >
                                        CLOSED
                                      </button>
                                    ) : (
                                      <button
                                        style={{ minWidth: "110px" }}
                                        className="btn btn-success btn-sm text-white greenopen"
                                        // : "btn btn-outline-danger float-right"
                                        onClick={(e) => {
                                          if (props.vendorOnly) {
                                            if (
                                              new Date(row?.end_date) >
                                              todaydate
                                            ) {
                                              history.push({
                                                pathname: "/vendor-quote",
                                                state: row?.id,
                                              });
                                            } else {
                                              bidclosedhandle();
                                            }
                                          }

                                          if (props.vendorOnly === false) {
                                            history.push({
                                              pathname: "/viewQuotes",
                                              state: row?.id,
                                            });
                                          }
                                        }}
                                      >
                                        {props.vendorOnly
                                          ? row?.bid_status === "0"
                                            ? "IN PROGRESS"
                                            : "Submitted"
                                          : row?.status === "inprogress"
                                          ? "IN PROGRESS"
                                          : row?.status === "draft"
                                          ? "DRAFT"
                                          : "Completed"}
                                      </button>
                                    )}
                                  </td>

                                  {props.vendorOnly === false && (
                                    <td>
                                      <button
                                        className="btn btn-danger btn-sm"
                                        onClick={() => {
                                          DeleteModalHandle(
                                            row?.bid_description
                                          );
                                          setBidId(row?.id);
                                        }}
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Delete
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="table-responsive">
                  <table className="table shadow-hover card-table border-no tbl-btn short-one">
                    <tbody>
                      <tr>
                        <td>
                          <span>
                            <svg
                              width="63"
                              height="63"
                              viewBox="0 0 63 63"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="63"
                                height="63"
                                rx="14"
                                fill="#71B945"
                              />
                              <path
                                d="M40.6186 32.7207L40.6186 32.7207L40.6353 39.6289C40.6354 39.6328 40.6354 39.6363 40.6353 39.6396M40.6186 32.7207L40.1353 39.6341L40.6353 39.635C40.6353 39.6481 40.6347 39.6583 40.6345 39.6627L40.6344 39.6642C40.6346 39.6609 40.6351 39.652 40.6353 39.6407C40.6353 39.6403 40.6353 39.64 40.6353 39.6396M40.6186 32.7207C40.6167 31.9268 39.9717 31.2847 39.1777 31.2866C38.3838 31.2885 37.7417 31.9336 37.7436 32.7275L37.7436 32.7275L37.7519 36.1563M40.6186 32.7207L37.7519 36.1563M40.6353 39.6396C40.6329 40.4282 39.9931 41.0705 39.2017 41.0726C39.2 41.0726 39.1983 41.0727 39.1965 41.0727L39.1944 41.0727L39.1773 41.0726L32.2834 41.056L32.2846 40.556L32.2834 41.056C31.4897 41.054 30.8474 40.4091 30.8494 39.615C30.8513 38.8211 31.4964 38.179 32.2903 38.1809L32.2903 38.1809L35.719 38.1892L22.5364 25.0066C21.975 24.4452 21.975 23.5351 22.5364 22.9737C23.0978 22.4123 24.0079 22.4123 24.5693 22.9737L37.7519 36.1563M40.6353 39.6396C40.6353 39.6376 40.6353 39.6356 40.6353 39.6336L37.7519 36.1563M39.1964 41.0726C39.1957 41.0726 39.1951 41.0726 39.1944 41.0726L39.1964 41.0726Z"
                                fill="white"
                                stroke="white"
                              />
                            </svg>
                          </span>
                        </td>
                        <td className="wspace-no">
                          <svg
                            className="mr-1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 0C5.3726 0 0 5.3726 0 12C0 18.6274 5.3726 24 12 24C18.6274 24 24 18.6274 24 12C23.9924 5.37574 18.6243 0.00758581 12 0ZM16.2857 18.0001H9.42866C8.9552 18.0001 8.57147 17.6164 8.57147 17.1429C8.57147 17.1024 8.57434 17.0618 8.5801 17.0216L9.22515 12.5054L7.92222 12.8313C7.85421 12.8486 7.78437 12.8572 7.71427 12.8572C7.24081 12.8567 6.85759 12.4727 6.85785 11.9992C6.85838 11.6063 7.12571 11.2642 7.50683 11.1684L9.48674 10.6735L10.2942 5.0213C10.3612 4.55254 10.7954 4.22714 11.2642 4.2941C11.7329 4.36107 12.0583 4.79529 11.9914 5.26404L11.2825 10.2247L14.3636 9.4543C14.8222 9.33737 15.2886 9.61439 15.4053 10.0729C15.5222 10.5315 15.2452 10.9979 14.7866 11.1148C14.784 11.1153 14.7814 11.1161 14.7788 11.1166L11.0204 12.0562L10.4164 16.2857H16.2857C16.7592 16.2857 17.1429 16.6695 17.1429 17.1429C17.1429 17.6161 16.7592 18.0001 16.2857 18.0001Z"
                              fill="#5F5F5F"
                            />
                          </svg>
                          <span className="font-w600 text-black">Litecoin</span>
                        </td>
                        <td>
                          <span className="text-black">06:24:45 AM</span>
                        </td>
                        <td>
                          <span className="font-w600 text-black">+$7,762</span>
                        </td>
                        <td>
                          <Link
                            to={"#"}
                            className="btn btn-outline-success float-right"
                          >
                            Completed
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            <svg
                              width="63"
                              height="63"
                              viewBox="0 0 63 63"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="63"
                                height="63"
                                rx="14"
                                fill="#71B945"
                              />
                              <path
                                d="M40.6186 32.7207L40.6186 32.7207L40.6353 39.6289C40.6354 39.6328 40.6354 39.6363 40.6353 39.6396M40.6186 32.7207L40.1353 39.6341L40.6353 39.635C40.6353 39.6481 40.6347 39.6583 40.6345 39.6627L40.6344 39.6642C40.6346 39.6609 40.6351 39.652 40.6353 39.6407C40.6353 39.6403 40.6353 39.64 40.6353 39.6396M40.6186 32.7207C40.6167 31.9268 39.9717 31.2847 39.1777 31.2866C38.3838 31.2885 37.7417 31.9336 37.7436 32.7275L37.7436 32.7275L37.7519 36.1563M40.6186 32.7207L37.7519 36.1563M40.6353 39.6396C40.6329 40.4282 39.9931 41.0705 39.2017 41.0726C39.2 41.0726 39.1983 41.0727 39.1965 41.0727L39.1944 41.0727L39.1773 41.0726L32.2834 41.056L32.2846 40.556L32.2834 41.056C31.4897 41.054 30.8474 40.4091 30.8494 39.615C30.8513 38.8211 31.4964 38.179 32.2903 38.1809L32.2903 38.1809L35.719 38.1892L22.5364 25.0066C21.975 24.4452 21.975 23.5351 22.5364 22.9737C23.0978 22.4123 24.0079 22.4123 24.5693 22.9737L37.7519 36.1563M40.6353 39.6396C40.6353 39.6376 40.6353 39.6356 40.6353 39.6336L37.7519 36.1563M39.1964 41.0726C39.1957 41.0726 39.1951 41.0726 39.1944 41.0726L39.1964 41.0726Z"
                                fill="white"
                                stroke="white"
                              />
                            </svg>
                          </span>
                        </td>
                        <td className="wspace-no">
                          <svg
                            className="mr-2"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16 9.50011C15.9992 8.67201 15.216 8.00092 14.2501 8H9V11H14.2501C15.216 10.9993 15.9992 10.328 16 9.50011Z"
                              fill="#FFAB2D"
                            />
                            <path
                              d="M9 16H14.2501C15.2165 16 16 15.3285 16 14.5001C16 13.6715 15.2165 13 14.2501 13H9V16Z"
                              fill="#FFAB2D"
                            />
                            <path
                              d="M12 0C5.3726 0 0 5.3726 0 12C0 18.6274 5.3726 24 12 24C18.6274 24 24 18.6274 24 12C23.9924 5.37574 18.6243 0.00758581 12 0ZM18.0001 14.5713C17.9978 16.4641 16.4641 17.9978 14.5716 17.9999V18.8571C14.5716 19.3305 14.1876 19.7143 13.7144 19.7143C13.2409 19.7143 12.8572 19.3305 12.8572 18.8571V17.9999H11.1431V18.8571C11.1431 19.3305 10.7591 19.7143 10.2859 19.7143C9.8124 19.7143 9.42866 19.3305 9.42866 18.8571V17.9999H6.85733C6.38387 17.9999 6.00013 17.6161 6.00013 17.1429C6.00013 16.6695 6.38387 16.2857 6.85733 16.2857H7.71427V7.71427H6.85733C6.38387 7.71427 6.00013 7.33053 6.00013 6.85707C6.00013 6.38361 6.38387 5.99987 6.85733 5.99987H9.42866V5.14293C9.42866 4.66947 9.8124 4.28573 10.2859 4.28573C10.7593 4.28573 11.1431 4.66947 11.1431 5.14293V5.99987H12.8572V5.14293C12.8572 4.66947 13.2409 4.28573 13.7144 4.28573C14.1879 4.28573 14.5716 4.66947 14.5716 5.14293V5.99987C16.4571 5.99202 17.992 7.5139 18.0001 9.39937C18.0043 10.3978 17.5714 11.3481 16.8152 12C17.5643 12.6445 17.9967 13.5828 18.0001 14.5713Z"
                              fill="#FFAB2D"
                            />
                          </svg>
                          <span className="font-w600 text-black">Bitcoin</span>
                        </td>
                        <td>
                          <span className="text-black">06:24:45 AM</span>
                        </td>
                        <td>
                          <span className="font-w600 text-black">+$5,553</span>
                        </td>
                        <td>
                          <Link
                            to={"#"}
                            className="btn btn-outline-success float-right"
                          >
                            Completed
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            <svg
                              width="63"
                              height="63"
                              viewBox="0 0 63 63"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="63"
                                height="63"
                                rx="14"
                                fill="#FF5757"
                              />
                              <path
                                d="M22.1318 30.9043L22.1318 30.9043L22.1151 23.9961C22.1151 23.9922 22.1151 23.9887 22.1152 23.9854M22.1318 30.9043L22.6152 23.9909L22.1152 23.99C22.1152 23.9769 22.1157 23.9667 22.116 23.9623L22.1161 23.9608C22.1159 23.9641 22.1154 23.973 22.1152 23.9843C22.1152 23.9847 22.1152 23.985 22.1152 23.9854M22.1318 30.9043C22.1338 31.6982 22.7788 32.3403 23.5728 32.3384C24.3667 32.3365 25.0088 31.6914 25.0069 30.8975L25.0069 30.8975L24.9986 27.4687M22.1318 30.9043L24.9986 27.4687M22.1152 23.9854C22.1176 23.1968 22.7574 22.5545 23.5488 22.5524C23.5504 22.5524 23.5522 22.5523 23.554 22.5523L23.5561 22.5523L23.5732 22.5524L30.4671 22.569L30.4658 23.069L30.4671 22.569C31.2608 22.571 31.903 23.2159 31.9011 24.01C31.8992 24.8039 31.2541 25.446 30.4602 25.4441L30.4602 25.4441L27.0315 25.4358L40.2141 38.6184C40.7755 39.1798 40.7755 40.0899 40.2141 40.6513C39.6527 41.2127 38.7426 41.2127 38.1812 40.6513L24.9986 27.4687M22.1152 23.9854C22.1152 23.9874 22.1152 23.9894 22.1152 23.9914L24.9986 27.4687M23.5541 22.5524C23.5547 22.5524 23.5554 22.5524 23.5561 22.5524L23.5541 22.5524Z"
                                fill="white"
                                stroke="white"
                              />
                            </svg>
                          </span>
                        </td>
                        <td className="wspace-no">
                          <svg
                            className="mr-1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.2161 0.00189099C5.59822 -0.114662 0.118718 5.17136 0.00213245 11.7875C-0.0369579 13.869 0.462987 15.8373 1.36961 17.5623H5.14217V5.75207C5.14217 5.03013 6.12354 4.80045 6.44244 5.44835L12.0001 16.6998L17.5578 5.44903C17.8767 4.80045 18.8581 5.03013 18.8581 5.75207V17.5623H22.619C23.4625 15.9594 23.9645 14.1494 23.9981 12.2194C24.1147 5.60329 18.8341 0.1253 12.2161 0.00189099Z"
                              fill="#FF782C"
                            />
                            <path
                              d="M17.4865 18.2479V8.6892L12.6146 18.5516C12.3842 19.0199 11.6154 19.0199 11.385 18.5516L6.51376 8.6892V18.2479C6.51376 18.6264 6.20721 18.9335 5.82796 18.9335H2.22685C4.36036 21.9303 7.82911 23.9241 11.7841 23.9981C15.8968 24.0708 19.5672 22.0551 21.7871 18.9335H18.1723C17.793 18.9335 17.4865 18.6264 17.4865 18.2479Z"
                              fill="#FF782C"
                            />
                          </svg>
                          <span className="font-w600 text-black">Monero</span>
                        </td>
                        <td>
                          <span className="text-black">06:24:45 AM</span>
                        </td>
                        <td>
                          <span className="font-w600 text-black">-$912</span>
                        </td>
                        <td>
                          <Link
                            to={"#"}
                            className="btn btn-outline-danger float-right"
                          >
                            Canceled
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </TabPane>
              <TabPane tabId="3">
                <div className="table-responsive">
                  <table className="table shadow-hover card-table border-no tbl-btn short-one">
                    <tbody>
                      <tr>
                        <td>
                          <span>
                            <svg
                              width="63"
                              height="63"
                              viewBox="0 0 63 63"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="63"
                                height="63"
                                rx="14"
                                fill="#FF5757"
                              />
                              <path
                                d="M22.1318 30.9043L22.1318 30.9043L22.1151 23.9961C22.1151 23.9922 22.1151 23.9887 22.1152 23.9854M22.1318 30.9043L22.6152 23.9909L22.1152 23.99C22.1152 23.9769 22.1157 23.9667 22.116 23.9623L22.1161 23.9608C22.1159 23.9641 22.1154 23.973 22.1152 23.9843C22.1152 23.9847 22.1152 23.985 22.1152 23.9854M22.1318 30.9043C22.1338 31.6982 22.7788 32.3403 23.5728 32.3384C24.3667 32.3365 25.0088 31.6914 25.0069 30.8975L25.0069 30.8975L24.9986 27.4687M22.1318 30.9043L24.9986 27.4687M22.1152 23.9854C22.1176 23.1968 22.7574 22.5545 23.5488 22.5524C23.5504 22.5524 23.5522 22.5523 23.554 22.5523L23.5561 22.5523L23.5732 22.5524L30.4671 22.569L30.4658 23.069L30.4671 22.569C31.2608 22.571 31.903 23.2159 31.9011 24.01C31.8992 24.8039 31.2541 25.446 30.4602 25.4441L30.4602 25.4441L27.0315 25.4358L40.2141 38.6184C40.7755 39.1798 40.7755 40.0899 40.2141 40.6513C39.6527 41.2127 38.7426 41.2127 38.1812 40.6513L24.9986 27.4687M22.1152 23.9854C22.1152 23.9874 22.1152 23.9894 22.1152 23.9914L24.9986 27.4687M23.5541 22.5524C23.5547 22.5524 23.5554 22.5524 23.5561 22.5524L23.5541 22.5524Z"
                                fill="white"
                                stroke="white"
                              />
                            </svg>
                          </span>
                        </td>
                        <td className="wspace-no">
                          <svg
                            className="mr-2"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.2161 0.00189099C5.59822 -0.114662 0.118718 5.17136 0.00213245 11.7875C-0.0369579 13.869 0.462987 15.8373 1.36961 17.5623H5.14217V5.75207C5.14217 5.03013 6.12354 4.80045 6.44244 5.44835L12.0001 16.6998L17.5578 5.44903C17.8767 4.80045 18.8581 5.03013 18.8581 5.75207V17.5623H22.619C23.4625 15.9594 23.9645 14.1494 23.9981 12.2194C24.1147 5.60329 18.8341 0.1253 12.2161 0.00189099Z"
                              fill="#FF782C"
                            />
                            <path
                              d="M17.4865 18.2479V8.6892L12.6146 18.5516C12.3842 19.0199 11.6154 19.0199 11.385 18.5516L6.51376 8.6892V18.2479C6.51376 18.6264 6.20721 18.9335 5.82796 18.9335H2.22685C4.36036 21.9303 7.82911 23.9241 11.7841 23.9981C15.8968 24.0708 19.5672 22.0551 21.7871 18.9335H18.1723C17.793 18.9335 17.4865 18.6264 17.4865 18.2479Z"
                              fill="#FF782C"
                            />
                          </svg>
                          <span className="font-w600 text-black">Monero</span>
                        </td>
                        <td>
                          <span className="text-black">06:24:45 AM</span>
                        </td>
                        <td>
                          <span className="font-w600 text-black">-$912</span>
                        </td>
                        <td>
                          <Link
                            to={"#"}
                            className="btn btn-outline-danger float-right"
                          >
                            Canceled
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            <svg
                              width="63"
                              height="63"
                              viewBox="0 0 63 63"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="63"
                                height="63"
                                rx="14"
                                fill="#FF5757"
                              />
                              <path
                                d="M22.1318 30.9043L22.1318 30.9043L22.1151 23.9961C22.1151 23.9922 22.1151 23.9887 22.1152 23.9854M22.1318 30.9043L22.6152 23.9909L22.1152 23.99C22.1152 23.9769 22.1157 23.9667 22.116 23.9623L22.1161 23.9608C22.1159 23.9641 22.1154 23.973 22.1152 23.9843C22.1152 23.9847 22.1152 23.985 22.1152 23.9854M22.1318 30.9043C22.1338 31.6982 22.7788 32.3403 23.5728 32.3384C24.3667 32.3365 25.0088 31.6914 25.0069 30.8975L25.0069 30.8975L24.9986 27.4687M22.1318 30.9043L24.9986 27.4687M22.1152 23.9854C22.1176 23.1968 22.7574 22.5545 23.5488 22.5524C23.5504 22.5524 23.5522 22.5523 23.554 22.5523L23.5561 22.5523L23.5732 22.5524L30.4671 22.569L30.4658 23.069L30.4671 22.569C31.2608 22.571 31.903 23.2159 31.9011 24.01C31.8992 24.8039 31.2541 25.446 30.4602 25.4441L30.4602 25.4441L27.0315 25.4358L40.2141 38.6184C40.7755 39.1798 40.7755 40.0899 40.2141 40.6513C39.6527 41.2127 38.7426 41.2127 38.1812 40.6513L24.9986 27.4687M22.1152 23.9854C22.1152 23.9874 22.1152 23.9894 22.1152 23.9914L24.9986 27.4687M23.5541 22.5524C23.5547 22.5524 23.5554 22.5524 23.5561 22.5524L23.5541 22.5524Z"
                                fill="white"
                                stroke="white"
                              />
                            </svg>
                          </span>
                        </td>
                        <td className="wspace-no">
                          <svg
                            className="mr-1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.2161 0.00189099C5.59822 -0.114662 0.118718 5.17136 0.00213245 11.7875C-0.0369579 13.869 0.462987 15.8373 1.36961 17.5623H5.14217V5.75207C5.14217 5.03013 6.12354 4.80045 6.44244 5.44835L12.0001 16.6998L17.5578 5.44903C17.8767 4.80045 18.8581 5.03013 18.8581 5.75207V17.5623H22.619C23.4625 15.9594 23.9645 14.1494 23.9981 12.2194C24.1147 5.60329 18.8341 0.1253 12.2161 0.00189099Z"
                              fill="#FF782C"
                            />
                            <path
                              d="M17.4865 18.2479V8.6892L12.6146 18.5516C12.3842 19.0199 11.6154 19.0199 11.385 18.5516L6.51376 8.6892V18.2479C6.51376 18.6264 6.20721 18.9335 5.82796 18.9335H2.22685C4.36036 21.9303 7.82911 23.9241 11.7841 23.9981C15.8968 24.0708 19.5672 22.0551 21.7871 18.9335H18.1723C17.793 18.9335 17.4865 18.6264 17.4865 18.2479Z"
                              fill="#FF782C"
                            />
                          </svg>
                          <span className="font-w600 text-black">Monero</span>
                        </td>
                        <td>
                          <span className="text-black">06:24:45 AM</span>
                        </td>
                        <td>
                          <span className="font-w600 text-black">-$912</span>
                        </td>
                        <td>
                          <Link
                            to={"#"}
                            className="btn btn-outline-danger float-right"
                          >
                            Canceled
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            <svg
                              width="63"
                              height="63"
                              viewBox="0 0 63 63"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="63"
                                height="63"
                                rx="14"
                                fill="#71B945"
                              />
                              <path
                                d="M40.6186 32.7207L40.6186 32.7207L40.6353 39.6289C40.6354 39.6328 40.6354 39.6363 40.6353 39.6396M40.6186 32.7207L40.1353 39.6341L40.6353 39.635C40.6353 39.6481 40.6347 39.6583 40.6345 39.6627L40.6344 39.6642C40.6346 39.6609 40.6351 39.652 40.6353 39.6407C40.6353 39.6403 40.6353 39.64 40.6353 39.6396M40.6186 32.7207C40.6167 31.9268 39.9717 31.2847 39.1777 31.2866C38.3838 31.2885 37.7417 31.9336 37.7436 32.7275L37.7436 32.7275L37.7519 36.1563M40.6186 32.7207L37.7519 36.1563M40.6353 39.6396C40.6329 40.4282 39.9931 41.0705 39.2017 41.0726C39.2 41.0726 39.1983 41.0727 39.1965 41.0727L39.1944 41.0727L39.1773 41.0726L32.2834 41.056L32.2846 40.556L32.2834 41.056C31.4897 41.054 30.8474 40.4091 30.8494 39.615C30.8513 38.8211 31.4964 38.179 32.2903 38.1809L32.2903 38.1809L35.719 38.1892L22.5364 25.0066C21.975 24.4452 21.975 23.5351 22.5364 22.9737C23.0978 22.4123 24.0079 22.4123 24.5693 22.9737L37.7519 36.1563M40.6353 39.6396C40.6353 39.6376 40.6353 39.6356 40.6353 39.6336L37.7519 36.1563M39.1964 41.0726C39.1957 41.0726 39.1951 41.0726 39.1944 41.0726L39.1964 41.0726Z"
                                fill="white"
                                stroke="white"
                              />
                            </svg>
                          </span>
                        </td>
                        <td className="wspace-no">
                          <svg
                            className="mr-1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 0C5.3726 0 0 5.3726 0 12C0 18.6274 5.3726 24 12 24C18.6274 24 24 18.6274 24 12C23.9924 5.37574 18.6243 0.00758581 12 0ZM16.2857 18.0001H9.42866C8.9552 18.0001 8.57147 17.6164 8.57147 17.1429C8.57147 17.1024 8.57434 17.0618 8.5801 17.0216L9.22515 12.5054L7.92222 12.8313C7.85421 12.8486 7.78437 12.8572 7.71427 12.8572C7.24081 12.8567 6.85759 12.4727 6.85785 11.9992C6.85838 11.6063 7.12571 11.2642 7.50683 11.1684L9.48674 10.6735L10.2942 5.0213C10.3612 4.55254 10.7954 4.22714 11.2642 4.2941C11.7329 4.36107 12.0583 4.79529 11.9914 5.26404L11.2825 10.2247L14.3636 9.4543C14.8222 9.33737 15.2886 9.61439 15.4053 10.0729C15.5222 10.5315 15.2452 10.9979 14.7866 11.1148C14.784 11.1153 14.7814 11.1161 14.7788 11.1166L11.0204 12.0562L10.4164 16.2857H16.2857C16.7592 16.2857 17.1429 16.6695 17.1429 17.1429C17.1429 17.6161 16.7592 18.0001 16.2857 18.0001Z"
                              fill="#5F5F5F"
                            />
                          </svg>
                          <span className="font-w600 text-black">Litecoin</span>
                        </td>
                        <td>
                          <span className="text-black">06:24:45 AM</span>
                        </td>
                        <td>
                          <span className="font-w600 text-black">+$7,762</span>
                        </td>
                        <td>
                          <Link
                            to={"#"}
                            className="btn btn-outline-success float-right"
                          >
                            Completed
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>
                            <svg
                              width="63"
                              height="63"
                              viewBox="0 0 63 63"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="63"
                                height="63"
                                rx="14"
                                fill="#71B945"
                              />
                              <path
                                d="M40.6186 32.7207L40.6186 32.7207L40.6353 39.6289C40.6354 39.6328 40.6354 39.6363 40.6353 39.6396M40.6186 32.7207L40.1353 39.6341L40.6353 39.635C40.6353 39.6481 40.6347 39.6583 40.6345 39.6627L40.6344 39.6642C40.6346 39.6609 40.6351 39.652 40.6353 39.6407C40.6353 39.6403 40.6353 39.64 40.6353 39.6396M40.6186 32.7207C40.6167 31.9268 39.9717 31.2847 39.1777 31.2866C38.3838 31.2885 37.7417 31.9336 37.7436 32.7275L37.7436 32.7275L37.7519 36.1563M40.6186 32.7207L37.7519 36.1563M40.6353 39.6396C40.6329 40.4282 39.9931 41.0705 39.2017 41.0726C39.2 41.0726 39.1983 41.0727 39.1965 41.0727L39.1944 41.0727L39.1773 41.0726L32.2834 41.056L32.2846 40.556L32.2834 41.056C31.4897 41.054 30.8474 40.4091 30.8494 39.615C30.8513 38.8211 31.4964 38.179 32.2903 38.1809L32.2903 38.1809L35.719 38.1892L22.5364 25.0066C21.975 24.4452 21.975 23.5351 22.5364 22.9737C23.0978 22.4123 24.0079 22.4123 24.5693 22.9737L37.7519 36.1563M40.6353 39.6396C40.6353 39.6376 40.6353 39.6356 40.6353 39.6336L37.7519 36.1563M39.1964 41.0726C39.1957 41.0726 39.1951 41.0726 39.1944 41.0726L39.1964 41.0726Z"
                                fill="white"
                                stroke="white"
                              />
                            </svg>
                          </span>
                        </td>
                        <td className="wspace-no">
                          <svg
                            className="mr-2"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16 9.50011C15.9992 8.67201 15.216 8.00092 14.2501 8H9V11H14.2501C15.216 10.9993 15.9992 10.328 16 9.50011Z"
                              fill="#FFAB2D"
                            />
                            <path
                              d="M9 16H14.2501C15.2165 16 16 15.3285 16 14.5001C16 13.6715 15.2165 13 14.2501 13H9V16Z"
                              fill="#FFAB2D"
                            />
                            <path
                              d="M12 0C5.3726 0 0 5.3726 0 12C0 18.6274 5.3726 24 12 24C18.6274 24 24 18.6274 24 12C23.9924 5.37574 18.6243 0.00758581 12 0ZM18.0001 14.5713C17.9978 16.4641 16.4641 17.9978 14.5716 17.9999V18.8571C14.5716 19.3305 14.1876 19.7143 13.7144 19.7143C13.2409 19.7143 12.8572 19.3305 12.8572 18.8571V17.9999H11.1431V18.8571C11.1431 19.3305 10.7591 19.7143 10.2859 19.7143C9.8124 19.7143 9.42866 19.3305 9.42866 18.8571V17.9999H6.85733C6.38387 17.9999 6.00013 17.6161 6.00013 17.1429C6.00013 16.6695 6.38387 16.2857 6.85733 16.2857H7.71427V7.71427H6.85733C6.38387 7.71427 6.00013 7.33053 6.00013 6.85707C6.00013 6.38361 6.38387 5.99987 6.85733 5.99987H9.42866V5.14293C9.42866 4.66947 9.8124 4.28573 10.2859 4.28573C10.7593 4.28573 11.1431 4.66947 11.1431 5.14293V5.99987H12.8572V5.14293C12.8572 4.66947 13.2409 4.28573 13.7144 4.28573C14.1879 4.28573 14.5716 4.66947 14.5716 5.14293V5.99987C16.4571 5.99202 17.992 7.5139 18.0001 9.39937C18.0043 10.3978 17.5714 11.3481 16.8152 12C17.5643 12.6445 17.9967 13.5828 18.0001 14.5713Z"
                              fill="#FFAB2D"
                            />
                          </svg>
                          <span className="font-w600 text-black">Bitcoin</span>
                        </td>
                        <td>
                          <span className="text-black">06:24:45 AM</span>
                        </td>
                        <td>
                          <span className="font-w600 text-black">+$5,553</span>
                        </td>
                        <td>
                          <Link
                            to={"#"}
                            className="btn btn-outline-success float-right"
                          >
                            Completed
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
        {recentBidData?.length < 10 ? (
          ""
        ) : (
          <div
            className="card-footer border-0 p-0 caret mt-1"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="btn btn-primary"
              onClick={(e) => setShowAll(!showAll)}
              style={{ marginBottom: "-25px" }}
            >
              <i
                className={showAll ? "fa fa-caret-up" : "fa fa-caret-down"}
                aria-hidden="true"
              ></i>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default IndexTab;
