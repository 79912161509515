import { Pagination } from "react-bootstrap";
import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle";
import axios from 'axios';
import EditRole from "../WireFrames/EditRole/EditRole";

export default function Roles() {
  const [data, setData] = useState(
    document.querySelectorAll("#patientTable_basic_table tbody tr")
  );
  const history = useHistory();


  const sort = 5;
  const activePag = useRef(0);
  const [test, settest] = useState(0);
  const [roles, setRole] = useState([]);


  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#patientTable_basic_table tbody tr"));
    //chackboxFun();
  }, [test]);



  // const [active, setActive] = useState(1);
  // let items = [];
  // for (let number = 1; number <= 5; number++) {
  //   items.push(
  //     <Pagination.Item
  //       key={number}
  //       active={number === active}
  //       onClick={(e) => setActive(number)}
  //     >
  //       {number}
  //     </Pagination.Item>
  //   );
  // }


  const [errorExist, setErrors] = useState(false);

  const getAllRole = () => {
    axios.get('/setting/getAllRole')
      .then((response) => {
        console.log(response.data);
        setRole(response.data.data);

        if (response.data.data.length === 0) {

          setErrors(true);
          console.log(response.data.data.length, "length")
        }
        else {

          setErrors(false);
          console.log(response.data.data.length, "length")

        }

      })
      .catch(function (response) {
        console.log(response.data)
      })
  }

  useEffect(() => {
    getAllRole();
  }, [])


  //delete by id
  const RoleDeleteHandle = (id) => {
    console.log("delete role id " + id);

    swal({
      title: "Are you sure? You want to delete this role",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios.delete(`/setting/delete_role/${id}`)
          .then(function (response) {
            //handle success
            console.log(response);
            getAllRole();
            swal("Role has been deleted!", {
              icon: "success",
            });

          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
        console.log(id + " deleted this role")
      }
    });
  };



  return (
    <>
     <div className="my-0 p-0">
          <span className="text-primary font-weight-bold" style={{cursor:"pointer"}}>Settings  </span>/{" "} Roles
     </div>
      <div className="row">
        <div className="col-md-12 my-4">
          {/* <h4 className="px-3">Users</h4> */}
          <div className="card ">
            <div className="card-header ">
              {/* <h3>Vendor Maste</h3> */}
              <div
                className="col-sm-12"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <i
                  className="fa fa-plus-circle"
                  style={{
                    fontSize: "30px",
                    color: "var(--primary)",
                    cursor: "pointer",
                  }}
                  onClick={(e) => history.push("/add-role")}
                ></i>
              </div>

            </div>
            <div className="card-body py-1">
              <div className="w-100 table-responsive">
                <div
                  id="patientTable_basic_table"
                  className="dataTables_wrapper"
                >
                  <table className="table shadow-hover card-table border-no tbl-btn text-left text-black">
                    <thead>
                      <tr>
                        <th>Role Name</th>
                        <th>Permission</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {errorExist ? (
                        <tr>
                          <td colSpan={9} className="text-center text-danger">Role has been not created yet</td>
                        </tr>
                      ) : (

                        roles.map((val, index) => (
                          <tr key={index}>
                            <td>{val.role_name}</td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  fontWeight: ""
                                }}
                              >
                                {val.permission.map((item, index) => {
                                  return index === 0 ? item : ", " + item
                                })}
                              </div>
                            </td>

                            <td>
                              <div className="d-flex">
                                <button
                                  onClick={(e) => history.push({
                                    pathname: "/editrole",
                                    state: { id: val.id }
                                  })}
                                  className="btn btn-warning shadow btn-xs sharp mr-1"
                                >
                                  <i className="fa fa-pencil"></i>
                                </button>
                                <button
                                  onClick={(e) => RoleDeleteHandle(val.id)}
                                  className="btn btn-danger shadow btn-xs sharp"
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      )
                      }
                    </tbody>

                  </table>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    {/* <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="example5_paginate"
                    >
                      <Link
                        className="paginate_button previous disabled"
                        to="/settings/roles"
                        onClick={() =>
                          activePag.current > 0 && onClick(activePag.current - 1)
                        }
                      >
                        {"<"}
                      </Link>
                      <span>
                        {paggination.map((number, i) => (
                          <Link
                            key={i}
                            to="/settings/roles"
                            className={`paginate_button  ${activePag.current === i ? "current" : ""
                              } `}
                            onClick={() => onClick(i)}
                          >
                            {number}
                          </Link>
                        ))}
                      </span>
                      <Link
                        className="paginate_button next"
                        to="/settings/roles"
                        onClick={() =>
                          activePag.current + 1 < paggination.length &&
                          onClick(activePag.current + 1)
                        }
                      >
                         {">"}
                      </Link>
                    </div> 
                     <Pagination size="sm">
                  <Pagination.Item key={0} onClick={(e) => setActive(1)}>
                    {"<<"}
                  </Pagination.Item>
                  <Pagination.Item
                    key={-3}
                    onClick={(e) => (active === 1 ? "" : setActive(active - 1))}
                  >
                    {"<"}
                  </Pagination.Item>
                  {items}
                  <Pagination.Item
                    key={-2}
                    onClick={(e) =>
                      active === items.length ? "" : setActive(active + 1)
                    }
                  >
                    {">"}
                  </Pagination.Item>
                  <Pagination.Item
                    key={-1}
                    onClick={(e) => setActive(items.length)}
                  >
                    {">>"}
                  </Pagination.Item>
                </Pagination>  */}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>




  );
}
